<template>
  <form class="form" @submit.prevent="">
    <h5 class="form__title">Вход в учетную запись</h5>

    <div class="form__input">
      <q-input
        v-model="email"
        placeholder="Введите E-mail"
        type="text"
        :error="errorLoginInput"
      />
    </div>

    <div class="form__input">
      <q-input
        v-model="password"
        placeholder="Введите пароль"
        :type="typeOfPasswordInput"
        :show-password="true"
        :error="errorPasswordInput"
        @switch-show-pass="typeOfPasswordInput === 'password' ? typeOfPasswordInput = 'text' : typeOfPasswordInput = 'password'"
      />
    </div>

    <div class="form__checkbox">
      <QCheckbox
        label="Оставаться в системе"
        :checked="remain"
        @clicked="remain = !remain"
      />
    </div>

    <div class="form__button">
      <q-button
        label="Войти в аккаунт"
        view="blur"
        @clicked="submitForm"
      />
    </div>

    <div class="form__button">
      <q-button
        label="Забыли пароль?"
        view="flat-w"
      />
    </div>
  </form>
</template>

<script setup>
import { defineComponent, onMounted } from 'vue'
import { ref } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import { useRouter } from 'vue-router'

const router = useRouter()

const email = ref('')
const password = ref('')
const remain = ref(true)

onMounted(()=> {
  password.value = process.env.VUE_APP_AUTOFILL_PASS
})

const typeOfPasswordInput = ref('password')
const errorLoginInput = ref('')
const errorPasswordInput = ref('')

const submitForm = async() => {
  errorLoginInput.value = ''
  errorPasswordInput.value = ''

  if(email.value.trim() === ''){
    errorLoginInput.value = 'Заполните почту'
    return
  }

  if(password.value.trim() === ''){
    errorPasswordInput.value = 'Заполните пароль'
    return
  }

  const sendForm = await useApi('post', 'user/login', {
    email: email.value.trim(),
    password: password.value.trim(),
    remain: remain.value
  })
  if(sendForm.token) {
    store.dispatch('login', {
      ...sendForm,
      remain: remain.value,
    })
    router.push({ name: 'Analytics'})
  } else if (sendForm.error === 'invalid login') {
    errorLoginInput.value = 'Не верный адрес почты'
  } else if (sendForm.error === 'invalid password') {
    errorPasswordInput.value = 'Не верный пароль'
  } else if (sendForm.error === 'Аккаунт заблокирован'){
    errorLoginInput.value = 'Аккаунт заблокирован'
  }
}
</script>

<style scoped lang="scss">
  .form {
    padding: 36px 36px 25px 36px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    backdrop-filter: blur(20px);
    max-width: 348px;
    flex: 1;
    &__title{
      margin-bottom: 25px;
      color: $white;
    }
    &__input{
      margin-bottom: 25px;
    }
    &__error{
      margin-top: -4px;
      height: 14px;
    }
    &__checkbox{
      margin-top: 10px;
      margin-bottom: 20px;
    }
    &__button{
      margin-bottom: 11px;
    }
  }
</style>