import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      token: localStorage.getItem(`q-token`) || null,
      role: Number(localStorage.getItem('q-role')) || null,
      company: localStorage.getItem('q-company') || null,
      access: localStorage.getItem('q-access') || null,
    },
    isBlockWindow: false,
    globalPreloader: false,
    demoModal: false,
    isDemoMode: false,
  },
  mutations: {
    setUsersData(state, data) {
      state.user.token = data.token
      state.user.role = data.role
      state.user.company = data.company_name
      state.user.access = data.access
      if (data.remain) {
        localStorage.setItem('q-token', data.token)
        localStorage.setItem('q-role', data.role)
        localStorage.setItem('q-company', data.company_name)
        localStorage.setItem('q-access', data.access)
      }
    },
    logoutUser(state) {
      state.user.token = null
      state.user.role = null
      state.user.company = null
      state.user.access = null
      localStorage.removeItem('q-token')
      localStorage.removeItem('q-role')
      localStorage.removeItem('q-company')
      localStorage.removeItem('q-access')
    },
    changeIsBlockWindow(state, type) {
      state.isBlockWindow = type
    },
    setGlobalPreloader(state, data){
      state.globalPreloader = data
    },
    setDemoModal(state, data){
      state.demoModal = data
    },
    setDemoMode(state){
      state.isDemoMode = true
    },
  },
  actions: {
    login({commit}, data) {
      commit('setUsersData', data)
    },
    logout({commit}){
      commit('logoutUser')
    },
    changeIsBlockWindow({commit}, type){
      commit('changeIsBlockWindow', type)
    },
    setGlobalPreloader({commit}, data){
      commit('setGlobalPreloader', data)
    },
    setDemoModal({commit}, data){
      commit('setDemoModal', data)
    },
    setDemoMode({commit}){
      commit('setDemoMode')
    },
  },
  getters: {
    userToken: state => state.isDemoMode ? localStorage.getItem(`q-token-demo`) : state.user.token,
    getUserRole: state => state.user.role,
  }
})
