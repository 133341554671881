<template>
  <div class="search-block">
    <div class="search-block__trasporters">
      <q-select
        :items="selectItemsTransporters"
        @changeSelect="activeItemTransporters = $event"
        :selectedItem="activeItemTransporters"
      />
    </div>
    <div class="search-block__number">
      <q-input-number
        v-model="number"
        placeholder="М 000 ММ 000"
        type="text"
        :search="searchedNumbers"
        @searchItemChoose="chooseNumber"
        license
        @closeSearchPanel="searchedNumbers = []"
        cross
        grey
        :masked="maskVal"
        @onInput="inputNumbers"
      />
    </div>
    <div class="search-block__btn">
      <q-button
        label="Найти"
        view="primary"
        @clicked="$emit('getCommonAnaliticData')"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, watchEffect, onMounted, computed } from "vue";
import useApi from '@/composables/useApi'


const emit = defineEmits(['getCommonAnaliticData', 'setTransportNumber', 'setPolygonName'])

onMounted(async()=>{
  const platformsListData = await useApi('get', 'analitics/get_platforms_list')
  selectItemsTransporters.value = [{
    id: "",
    label: "Выберите значение",
  },...platformsListData.platforms_list]
})

const selectItemsTransporters = ref([]);
const activeItemTransporters = ref({
  id: "",
  label: "Выберите значение",
});

const number = ref('');
const searchedNumbers = ref([]);


const inputNumbers = async(e) => {
  if(e){
    const filtNum = await useApi('get', `filters/find_state_number/${e}/tko`)
    searchedNumbers.value = filtNum.cars
  } else {
    searchedNumbers.value = []
  }
}

watchEffect(() => emit('setPolygonName', activeItemTransporters.value.label))

const chooseNumber = item => {
  number.value = item.label;
  emit('setTransportNumber', item.label);
  searchedNumbers.value = [];
}

const maskVal = computed(() => Number(number.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>

<style scoped lang="scss">
.search-block {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  &__trasporters {
    width: 340px;
  }
  &__number {
    flex: 1;
    margin: 0 25px;
  }
  &__btn {
    width: 90px;
  }
  &__date {
    width: 200px;
  }
}
</style>
