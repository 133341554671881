<template>
  <div class="page">
    <q-counterparties-preloader v-if="preloader"/>

    <q-empty-content
      v-if="notFoundMessage"
      size="small"
      :message="notFoundMessage"
    />

    <transition name="default">
      <div class="list container" v-if="!preloader">
        <QСounterpartiesItemAdmin
          v-for="item in counterpartiesList"
          :key="item.id"
          :data="item"
          :spheres="spheres"
          :roles="roles"
          :ro="roList"
          @notification="notify({ title: $event, type: 'success'})"
          @updateCounterpartiesList="getAgents"
          @updateStatus="updateStaus($event)"
        />
      </div>
    </transition>

    <teleport to="#header__left-teleport-container">
      <button
        class="add"
        @click="isShowModalCompany = true"
      >
        <img src="@/assets/images/add.svg" alt="">
        Добавить
      </button>
    </teleport>

    <transition name="default">
      <QModalCompany
        v-if="isShowModalCompany"
        @close="isShowModalCompany = false"
        :spheres="spheres"
        :roles="roles"
        :ro="roList"
        @notification="notify({ title: $event, type: 'success'})"
        @updateCounterpartiesList="getAgents"
        @openAttachingGravity="isShowModalGravity = true"
      />
    </transition>

    <transition name="default">
      <QModalGravity
        v-if="isShowModalGravity"
        @close="isShowModalGravity = false"
      />
    </transition>

    <q-bottom-panel>
      <QCounterpartiesFilters
        @setStatus="setStatus"
        @setType="setType"
        @setTransporter="setTransporter"
      />
    </q-bottom-panel>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import useApi from '@/composables/useApi'
import store from '@/store'
import { notify } from '@kyvg/vue3-notification'

import useRouterGuard from '@/composables/useRouterGuard'
import QModalGravity from '@/components/modals/QModalGravity.vue'
import QModalCompany from '@/components/modals/QModalCompany.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue'

import QСounterpartiesItemAdmin from './QСounterpartiesItemAdmin.vue';
import QCounterpartiesPreloader from './QCounterpartiesPreloader.vue'
import QCounterpartiesFilters from './QCounterpartiesFilters.vue'
import QEmptyContent from '@/components/common/QEmptyContent.vue'

onMounted(()=> {
  useRouterGuard()
  getSpheres()
  getRoles()
  getAgents()
  getRoList()
})

watch(
  () => store.state.user.role,
  () => getAgents()
)

const isShowModalCompany = ref(false)
const isShowModalGravity = ref(false)


const preloader = ref(true)

const counterpartiesList = ref([])

const notFoundMessage = ref('')

const status = ref('true')
const type = ref('')
const transporter = ref('')

const getAgents = async() => {
  preloader.value = true

  const payload = {
    company_name: transporter.value,
    status: status.value === 'true' ? true : false,
    type: type.value
  }
  const agentsData = await useApi('post', 'admin/company/get_with_filter', payload)

  notFoundMessage.value = ''
  if(agentsData.message) {
    notFoundMessage.value = agentsData.message.text
  }
  preloader.value = false
  counterpartiesList.value = agentsData.company
}

const updateStaus = id => {
  counterpartiesList.value[counterpartiesList.value.findIndex(item => item.id === id)].status = !counterpartiesList.value[counterpartiesList.value.findIndex(item => item.id === id)].status
  store.dispatch('setGlobalPreloader', false)
  notify({ title: 'Статус компании изменен', type: 'success'})
  setTimeout(()=> getAgents(), 1000)
}


const setStatus = e => {
  status.value = e
  getAgents()
}

const setType = e => {
  type.value = e
  getAgents()
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13) getAgents()
});

const setTransporter = e => {
  transporter.value = e
  getAgents()
}

const spheres = ref([])
const getSpheres = async() => {
  const data = await useApi('get', 'admin/sphere/get')
  spheres.value = data.spheres
}

const roles = ref([])
const getRoles = async() => {
  const data = await useApi('get', 'user/role/get')
  roles.value = data.roles
}

const roList = ref([])
const getRoList = async() => {
  const data = await useApi('get', 'admin/company/get_ro')
  roList.value = data.ro
}
</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add{
  padding: 8px 16px;
  background-color: $lemon-lime;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  img{
    margin-right: 10px;
  }
}
</style>