<template>
  <div class="q-acts-preloader container">
    <img
      src="@/assets/images/preloader-orders.svg"
      v-for="(_, idx) in 3"
      :key="idx"
    />
  </div>
</template>

<style scoped lang="scss">
img {
  margin-bottom: 10px;
  animation-name: blink;
  animation-duration: .6s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes blink {
  0% { opacity: .5; }
  100% { opacity: 1; }
}
</style>