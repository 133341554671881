<template>
  <div class="current-accrual">
    <div class="current-accrual__subtitle">
      Суммарный объём начислений площадкам по состоянию на {{ currentDay }}
      {{ monthCompare(currentMonth + 1) }}
    </div>
    <div class="current-accrual__summ">
      <span> {{ data.summ }} ₽</span>
      <div
        class="comparison"
        :class="{green: data.summ_percent.includes('-')}"
      >
        {{ data.summ_percent }} по сравнению с 1 по {{ currentDay }}
        {{ monthCompare(currentMonth) }}
      </div>
    </div>
    <div class="current-accrual__analyt">
      <div class="box" :style="{ width: '340px' }">
        <p>
          Прогноз по начислениям <br />
          на конец месяца
        </p>
        <div class="summ">{{ data.summ_prognosis }} ₽</div>
      </div>
      <div class="box box__completeness">
        <div class="scheme">
          <div class="scheme__title">Выполнение тер.схемы</div>
          <div class="scheme__bar">
            <div :style="`width: ${data.tonnage_realization}; background: ${Number(data.tonnage_realization.slice(0, -1)) < 100 ? '#B2EAFC' : '#ff6e63'}`">{{ data.tonnage_realization }}</div>
          </div>
        </div>
        <div class="tonnage">
          <div class="tonnage__item">
            <span>{{ data.tonnage }} т</span>
            <p class="blue">Фактический тоннаж</p>
          </div>
          <div class="tonnage__item">
            <span>{{ data.tonnage_scheme }} т</span>
            <p>Тоннаж по тер. схеме</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="separator" />
</template>

<script setup>
import { defineProps, ref } from "vue"
import moment from "moment"
import "moment/locale/ru"
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts"

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const time = new Date()
const currentMonth = time.getMonth()
const currentDay = ref(time.getDate())

const monthCompare = month => {
  switch (month) {
    case 1: return 'января'
    case 2: return 'февраля'
    case 3: return 'марта'
    case 4: return 'апреля'
    case 5: return 'мая'
    case 6: return 'июня'
    case 7: return 'июля'
    case 8: return 'августа'
    case 9: return 'сентября'
    case 10: return 'октября'
    case 11: return 'ноября'
    case 12: return 'декабря'
  }
}

</script>

<style scoped lang="scss">
.current-accrual {
  margin-top: 30px;
  &__subtitle {
    font-size: 20px;
    opacity: 0.7;
  }
  &__summ {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    font-size: 16px;
    span {
      font-size: 48px;
      font-family: "TT Norms Pro Regular";
      line-height: 52px;
      margin-right: 18px;
    }
    .comparison {
      background: $natural-color-system;
      padding: 8px 16px;
      color: white;
      border-radius: 14px;
      font-size: 16px;
      width: fit-content;
      letter-spacing: 0.01em;

      &.green{
        background: $green;
        color: $ford-medium-dark;
      }
    }
  }
  &__analyt {
    margin: 37px 17px 30px 0px;
    display: flex;
    // justify-content: space-between;
  }
}

.box {
  padding: 23px 26px;
  border-radius: 22px;
  height: 150px;
  background: $ford-medium-dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    color: rgba(white, 0.6);
    font-size: 16px;
    line-height: 22px;
  }
  .summ {
    color: white;
    font-size: 30px;
    line-height: 35.94px;
    font-family: "TT Norms Pro Regular";
  }
  &__completeness {
    background: $caparol;
    color: $ford-medium-dark;
    margin: 0px 35px;
    width: 500px;
    p {
      color: $ford-medium-dark;
    }
    .summ {
      color: $ford-medium-dark;
    }
    .scheme {
      display: flex;
      align-items: center;
      &__title {
        white-space: nowrap;
        font-size: 16px;
      }
      &__bar {
        margin-left: 35px;
        background: white;
        height: 36px;
        width: -webkit-fill-available;
        border-radius: 14px;
        position: relative;
        overflow: hidden;
        
        div {
          display: flex;
          padding-left: 12px;
          align-items: center;
          height: 100%;
          background: $ford-tyrolean-blue;
          transition: ease .5s;
        }
      }
    }
    .tonnage {
      display: flex;
      justify-content: space-between;
      &__item {
        span {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 5%;
          font-family: "TT Norms Pro Regular";
        }
        p {
          font-size: 16px;
          padding-left: 20px;
          color: rgba($ford-medium-dark, 0.6);
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 6px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 3px;
            background: white;
          }
          &.blue {
            &:before {
              background: $ford-tyrolean-blue;
            }
          }
          &.red {
            &:before {
              background: $natural-color-system;
            }
          }
        }
      }
    }
  }
  &__warn {
    background: $caparol;
    color: $ford-medium-dark;
    font-size: 16px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: black;
      }
      span {
        width: 65px;
        height: 36px;
        background: $ford-medium-dark;
        color: white;
        margin-left: 27px;
        border-radius: 14px;
        font-size: 16px;
        padding: 7px 0;
        text-align: center;
      }
    }
  }
}

.separator {
  margin: 36px 0;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: rgba($ford-medium-dark, 0.1);
}

</style>
