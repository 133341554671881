<template>
  <div class="page">
    <teleport to="#header__right-teleport-container">
      <div class="q-acts-switcher" v-if="switcherSelectItems.length">
        <q-waste-switcher
          :items="switcherSelectItems"
          @changeSelect="currentWaste = $event"
          @changeSelectParent="currentWasteType = $event ? $event.id : ''"
          :selectedItem="currentWaste"
          white
          multi
          switcher
          @setSwitcherValue="setSwitcherValue"
        />
      </div>
    </teleport>

    <q-scroll-up-button :quantityPaginationPage="quantityPaginationPage"/>

    <q-acts-preloader v-if="preloader"/>

    <q-empty-content
      v-if="notFoundMessage"
      :message="notFoundMessage"
    />

    <transition name="default">
      <div v-if="!preloader" class="container">
        <q-acts-item
          v-for="act in acts"
          :key="act.id"
          :data="act"
          @updateActStatus="updateActStatus"
          @updateActsList="getActs"
          @showNotification="notify({ title: $event, type: 'success'})"
          :waste="currentWaste"
        />
      </div>
    </transition>

    <v-pagination
      v-if="quantityPaginationPage > 1"
      v-model="currentPaginationPage"
      :pages="quantityPaginationPage"
      :range-size="1"
      @update:modelValue="updateHandler"
    />

    <q-bottom-panel>
      <QActsFilters
        @setCarNumber="setCarNumber"
        @setTransporter="setTransporter"
        @setClient="setClient"
        @setPolygon="setPolygon"
        @setSortType="setSortType"
        @setFilteredDate="setFilteredDate"
        @setTemporaryActNumber="setTemporaryActNumber($event)"
        @downloadExcel="downloadExcel"
        :tonnage="tonnage"
        :weights="weights"
        :numDispute="numDispute"
        :tonnageDispute="tonnageDispute"
        :wasteCategory="switcherSelectItems.length ? currentWaste.id : ''"
      />
    </q-bottom-panel>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch, computed } from 'vue'
import moment from 'moment'
import store from '@/store'
import useRouterGuard from '@/composables/useRouterGuard'
import useApi from '@/composables/useApi'
import QActsItem from './QActsItem.vue'
import QActsFilters from './QActsFilters.vue'
import QActsPreloader from './QActsPreloader.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue'
import QEmptyContent from '@/components/common/QEmptyContent.vue'
import VPagination from '@hennge/vue3-pagination'
import QScrollUpButton from '@/components/common/QScrollUpButton.vue'
import { notify } from '@kyvg/vue3-notification'
import QWasteSwitcher from '@/components/ui/QWasteSwitcher.vue'
import { useRouter } from 'vue-router'


const router = useRouter()

const preloader = ref(false)
const acts = ref([])
const tonnage = ref(0)
const weights = ref(0)
const numDispute = ref(0)
const tonnageDispute = ref(0)
const notFoundMessage = ref('')

onMounted(async ()=> {
  if(store.state.user.role === 4){
    router.push('/counterparties')
  }

  date.value = [moment().utc(moment()._d), moment().utc(moment()._d)]
  await useRouterGuard()
  await getSwitchers()
  preloader.value = true
})

const carNumber = ref('')
const transporter = ref('')
const client = ref('')
const polygon = ref('')
const sortType = ref('date_desc')
const date = ref(['', ''])

const isDirtyFields = ref(false)

const quantityPaginationPage = ref(1)
const currentPaginationPage = ref(1)

const temporaryActNumber = ref(null)

const currentWasteType = ref('')
const currentWasteCategory = ref('')

watch(
  () => quantityPaginationPage.value,
  (val) => currentPaginationPage.value = 1
)

watch(
  () => store.state.user.role,
  () => getActs()
)

const getActs = async(dispute = false) => {
  store.dispatch('setGlobalPreloader', true)
  acts.value = []
  preloader.value = true
  notFoundMessage.value = ''

  const actsData = await useApi('post', 'acts/get_acts', {
    waste_category: currentWasteCategory.value,
    waste_type: currentWasteType.value,
    car_number: carNumber.value,
    transporter_name: transporter.value,
    current_page: isDirtyFields.value ? 1 : currentPaginationPage.value,
    platform_name: polygon.value,
    sorting_by: dispute ? 'dispute' : String(sortType.value),
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    client: client.value,

    act_number: temporaryActNumber.value ? String(temporaryActNumber.value) : '',
  })

  if(actsData.message){
    notFoundMessage.value = actsData.message.text
  }
  quantityPaginationPage.value = actsData.pages
  tonnage.value = actsData.tonnage
  weights.value = actsData.weight
  acts.value = actsData.acts
  numDispute.value = actsData.num_dispute
  tonnageDispute.value = actsData.dispute_tonnage
  preloader.value = false

  isDirtyFields.value = false
  store.dispatch('setGlobalPreloader', false)

  temporaryActNumber.value = null
}

const setTemporaryActNumber = e => {
  temporaryActNumber.value = e
  getActs()
}


const switcherSelectItems = ref([])

const currentWaste = ref({
  id: 'tko',
  label: 'ТКО'
})
watch(
  () => currentWaste.value,
  (val) => getActs()
)

const getSwitchers = async () => {
  const res = await useApi('get', 'waste/get_info')

  if(res.waste.length){
    switcherSelectItems.value = res.waste
    currentWaste.value = switcherSelectItems.value[0]
  }
  getActs()
}

const updateHandler = (e) => {
  currentPaginationPage.value = e
  getActs()
}

const setSortType = e => {
  isDirtyFields.value = true
  sortType.value = e.id
  getActs()
}

const setPolygon = e => {
  polygon.value = e
  isDirtyFields.value = true
  getActs()
}

const setTransporter = e => {
  transporter.value = e
  isDirtyFields.value = true
  getActs()
}

const setClient = e => {
  client.value = e
  isDirtyFields.value = true
  getActs()
}

const setCarNumber = e => {
  carNumber.value = e
  isDirtyFields.value = true
  getActs()
}

const setFilteredDate = e => {
  isDirtyFields.value = true
  date.value = [moment(e[0]).utc(e[0]), moment(e[1]).utc(e[1])]
  getActs()
}

const updateActStatus = id => {
  acts.value[acts.value.findIndex(item => item.id === id)].dispute = !acts.value[acts.value.findIndex(item => item.id === id)].dispute
  setTimeout(()=>getActs(), 1000)
}

const downloadExcel = async() => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'acts/get_excel', {
    car_number: carNumber.value,
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    platform_name: polygon.value,
    transporter_name: transporter.value,
    waste_category: currentWasteCategory.value,
    waste_type: currentWasteType.value,
    client: client.value,
  })

  if(req.docs){
    window.open(req.docs, '_self');
  }
  store.dispatch('setGlobalPreloader', false)
}


const setSwitcherValue = item => {
  if(item.category_id){
    currentWasteType.value = item.id
    currentWasteCategory.value = item.category_id
  } else {
    currentWasteType.value = ''
    currentWasteCategory.value = item.id
  }
}
</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.q-acts-switcher{
  position: relative;
  width: 200px;
}
</style>
