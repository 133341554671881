<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5> {{ data ? 'Редактирование' : 'Добавление' }} сотрудника в {{ type }}</h5>

      <div class="form-item">
        <label>ФИО</label>
        <q-input
          placeholder="Введите ФИО"
          type="text"
          :disabled="false"
          :white="true"
          v-model="form.name"
          :error="v$.name.$errors.length ? 'Заполните поле' : ''"
        />
      </div>

      <div class="form-item" v-if="type !== 'Gravity' && !props.data">
        <label>E-mail</label>
        <q-input
          placeholder="Введите E-mail"
          type="text"
          :disabled="false"
          :white="true"
          v-model="form.email"
          :error="v$.email.$errors.length ? 'Некорректный адрес электронной почты' : ''"
        />
        <div v-if="emailError && !v$.email.$errors.length" class="email-error">Сотрудник с такой почтой уже зарегистрирован</div>
      </div>

      <div class="form-item form-item--password" v-if="(type == 'Gravity') || (type === 'Signall' && !props.data)">
        <label>Пароль</label>
        <q-input
          placeholder="Придумайте пароль"
          type="text"
          :disabled="false"
          :white="true"
          v-model="form.password"
          :error="v$.password.$errors.length ? 'Заполните поле' : ''"
        />
        <svg @click="generatePassword" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1185 0.49968C12.4961 0.49968 11.9909 1.00425 11.9909 1.62667C11.9909 2.2491 12.4961 2.75367 13.1185 2.75367C13.741 2.75367 14.2462 2.2491 14.2462 1.62667C14.2462 1.00425 13.741 0.49968 13.1185 0.49968ZM2.31519 11.7825L9.41217 4.68961C10.0423 4.05988 11.0638 4.05988 11.6939 4.68961L13.3211 6.31591C13.9512 6.94563 13.9512 7.96661 13.3211 8.59634L6.22416 15.6892C5.14473 16.7681 3.39462 16.7681 2.31519 15.6892C1.23576 14.6104 1.23575 12.8613 2.31519 11.7825ZM10.4731 5.74996L8.25038 7.97144L10.0374 9.75746L12.2602 7.53598C12.3043 7.49188 12.3043 7.42037 12.2602 7.37626L10.6329 5.74996C10.5888 5.70586 10.5173 5.70586 10.4731 5.74996ZM3.37615 12.8429L7.18941 9.0318L8.97646 10.8178L5.1632 14.6289C4.66972 15.1221 3.86963 15.1221 3.37615 14.6289C2.88268 14.1357 2.88267 13.3361 3.37615 12.8429ZM14.2474 9.38865C14.2474 8.76622 14.7526 8.26165 15.375 8.26165C15.9974 8.26165 16.5027 8.76622 16.5027 9.38865C16.5027 10.0111 15.9974 10.5156 15.375 10.5156C14.7526 10.5156 14.2474 10.0111 14.2474 9.38865ZM7.60797 1.49968C6.98555 1.49968 6.48033 2.00425 6.48033 2.62667C6.48033 3.2491 6.98555 3.75367 7.60797 3.75367C8.23039 3.75367 8.73561 3.2491 8.73561 2.62667C8.73561 2.00425 8.23039 1.49968 7.60797 1.49968Z" fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.4"/>
        </svg>
      </div>

      <q-button
        label="Сохранить"
        view="secondary"
        @clicked="submitForm"
      />
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, reactive, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { notify } from '@kyvg/vue3-notification'

onMounted(()=> {
  if(props.data){
    form.id = props.data.id || ''
    form.email = props.data.email || ''
    form.name = props.data.name || props.data.full_name
    form.password = props.data.password || ''
  }
})

const props = defineProps({
  type: {
    type: String,
    default: 'Signall'
  },
  data: {
    type: Object,
    default: {}
  }
})
const emit = defineEmits(['close', 'updateUsersList', 'showNotification'])

const emailError = ref(false)

const form = reactive({
  email: '',
  name: '',
  password: '',
})

const generatePassword = () => form.password = Math.random().toString(36).slice(-10)


const submitForm = async() => {

  emailError.value = false

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  const send = await useApi('post', `settings/${props.data ? 'update' : 'create'}_${props.type === 'Signall' ? 'user' : 'weigher'}`, form)


  if(send.error){
    notify({ title: send.error, type: 'error'})
    return
  }

  if(send.status === 'success'){
    emit('showNotification', `Для сотрудника ${form.name} личный кабинет ${props.type} успешно ${props.data ? 'изменен' : 'добвлен'}!`)
    emit('updateUsersList')
    emit('close')
  }
}

const validShemaSignall = ref({
  name: { required },
  email: props.data ? {} : {required, email},
  password: props.data ? {} : {required},
})

const validShemaGravity = ref({
  name: { required },
  password: { required },
})

const v$ = useVuelidate(props.type === 'Signall' ? validShemaSignall.value : validShemaGravity.value, form)
</script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;
}
h5{
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-item{
  position: relative;
}
.form-item--password{
  position: relative;
  svg{
    position: absolute;
    right: 10px;
    bottom: 11px;
    cursor: pointer;
    path{
      transition: ease .3s;
    }
    &:hover{
      path{
        fill: #B2B2B2;
      }
    }
  }
}

.email-error{
  font-size: 14px;
  color: #FF7A7A;
  margin-bottom: -15px;
  line-height: 1.4;
}
</style>
