<template>
  <q-modal @close="$emit('close')">
    <div class="content">
      <h5>Добавление RFID</h5>

      <div class="form-item">
        <label>Тип</label>
        <div class="types">
          <div
            v-for="checkbox in types"
            :key="checkbox.id"
            class="checkbox"
            :class="{active: checkbox.id === activeType.id}"
            @click="activeType = checkbox"
          >
            {{ checkbox.label }}
          </div>
        </div>
      </div>

      <div class="form-item">
        <label>Маска</label>
        <q-input
          placeholder="FFFF00"
          type="text"
          :white="true"
          v-model="form.mask"
          masked="masko"
          :error="v$.mask.$errors.length ? 'Заполните поле' : ''"
        />
      </div>

      <div class="form-item">
        <label>Номер</label>
        <div class="wrap">
          <div class="item">
            <q-input
              placeholder="0000"
              type="text"
              :white="true"
              v-model="form.numFrom"
              masked="rfid"
              :error="v$.numFrom.$errors.length ? 'Заполните поле' : ''"
            />
          </div>
          <span>до</span>
          <div class="item">
            <q-input
              placeholder="0000"
              type="text"
              :white="true"
              v-model="form.numTo"
              masked="rfid"
              :error="v$.numTo.$errors.length ? 'Заполните поле' : ''"
            />
          </div>
        </div>
      </div>

      <div class="form-buttons">
        <div class="">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="createRfid"
          />
        </div>
        <div class="form-btn">
          <q-button
            label="Сохранить и добавить еще"
            view="flat-b"
            @clicked="createRfid({more: true})"
          />
        </div>
      </div>

      <div v-if="formError" class="form-error">{{ formError }}</div>
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, ref, reactive, computed, onMounted, watch, watchEffect } from 'vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

const emit = defineEmits(['notification', 'updateRfidList', 'close'])

const types = ref([])

const activeType = ref({id: 0, label: ''})

const form = reactive({
  mask: '',
  numFrom: '',
  numTo: ''
})

const formError = ref('')

const validShema = ref({
  mask: { required,  minLength: minLength(6) },
  numFrom: { required, minLength,  minLength: minLength(4) },
  numTo: { required, minLength, minLength: minLength(4) }
})

const v$ = useVuelidate(validShema.value, form)

onMounted(async()=> {
  const data = await useApi('get', 'rfid/type/get')
  types.value = data.rfid_type
  activeType.value = types.value[0]
})

const createRfid = async(more) => {
  formError.value = ''

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }
  const data = await useApi('post', 'admin/rfid/create', {
    mask: form.mask,
    start_number: form.numFrom,
    stop_number: form.numTo,
    type_id: activeType.value.id
  })

  if(data.error){
    formError.value = data.error
  }

  if(data.status === 'successful'){
    emit('notification', 'RFID успешно добавлены')
    emit('updateRfidList')

    if(more){
      form.mask = ''
      form.numFrom = ''
      form.numTo = ''
    } else {
      emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.content{
  width: 348px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 22px;
  line-height: normal;
}

.types{
  display: flex;
  gap: 24px;
}

.checkbox{
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  cursor: pointer;
  &:before{
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid #424242;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
  &:after{
    content: '';
    width: 14px;
    height: 14px;
    background: #424242;
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    transition: ease .3s;
    opacity: 0;
  }
  &.active{
    &:after{
      content: '';
      opacity: 1;
    }
  }
}

.form-item{
  margin-bottom: 24px;
}

.wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item{
    width: 118px;
  }
}

.form-buttons{
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.form-error{
  color: #F69782;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}
</style>