<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5>Печати организации</h5>

      <div
        class="surprints-list"
        v-if="surprintsList.length"
      >
        <div
          class="surprint-item"
          v-for="sp in surprintsList"
        >
          <div
            class="image"
            :style="{ backgroundImage:`url(https://signalltestdev.qodex.tech/media/seal/${sp})` }"
          />
          <span @click="removeSP(sp)">Удалить</span>
        </div>
      </div>

      <div v-else class="surprints-empty">
        <span>У вас еще нет печатей. <br/> Добавьте их ниже.</span>
      </div>

      <div
        class="dnd-zone"
        @dragenter.prevent="activeDND = !activeDND"
        @dragleave.prevent="activeDND = !activeDND"
        @dragover.prevent
        @drop.prevent="uploadFilesDND"
        :class="{active: activeDND, error: fileError}"
      >

        <div
          v-if="uploadedFiles.length"
          class="load-more"
          @click="fileInput.click()"
        >Загрузить еще</div>

        <span v-if="fileError" class="file-error">{{ fileError }}</span>

        <div v-if="uploadedFiles.length" class="prev-list">
          <div
            class="prev-item"
            v-for="(item, idx) in uploadedFiles"
            :key="item.lastModified"
            :style="{ backgroundImage:`url(${item.preview})` }"
          >
            <img src="@/assets/images/remove-preview.svg" @click="uploadedFiles.splice(idx, 1);">
          </div>
        </div>

        <div v-else class="empty">
          <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.84069 16.5065C6.98888 15.3036 8.01068 14.3999 9.22269 14.3999H43.9864C45.4283 14.3999 46.5447 15.6623 46.3684 17.0933L43.5597 39.8933C43.4115 41.0963 42.3897 41.9999 41.1777 41.9999H6.41399C4.97211 41.9999 3.85571 40.7375 4.032 39.3065L6.84069 16.5065Z" fill="#BBBBBB"/>
            <path d="M19.1413 6H6.1002C4.77471 6 3.7002 7.07452 3.7002 8.4V12V39.6C3.7002 40.9255 4.77471 42 6.1002 42H40.9002C42.2257 42 43.3002 40.9255 43.3002 39.6V14.4C43.3002 13.0745 42.2257 12 40.9002 12H25.4591C24.616 12 23.8348 11.5577 23.4011 10.8348L21.1993 7.16521C20.7656 6.44232 19.9844 6 19.1413 6Z" stroke="#BBBBBB" stroke-width="2"/>
            <path d="M25.5 21C25.5 20.4477 25.0523 20 24.5 20C23.9477 20 23.5 20.4477 23.5 21L25.5 21ZM23.7929 35.7071C24.1834 36.0976 24.8166 36.0976 25.2071 35.7071L31.5711 29.3431C31.9616 28.9526 31.9616 28.3195 31.5711 27.9289C31.1805 27.5384 30.5474 27.5384 30.1569 27.9289L24.5 33.5858L18.8431 27.9289C18.4526 27.5384 17.8195 27.5384 17.4289 27.9289C17.0384 28.3195 17.0384 28.9526 17.4289 29.3431L23.7929 35.7071ZM23.5 21L23.5 35L25.5 35L25.5 21L23.5 21Z" fill="white"/>
          </svg>
          <p>Загрузите документ в оттиском печати или перетащите в эту область. Доступные форматы документа: .jpeg, .jpg, .png, .svg</p>
        </div>

        <div class="upload-btn">
          <QButton
            :label="uploadedFiles.length ? 'Сохранить' : 'Загрузить'"
            view="secondary"
            @click="sendForm"
          />
        </div>
      </div>

      <input
        class="file-input"
        ref="fileInput"
        type="file"
        multiple
        @change="uploadFiles"
      >
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, onUnmounted, onMounted, reactive, inject } from 'vue'
import store from '@/store'
import axios from 'axios'
import useApi from '@/composables/useApi'

const emit = defineEmits(['close', 'updateCounterpartiesList', 'showNotification'])

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  surprints: {
    type: Array,
    default: () => [],
  },
})

const surprintsList = ref([])

onMounted(async()=> {
  surprintsList.value = props.surprints
})

const fileInput = ref(null)
const uploadedFiles = ref([])
const activeDND = ref(false)
const fileError = ref('')


const validateFiles = files => {
  const allowFormats = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml"
  ]

  fileError.value = ''

  if(files.length > 10){
    fileError.value = 'Вы можете загрузить максимум 10 файлов'
    return
  }

  const isErr = false

  files.forEach(file => {
    if( !(allowFormats.find(f => f === file.type))){
      fileError.value = 'Доступные форматы документа: .jpeg, .jpg, .png, .svg'
      return
    }
    if(file.size >= 10000000){
      fileError.value = 'Вы не можете загружать файлы больше 10мб'
      return
    }
  })

  if(!fileError.value){
    files.forEach(f => {
      uploadedFiles.value.push({
        preview: URL.createObjectURL(f),
        file: f
      })
    })
  }

  fileInput.value.value = null
}

const uploadFilesDND = e => {
  activeDND.value = false
  validateFiles(e.dataTransfer.files)
}

const uploadFiles = e => {
  activeDND.value = false
  validateFiles(e.target.files)
}


const sendForm = () => {
  store.dispatch('setGlobalPreloader', true)
  if(!uploadedFiles.value.length){
    fileInput.value.click()
    store.dispatch('setGlobalPreloader', false)
    return
  }

  const formData = new FormData()

  formData.append('id', props.id)

  uploadedFiles.value.forEach(f => formData.append('file', f.file))

  axios.post(`${process.env.VUE_APP_BASE_API}/counterparties/add_seal`,
    formData,
    {
      headers: {
        'Authorization': store.state.user.token,
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  .then((res)=> {
    if(res.data.status === "successful"){
      emit('showNotification', `Печат${uploadedFiles.value.length === 1 ? 'ь' : 'и'} успешно добавлен${uploadedFiles.value.length === 1 ? 'а' : 'ы'}!`)
      getSurprints()
      store.dispatch('setGlobalPreloader', false)
    }
  })
}

const payloadGetSP = reactive({
  company_name: inject('company_name'),
  status: inject('status'),
  type: inject('type'),
})

const getSurprints = async() => {
  store.dispatch('setGlobalPreloader', true)
  const agentsData = await useApi('post', 'counterparties/get_counterparties', payloadGetSP)

  surprintsList.value = agentsData.counterparties.find(item => item.id === props.id).seals
  uploadedFiles.value = []
  store.dispatch('setGlobalPreloader', false)
}

const removeSP = async (sp) => {
  store.dispatch('setGlobalPreloader', true)
  const data = await useApi('delete', `counterparties/delete_seal/${props.id}/${sp}`)
  if(data.status === 'successful'){
    emit('showNotification', 'Печать успешно удалена')
    surprintsList.value.splice(surprintsList.value.findIndex(item => item === sp), 1)
  }
  store.dispatch('setGlobalPreloader', false)
}

onUnmounted(()=> emit('updateCounterpartiesList'))

</script>

<style scoped lang="scss">
.content{
  width: 816px;
  padding: 36px;
  position: relative;
}
h5{
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}
.surprints-list{
  display: flex;
  flex-wrap: nowrap;
  width: 780px;
  overflow: auto;
  gap: 0px 12px;
  padding-right: 36px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.surprint-item{
  .image{
    border-radius: 16px;
    border: 1px solid #9B9B9B;
    overflow: hidden;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
  span{
    text-align: center;
    display: block;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
    transition: ease .3s;
    &:hover{
      color: #555;
    }
  }
}
.file-input{
  display: none;
}

.dnd-zone{
  margin-top: 35px;
  height: 200px;
  border-radius: 22px;
  border: 1px dashed #BBB;
  background: #F4F4F4;
  transition: ease .3s;
  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &.active{
    border: 1px dashed #B3D700;
    background: rgba(225, 255, 76, 0.30);
  }
  &.error{
    border: 1px solid #FFAFAF;
    background: #FFEFEF;
  }
  .file-error{
    position: absolute;
    right: 0;
    bottom: -25px;
    color: #FF7A7A;
    font-size: 12px;
  }
  .upload-btn{
    width: 275px;
  }
  .empty{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 102px;
    p{
      max-width: 465px;
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.14px;
      opacity: 0.5;
    }
  }
}

.prev-list{
  display: flex;
  gap: 0 12px;
  width: 100%;
  margin-top: -5px;
  margin-left: -8px;

}
.prev-item{
  width: 117px;
  height: 117px;
  border-radius: 16px;
  border: 1px solid #9B9B9B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  img{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}

.surprints-empty{
  height: 237px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    text-align: center;
    font-size: 20px;
    opacity: 0.5;
  }
}

.load-more{
  position: absolute;
  left: 8px;
  bottom: 16px;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  transition: ease .3s;
  &:hover{
    color: #555555
  }
}
</style>