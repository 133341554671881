<template>
  <div
    class="transport-item"
    :class="{readonly: data.access === 'ro'}"
  >
    <div class="transport-item__top">
      <button
        class="transport-item__btn-delete"
        @click="data.access === 'ro' ? 1 : isShowModalDeleteTransport = true"
      >
      <svg class="remove-btn" width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="29" rx="11.5" stroke="#C6C6C6"/>
        <g clip-path="url(#clip0_12500_104040)">
        <path d="M25 15H20H15" stroke="#C6C6C6" stroke-width="2.5" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_12500_104040">
        <rect width="14" height="14" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 27 8)"/>
        </clipPath>
      </defs>
      </svg>
      </button>

      <div v-if="data.access === 'ro'" class="blocked-ro">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33398 5.99967V5.33301C5.33398 3.86025 6.52789 2.66634 8.00065 2.66634C9.47341 2.66634 10.6673 3.86025 10.6673 5.33301V5.99967L5.33398 5.99967ZM4.00065 6.22971V5.33301C4.00065 3.12387 5.79151 1.33301 8.00065 1.33301C10.2098 1.33301 12.0007 3.12387 12.0007 5.33301V6.22971C12.4017 6.48787 12.6673 6.93824 12.6673 7.45065V9.99967C12.6673 12.577 10.578 14.6663 8.00065 14.6663C5.42332 14.6663 3.33398 12.577 3.33398 9.99967V7.45065C3.33398 6.93824 3.5996 6.48787 4.00065 6.22971ZM4.78496 7.33301C4.71999 7.33301 4.66732 7.38568 4.66732 7.45065L4.66732 9.99967C4.66732 11.8406 6.1597 13.333 8.00065 13.333C9.8416 13.333 11.334 11.8406 11.334 9.99967V7.45065C11.334 7.38568 11.2813 7.33301 11.2163 7.33301H10.6673H5.33398H4.78496ZM8.00065 8.66634C8.73703 8.66634 9.33398 9.2633 9.33398 9.99967C9.33398 10.7361 8.73703 11.333 8.00065 11.333C7.26427 11.333 6.66732 10.7361 6.66732 9.99967C6.66732 9.2633 7.26427 8.66634 8.00065 8.66634Z" fill="white"/>
        </svg>

        <div class="notification-info">
          <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 7L-1.25965e-06 7L5.58579 1.41421C6.36684 0.633163 7.63316 0.633165 8.41421 1.41421L14 7Z" fill="#393939"/></svg>

          <div class="rows">
            <span class="row">{{ data.access_msg }}</span>
          </div>
        </div>
      </div>

      <div class="transport-item__rfid">
        <span>№ RFID</span>
        <span>{{ data.rfid !== '' ? data.rfid : '-'}}</span>
      </div>

      <div
        class="transport-item__notification"
        :class="{'empty-rfid': data.rfid}"
        v-if="data.messages.length"
      >
        <img src="@/assets/images/trasport-notification.svg">

        <div class="notification-info">
          <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 7L-1.25965e-06 7L5.58579 1.41421C6.36684 0.633163 7.63316 0.633165 8.41421 1.41421L14 7Z" fill="#393939"/></svg>

          <div class="rows">
            <span
              class="row"
              v-for="m in data.messages"
              :key="m"
            >{{m}}</span>
          </div>

        </div>
      </div>

      <div class="transport-item__image">
        <div class="default-image" :style="{ backgroundImage: 'url(' + require('@/assets/images/noImg.svg') + ')' }"></div>

        <div class="image"
          :style="{ backgroundImage: `url(${assetsEnv}/media/acts/preview/${data.photo}`}"
        />

        <div class="transport-item__panel">
          <div class="transport-item__number">
            <q-license-plate :number="data.state_number"/>
          </div>

          <button class="transport-item__open-gallery" @click="isShowGalleryCar = true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 18C22 20.2091 20.2091 22 18 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18C19.1046 20 20 19.1046 20 18V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V18ZM8 21C8 21.5523 7.55229 22 7 22H4C2.89543 22 2 21.1046 2 20V17C2 16.4477 2.44772 16 3 16C3.55228 16 4 16.4477 4 17V18.5858L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.41422 20H7C7.55229 20 8 20.4477 8 21ZM3 10C2.44772 10 2 9.55229 2 9V6C2 3.79086 3.79086 2 6 2H9C9.55229 2 10 2.44772 10 3C10 3.55228 9.55229 4 9 4H6C4.89543 4 4 4.89543 4 6V9C4 9.55229 3.55228 10 3 10ZM21 8C20.4477 8 20 7.55229 20 7V5.41422L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.5858 4H17C16.4477 4 16 3.55228 16 3C16 2.44772 16.4477 2 17 2H20C21.1046 2 22 2.89543 22 4V7C22 7.55229 21.5523 8 21 8Z" fill="white"/>
            </svg>
          </button>
        </div>

        <div class="transport-item__capacity">
          <span>Объем: </span>
          <span>{{ data.capacity}} <span v-if="data.capacity !== '-'" class="meter">м</span> </span>
        </div>

      </div>

      <div class="transport-item__info">
        <div class="transport-item__model">Марка ТС</div>
        <div class="transport-item__model-name">
          {{ data.brand }}
          <svg
            @click="data.access === 'ro' ? 1 : isShowModalTransport = true"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.229 13.8625L19.8492 6.24229C20.2397 5.85177 20.2397 5.2186 19.8492 4.82808L19.7257 4.70458C19.3352 4.31406 18.702 4.31406 18.3115 4.70458L10.6912 12.3248L12.229 13.8625ZM9.77702 16.2768C8.94858 16.2768 8.27699 15.6052 8.277 14.7768L8.27703 11.9106L16.8973 3.29037C18.0688 2.1188 19.9683 2.11879 21.1399 3.29037L21.2634 3.41387C22.435 4.58544 22.435 6.48493 21.2634 7.6565L12.6432 16.2767L9.77702 16.2768Z" fill="#C6C6C6"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4H7Z" fill="#C6C6C6"/>
          </svg>
        </div>

        <div class="transport-item__transporter">Перевозчик</div>
        <div class="transport-item__transporter-name">{{ data.organisation }}</div>

        <div class="transport-item__district">
          <span>Р-н обслуживания:</span>
          <span>{{ data.distrs[0] ? data.distrs[0].label : '-' }}</span>
        </div>
      </div>
    </div>
  </div>

  <transition name="default">
    <q-modal-transport
      v-if="isShowModalTransport"
      @close="isShowModalTransport= false"
      :data="data"
      type="edit"
      @setDataEdit="setDataEdit"
    />
  </transition>

  <transition name="default">
    <q-modal-transport-gallery-car
      :photo="data.photo"
      v-if="isShowGalleryCar"
      @close="isShowGalleryCar = false"
    />
  </transition>

  <transition name="default">
    <q-modal-delete-confirmation
      v-if="isShowModalDeleteTransport"
      @close="isShowModalDeleteTransport = false"
      title="Удаление ТС"
      :id="data.car_id"
      @delete="deleteTransport"
    >
      <span class="ts-number"> Уверены что хотите удалить ТС</span> <br/>
      № {{ prettyLicensePlate(data.state_number) }}
    </q-modal-delete-confirmation>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, inject, onMounted } from "vue";
import axios from 'axios'
import store from '@/store'
import useApi from '@/composables/useApi'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import QModalTransportGalleryCar from '@/components/modals/QModalTransportGalleryCar.vue'
import QModalTransport from '@/components/modals/QModalTransport.vue'
import prettyLicensePlate from '@/helpers/prettyLicensePlate'
import QModalDeleteConfirmation from '@/components/modals/QModalDeleteConfirmation.vue'

const assetsEnv = computed(()=> store.state.isDemoMode ? process.env.VUE_APP_BASE_API_DEMO_ASSETS : process.env.VUE_APP_BASE_API_ASSETS)

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['setDataEdit', 'deleteTransport'])

const isShowGalleryCar = ref(false)
const isShowModalTransport = ref(false)
const isShowModalDeleteTransport = ref(false)

const setDataEdit = pl => {
  emit('setDataEdit', pl)
}

const deleteTransport = async(id) => {
  if(store.state.isDemoMode){
    isShowModalDeleteTransport.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  const deleteTransp = await useApi('delete', `car/delete_car/${id}`)
  if(deleteTransp.status === 'success'){
    emit('deleteTransport', id)
    isShowModalDeleteTransport.value = false
  }
}
</script>

<style scoped lang="scss">
.transport-item {
  padding: 12px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 16px;
  position: relative;
  &.supervisory{
    padding-bottom: 60px;
    &:before{
      position: absolute;
      left: 0;
      top: 0;
      background: white;
      width: 100%;
      height: 100%;
      content: '';
      border-radius: 20px;
      opacity: 0.5;
      z-index: 6;
    }
    &:after{
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 12px;
      content: "Данное ТС добавлено контроллирующим органом";
      border-radius: 14px;
      background: #424242;
      color: white;
      padding: 8px 16px;
      z-index: 7;
    }
  }
  &__notification{
    position: absolute;
    right: 165px;
    cursor: pointer;
    z-index: 3;
    &.empty-rfid{
      right: 190px;
    }
    &:hover{
      z-index: 5;
      >.notification-info{
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__top{
    display: flex;
    position: relative;
  }
  &__panel{
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    background: linear-gradient(transparent, #292929);
    height: 50px;
    border-radius: 0 0 14px 14px;
  }
  &__image {
    margin-right: 32px;
    width: 198px;
    position: relative;
    transition: ease-out .4s;

    .default-image{
      width: 198px;
      height: 124px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: absolute;
      border-radius: 14px;
      left: 0;
      top: 0;
    }

    .image{
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      width: 198px;
      height: 124px;
      border-radius: 14px;
      position: relative;
    }
  }
  &__rfid{
    background: $caparol;
    padding: 4px 16px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 52px;
    top: 0;
    span{
      &:first-child{
        opacity: .5;
        margin-right: 10px;
      }
    }
  }
  &__number {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
  &__open-gallery {
    position: absolute;
    right: 12px;
    bottom: 6px;
    svg{
      path{
        transition: ease .3s;
      }
    }
    &:hover{
      svg{
        path{
          fill: #D1D1D1;
        }
      }
    }
  }
  &__btn-delete {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
  }
  &__info{
    flex: 1;
    margin-top: 4px;
  }
  &__model{
    opacity: .5;
    margin-top: 10px;
    font-size: 16px;
  }
  &__model-name{
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;

    svg{
      margin-left: 10px;
      cursor: pointer;
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2;
        }
      }
    }

  }
  &__transporter{
    opacity: .5;
    font-size: 16px;
    margin-top: 13px;
  }
  &__transporter-name{
    font-size: 16px;
    line-height: 20px;
  }
  &__category,
  &__capacity{
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background: $lemon-lime;
    border-radius: 14px;
    margin-top: 14px;

    &.multiple{
      flex-direction: column;
    }
  }
  &__capacity{
    background-color: $caparol;
    margin-top: 8px;
    .meter{
      position: relative;
      &:before{
        content: "3";
        position: absolute;
        right: -5px;
        top: -7px;
        font-size: 9px;
      }
    }
  }
  &__district{
    margin-top: 14px;
    span{
      display: block;
      font-size: 16px;
      &:first-child{
        opacity: .5;
      }
    }
  }
}
.ts-number{
  opacity: .5;
}



.notification-info{
  position: absolute;
  background: #393939;
  padding: 16px;
  width: 270px;
  border-radius: 14px;
  // top: 100px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: ease .3s;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  svg{
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
  .row{
    color: white;
    display: block;
    margin-top: 26px;
    position: relative;

    font-family: "TT Norms Pro Medium", sans-serif;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;

    &:before{
      content: "";
      height: 2px;
      width: 100%;
      background: #FFFFFF;
      opacity: 0.1;
      border-radius: 1px;
      position: absolute;
      top: -14px;
    }
    &:first-child {
      margin-top: 0px;
      &:before{
        display: none !important;
      }
    }
  }
}

.blocked-ro{
  width: 30px;
  height: 30px;
  border-radius: 12px;
  background: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 42px;
  cursor: pointer;
  z-index: 2;
  .notification-info{
    left: auto;
    right: -140px;
    top: 40px;
    svg{
      left: auto;
      right: 6px;
    }
  }
  &:hover{
    .notification-info{
      opacity: 1;
      visibility: visible;
    }
  }
}

.remove-btn{
  margin-left: 22px;
  cursor: pointer;
  rect{
    transition: ease .3s;
  }
  g{
    path{
      transition: ease .3s;
    }
  }
  &:hover{
    rect{
      stroke: #A0A0A0;
    }
    g{
      path{
        stroke: #A0A0A0;
      }
    }

  }
}

.readonly{
  .transport-item__capacity,
  .transport-item__model,
  .transport-item__model-name,
  .transport-item__transporter,
  .transport-item__transporter-name,
  .transport-item__district,
  .transport-item__btn-delete,
  .transport-item__rfid{
    opacity: .5;
    &:hover{
      pointer-events: none;
      .remove-btn{
        pointer-events: none !important;
        cursor: default;
      }
    }
  }
}
</style>
