<template>
  <div
    class="q-scroll-up-button"
    :class="{show, center: quantityPaginationPage === 1}"
    @click="scrollTop"
  >
    <img src="@/assets/images/scroll-up-button.svg" alt="Прокрутить страницу к началу">
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, onBeforeUnmount, watchEffect } from 'vue'

const props = defineProps({
  quantityPaginationPage: {
    type: Number,
    required: true,
  }
})

const scrollY = ref(0)
const show = ref(false)
const getScrollY = () => {
  scrollY.value = window.pageYOffset
}
onMounted(()=> window.addEventListener("scroll", getScrollY))
onBeforeUnmount(()=> window.removeEventListener("scroll", getScrollY))
watchEffect(() => scrollY.value > 200 ? show.value = true : show.value = false)

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
};
</script>

<style scoped lang="scss">
.q-scroll-up-button{
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: $warmweiss;
  position: fixed;
  right: 50%;
  top: 25%;
  transform: translateX(685px);
  cursor: pointer;
  transition: ease .3s;
  opacity: 0;
  visibility: hidden;
  &.show{
    opacity: 1;
    visibility: visible;
  }
  &.center{
    top: calc(50% - 28px);
  }
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
