<template>
  <div class="page">
    <div
      v-if="publicAct"
      class="act active"
    >
      <div class="act__top">
        <div class="act__id">Акт № {{ publicAct.number }}</div>
        <div class="act__period">{{`${moment(publicAct.date_in).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(publicAct.date_out).utc().format('HH:mm')}`}}</div>

        <div class="act__image">
          <div class="act__id">Акт № {{ publicAct.number }}</div>
          <div class="act__period">{{ `${moment(publicAct.date_in).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(publicAct.date_out).utc().format('HH:mm')}` }}</div>
          <div
            class="default-image"
            :style="{ backgroundImage: publicAct.preview === '' ? 'url(' + require('@/assets/images/noImg.svg') + ')' : `url(https://signall.qodex.tech/media/acts/full/${publicAct.preview})`}"
          />
          <div class="act__panel">
            <div class="act__number">
              <q-license-plate :number="publicAct.state_number"/>
            </div>
            <button class="act__open-gallery" @click="isShowModalVisit = true">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 18C22 20.2091 20.2091 22 18 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18C19.1046 20 20 19.1046 20 18V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V18ZM8 21C8 21.5523 7.55229 22 7 22H4C2.89543 22 2 21.1046 2 20V17C2 16.4477 2.44772 16 3 16C3.55228 16 4 16.4477 4 17V18.5858L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.41422 20H7C7.55229 20 8 20.4477 8 21ZM3 10C2.44772 10 2 9.55229 2 9V6C2 3.79086 3.79086 2 6 2H9C9.55229 2 10 2.44772 10 3C10 3.55228 9.55229 4 9 4H6C4.89543 4 4 4.89543 4 6V9C4 9.55229 3.55228 10 3 10ZM21 8C20.4477 8 20 7.55229 20 7V5.41422L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.5858 4H17C16.4477 4 16 3.55228 16 3C16 2.44772 16.4477 2 17 2H20C21.1046 2 22 2.89543 22 4V7C22 7.55229 21.5523 8 21 8Z" fill="white"/>
            </svg>
            </button>
          </div>
        </div>
        <div class="act__content">
          <div class="act__header">
              <div class="act__info">
                <div class="act-info">
                  <div class="act-info__item"><span>Брутто</span> {{ (publicAct.brutto / 1000).toFixed(2) }}</div>
                  <div class="act-info__separator"></div>
                  <div class="act-info__item"><span>Тара</span> {{ (publicAct.tara / 1000).toFixed(2) }}</div>
                  <div class="act-info__separator"></div>
                  <div class="act-info__item"><span>Нетто</span> {{ (publicAct.netto / 1000).toFixed(2) }}</div>
                </div>
            </div>
          </div>
          <div class="act__body">
            <div class="act-body-descr">
              <div class="act-body-descr__title">Перевозчик</div>
              <div class="act-body-descr__text">{{ publicAct.transporter.name }} </div>
            </div>
            <div class="act-body-descr">
              <div class="act-body-descr__title">Площадка</div>
              <div class="act-body-descr__text">{{ publicAct.platform }}</div>
            </div>
          </div>
          <div class="act__warnings">
            <div class="warning-item" :class="{activeWarn: publicAct.alerts.length}">
              <img :src="require(`@/assets/images/warning${publicAct.alerts.length ? '-active' : ''}.svg`)" alt="" />
              <span>{{publicAct.alerts.length}}</span>
            </div>
            <div class="warning-item" :class="{activeMess: publicAct.comments.length}">
              <img :src="require(`@/assets/images/messages${publicAct.comments.length ? '-active' : ''}.svg`)" alt="" />
              <span>{{publicAct.comments.length}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="act__bottom">
        <div class="bottom-left">
          <div class="bottom-left__info">
            <div class="bottom-left__info-title">Доп. данные</div>
            <div class="bottom-left__info-row">
              <span>Категория отходов</span>
              <span>{{publicAct.waste_category.label || '-'}}</span>
            </div>
            <div class="bottom-left__info-row">
              <span>Вид груза</span>
              <span>{{publicAct.waste_type.label || '-'}}</span>
            </div>
            <div class="bottom-left__info-row">
              <span>Объем кузова ТС</span>
              <span> {{ publicAct.capacity }} </span>
            </div>
            <div class="bottom-left__info-row">
              <span>Пункт назначения</span>
              <span> {{ publicAct.platform_type }} </span>
            </div>
            <div class="bottom-left__info-row">
              <span>Маршрут</span>
              <span>{{ publicAct.route}}</span>
            </div>
            <div class="bottom-left__info-row">
              <span>Собрано контейнеров</span>
              <span>{{ publicAct.containers}}</span>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right__separator"></div>
          <div class="bottom-right__activity">
            <div class="activity-item">
              <span class="activity-item__header">
                <img src="@/assets/images/warning.svg" alt="" />
                Предупреждения
              </span>
              <div class="activity-item__body">
                <span class="item empty" v-if="!publicAct.alerts.length">Предупреждений нет</span>
                <span
                  class="item"
                  v-for="(alert, idx) in publicAct.alerts"
                  :key="idx"
                >
                  <div class="warn-date">{{alert.date ? alert.date : 'Время не известно'}}</div>
                  <div class="text">{{alert.text}}</div>
                </span>
              </div>
            </div>
            <div class="activity-item">
              <span class="activity-item__header">
                <img src="@/assets/images/messages.svg" alt="" />
                Комментарии
              </span>
              <div class="activity-item__body">
                <span class="item empty" v-if="!publicAct.comments.length">Комментариев нет</span>
                <span
                  class="item"
                  v-for="(comment, idx) in publicAct.comments"
                  :key="idx"
                >
                  {{comment}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="default">
        <q-modal-acts-visit
          v-if="isShowModalVisit"
          @close="isShowModalVisit = false"
          :data="publicAct"
        />
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted , computed} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import useApi from '@/composables/useApi'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import QModalActsVisit from '@/components/modals/QModalActsVisit.vue'

const route = useRoute()
const router = useRouter()

const publicAct = ref(null)

const isShowModalVisit = ref(false)

const date = computed(() => {
  return `${moment(publicAct.value.date_in).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(publicAct.value.date_out).utc().format('HH:mm')}`
})

onMounted(async()=> {
  try {
    const getPublicAct = await useApi('get', `acts/get_act_by_id_non_auth/${route.params.id}`)
    publicAct.value = getPublicAct.acts[0]
  } catch (error) {
    router.push({path: '/not-found'})
  }
})
</script>

<style scoped lang="scss">
.page{
  padding: 88px 8px 100px 8px
}

.act{
  margin: 0 auto;
  padding: 14px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 16px;
  max-width: 462px;
  &__info{
    width: 100%;
    margin-top: 10px;
  }
  &__top{
    display: flex;
    flex-direction: column;
  }
  &__header-left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
  &__image {
    width: 431px;
    height: 258px;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: ease-out .4s;
    flex: none;

    .default-image{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
  &__panel{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, #292929);
    height: 50px;
  }
  &__number {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
  &__open-gallery {
    position: absolute;
    right: 12px;
    bottom: 6px;
    svg{
      margin-left: 10px;
      cursor: pointer;
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    position: relative;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    &-right {
      display: flex;
    }
  }
  &__id {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 4px;
    display: flex;

    img{
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &__period {
    color: $dulux-australia;
    width: 100%;
    margin-bottom: 14px;
  }
  &__btn-show-inner {
    width: 40px;
    height: 30px;
    border: 1px solid $california-paints;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-right: 4px;
    img{
      transition: ease-out .4s
    }
  }
  .act-info {
    background: $caparol;
    width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    &__item {
      span {
        color: $dulux-australia;
        margin-right: 8.5px;
      }
    }
    &__separator {
      width: 1px;
      height: 18px;
      background: $silver-bullet;
      border-radius: 2px;
      margin: 0 24px;
    }
  }
  .act-body-descr {
    &:first-child{
      margin-bottom: 15px;
    }
    &__title {
      color: rgba($ford-medium-dark, 0.5);
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      font-size: 16px;
      line-height: 20px;
      width: 314px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__warnings {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    transition: ease .1s;
  }
  .warning-item {
    display: flex;
    align-items: center;
    color: $dulux-australia;
    padding: 4px 13px;
    background: $caparol;
    border-radius: 12px;
    line-height: 13px;
    margin-left: 10px;
    span {
      display: block;
      margin-left: 5px;
      color: $dulux-australia;
    }
    &.activeWarn{
      background: $orange;
      span{
        color: $ford-medium-dark;
      }
    }
    &.activeMess{
      background: $blue-light;
      span{
        color: $ford-medium-dark;
      }
    }
  }

  &__bottom{
    overflow: hidden;
    height: 0px;
    transition: all .4s;

    .bottom-left{
      width: 100%;
      transition: ease-out .4s;
      transform: translateY(-30px);
      opacity: 0;
      transition-delay: .1s;
      flex: none;

      &__info{
        margin-top: 19px;
      }
      &__info-title{
        font-size: 18px;
        margin-bottom: 7px;
      }
      &__info-row{
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        span:first-child{
          opacity: .5;
        }
      }
    }

    .bottom-right{
      position: relative;
      padding-top: 35px;
      transition: ease-out .4s;
      transform: translateY(-30px);
      transition-delay: .1s;
      opacity: 0;
      &__separator{
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: $warmweiss;
        position: absolute;
        left: 0;
        top: 15px;
      }

      &__container{
        width: 255px;
        font-size: 14px;
        line-height: 20px;
        padding: 7px 16px;
        background: $caparol;
        border-radius: 14px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        span{
          opacity: .5;
        }
        &.lime{
          background-color: $lemon-lime;
          span{
            opacity: 1;
          }
        }
      }
      &__activity{

        padding-right: 5px;
        flex-grow: 1;

        .activity-item{
          &:first-child{
            margin-bottom: 15px;
          }
          &__header{
            font-size: 18px;
            margin-bottom: 11px;
            display: flex;
            img{
              margin-right: 4px;
            }
          }
          &__body{
            font-size: 14px;
            padding: 7px 16px;
            background: $caparol;
            border-radius: 14px;
            color: rgba($ford-medium-dark, .5);
            .item{
              display: block;
              margin-bottom: 7px;
              &:last-child{
                margin-bottom: 0;
              }
              &.empty{
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }

  &.active{
    .act__image{
      width: 100%;
      height: 258px;

      @media (max-width: 484px){
        width: calc(100vw - 50px);
        height: calc(calc(100vw - 30px) / 1.6);
      }
    }
    .act__btn-show-inner{
      img{
        transform: rotate(180deg);
      }
    }
    .act-body-descr__text{
      overflow: auto;
      white-space: normal;
    }
    .act__warnings{
      opacity: 0;
    }
    .act__bottom{
      height: -webkit-fill-available;
    }
    .bottom-left,
    .bottom-right{
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
