<template>
  <div class="districts">
    <h5>Статистические данные по району</h5>

    <div class="block-wrapper">
      <q-charts
        :series="isCompareMode ? compareData : districtsChartTonnage"
        :compareData="compareData"
        :polygonChartSumm="districtsChartTonnage"
        :polygonChartTonnage="districtsChartTonnage"
        :month="month"
        :year="year"
        customTooltip
        type="district"
      />

      <div class="table">
        <div class="thead">
          <div class="tr">
            <div class="td" style="width: 650px">
              <span>Все районы</span>
              <button
                class="compare-btn"
                :class="{active: isCompareMode}"
                @click="changeCompareMode"
              >
                <span>{{isCompareMode ? 'Сбросить' : 'Сравнить'}}</span>
                <img src="@/assets/images/сompare.svg">
              </button>
            </div>
            <div class="td" style="width: 153px">Фактический<br/> объем (т)</div>
            <div class="td" style="width: 153px">Тоннаж по<br/> тер.схеме (т)</div>
            <div class="td no-separator" style="width: 153px">Выполнение<br/> тер.схемы (%)</div>
          </div>
        </div>

        <div class="tbody">
          <div
            class="item"
            v-for="item in data.details"
            :key="item.id"
          >
            <div class="tr">
              <div class="td" style="width: 650px">
                <transition name="slide">
                  <q-checkbox
                    v-if="isCompareMode"
                    @clicked="chooseCompare(item)"
                    :checked="compareData.find(compareItem => compareItem.id === item.id) ? true : false"
                    :view="compareData[0] && compareData[0].id === item.id ? '1' : '2'"
                  />
                </transition>
                <div>
                  <div class="title">{{ item.name }}</div>
                  <div class="subtitle ">{{ item.platforms.join(', ') }}</div>
                </div>
                <img v-if="compareData[0] && compareData[0].id === item.id" class="compare-fixed" src="@/assets/images/compare-fixed.svg"/>
              </div>
              <div class="td" style="width: 153px">{{ item.tonnage.toFixed(2) }} т</div>
              <div class="td" style="width: 153px">{{ item.tonnage_scheme.toFixed(2) }} т</div>
              <div
                class="td no-separator"
                style="width: 153px"
                :style="`color: ${Math.round(item.tonnage_realization >= 100) ? '#EA3636' : '#1E85FF'}`"
              >
                {{ Math.round(item.tonnage_realization) }} %
              </div>
            </div>
          </div>
        </div>

        <div class="tfoot">
          <div class="tr">
            <div class="td" style="width: 650px">
              <div>
                <div class="title ">Общий итог</div>
                <div class="subtitle ">{{ data.details.length }} районов</div>
              </div>
            </div>
            <div class="td" style="width: 153px">{{ data.tonnage.toFixed(2) }} т</div>
            <div class="td" style="width: 153px"> {{ data.tonnage_scheme.toFixed(2) }} т</div>

            <div
              class="td no-separator"
              style="width: 153px"
              :style="`color: ${Math.round(data.tonnage_realization >= 100) ? '#EA3636' : '#1E85FF'}`"
              >
                {{ data.tonnage_realization.toFixed(2) }} %
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, provide, watch } from "vue"
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts"
import QCharts from "@/components/ui/QCharts.vue"

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  districtsChartTonnage: {
    type: Array,
  },
  month: {
    type: String
  },
  year: {
    type: String
  },
})

const isCompareMode = ref(false)
const compareData = ref([])
provide('isCompareMode', isCompareMode)

const changeCompareMode = () => {
  if(!isCompareMode.value){
    isCompareMode.value = true
  } else {
    isCompareMode.value = false
    compareData.value = []
  }
}

const chooseCompare = item => {
  const s = compareData.value.findIndex(compareItem => compareItem.id === item.id)

  if(s === 0 || s === 1){
    compareData.value.splice(s, 1)
    return
  }
  if(compareData.value.length === 2){
    return
  }

  compareData.value.push({
    isFixed: compareData.value.length ? true : false,
    id: item.id,
    name: item.name,
    data: item.period.tonnage.map(item => item === '' ? null : item),
    diff: item.period.diff_previous.map(item => item === '' ? null : item),
    scheme: item.period.tonnage_realization.map(item => item === '' ? null : item)
  })
}

watch(
  () => props.month,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
watch(
  () => props.year,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
</script>

<style scoped lang="scss">
  h5{
    margin-top: 36px;
    margin-left: 20px;
    margin-bottom: 30px;
  }
</style>
