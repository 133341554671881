<template>
  <q-modal
    @close="$emit('close')"
  >
    <div class="content">

      <div class="content__slide"
        :style="{ backgroundImage: data.photos.length && data.photos[currentPhoto].img ? `url(${assetsEnv}/media/${kpp ? 'passes/full/' : 'acts/full/'}${data.photos[currentPhoto].img})` : 'url(' + require('@/assets/images/noImg.svg') + ')' }"
        :class="{'show': !showSlide}"
      >
        <div class="content__row">
          <div class="cont">
            <q-license-plate :number="data.state_number"/>
            <span>Время въезда – {{ getDate(data.date_in) }}</span>
          </div>

          <img @click="prevPhoto" class="prev prev-desk" src="@/assets/images/buttonSlid.svg" alt="">

          <img @click="nextPhoto" class="next next-desk" src="@/assets/images/buttonSlid.svg" alt="">
          <img @click="prevPhoto" class="next next-adapt" src="@/assets/images/adapt-arr-act.svg" alt="">
        </div>
      </div>

      <img class="fake-img" :src="`${assetsEnv}/media/acts/full/${data.photo_in}`" alt="">
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import moment from 'moment'
import store from '@/store'

const assetsEnv = computed(()=> store.state.isDemoMode ? process.env.VUE_APP_BASE_API_DEMO_ASSETS : process.env.VUE_APP_BASE_API_ASSETS)

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  kpp: {
    type: Boolean,
    default: false
  }
})


const currentPhoto = ref(0)

const prevPhoto = () => {
  if(currentPhoto.value === 0){
    currentPhoto.value = props.data.photos.length - 1
  } else {
    currentPhoto.value--
  }
}

const nextPhoto = () => {
  if(currentPhoto.value === props.data.photos.length - 1){
    currentPhoto.value = 0
  } else {
    currentPhoto.value++
  }
}

const emit = defineEmits(['close'])

const showSlide = ref(false)
const getDate = (time) => {
  return moment(time).utc().format('DD.MM.YYYY ・ HH:mm')
}
</script>

<style scoped lang="scss">
.content{
  width: 1180px;
  height: 690px;
  position: realative;

  @media (max-width: 1200px){
    width: calc(100vw - 30px);
    height: calc(calc(100vw - 30px) / 1.7);
  }

  .default-image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 24px;
  }

  &__slide{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    transition: ease .3s;
    z-index: 2;
    opacity: 0;
    border-radius: 22px;
    visibility: hidden;
    &.show{
      opacity: 1;
      visibility: visible;
    }
  }

  &__row{
    padding: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 150px;
    background: linear-gradient(transparent, $gm-dark-gray);
    border-radius: 22px;



    .prev{
      position: absolute;
      left: 10px;
      bottom: 10px;
      cursor: pointer;
    }

    .next{
      position: absolute;
      right: 10px;
      bottom: 10px;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .cont{
      display: flex;
      align-items: center;
      span{
        flex: 1;
        color: $white;
        padding: 0 18px;
        margin-bottom: 4px;
      }
    }

    .prev-adapt,
    .next-adapt{
      display: none;
    }

    @media (max-width: 1330px){
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      >.cont{
        display: none;
      }
      >.prev{
        bottom: 50px;
        transform: rotate(180deg);

      }
      >.next{
        bottom: 50px;
        transform: rotate(0);
      }
      .prev-desk,
      .next-desk{
        display: none;
      }
      .prev-adapt,
      .next-adapt{
        display: block;
      }
    }
  }
}

.fake-img{
  max-height: 100%;
  opacity: 0;
  visibility: hidden;
}
</style>
