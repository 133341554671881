<template>
  <div v-if="store.state.user.access === 'rw'" class="weight-row">
    <h5>Категории грузов</h5>

    <div class="add-weight-category">
      <button
        class="add-new"
        @click="isShowQModalWasteAddCategory = true"
      >
        <img src="@/assets/images/add.svg" alt="">
        Добавить
      </button>
    </div>
  </div>

  <div v-if="store.state.user.access === 'rw'" class="weight-list">

    <div
      class="weight-item"
      v-for="(item, idx) in wasteList"
      :key="item.id"
      :class="{readonly: item.access === 'ro'}"
    >
      <div class="weight-item__head">

        <div class="blocked-ro" v-if="item.access === 'ro'">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33398 5.99967V5.33301C5.33398 3.86025 6.52789 2.66634 8.00065 2.66634C9.47341 2.66634 10.6673 3.86025 10.6673 5.33301V5.99967L5.33398 5.99967ZM4.00065 6.22971V5.33301C4.00065 3.12387 5.79151 1.33301 8.00065 1.33301C10.2098 1.33301 12.0007 3.12387 12.0007 5.33301V6.22971C12.4017 6.48787 12.6673 6.93824 12.6673 7.45065V9.99967C12.6673 12.577 10.578 14.6663 8.00065 14.6663C5.42332 14.6663 3.33398 12.577 3.33398 9.99967V7.45065C3.33398 6.93824 3.5996 6.48787 4.00065 6.22971ZM4.78496 7.33301C4.71999 7.33301 4.66732 7.38568 4.66732 7.45065L4.66732 9.99967C4.66732 11.8406 6.1597 13.333 8.00065 13.333C9.8416 13.333 11.334 11.8406 11.334 9.99967V7.45065C11.334 7.38568 11.2813 7.33301 11.2163 7.33301H10.6673H5.33398H4.78496ZM8.00065 8.66634C8.73703 8.66634 9.33398 9.2633 9.33398 9.99967C9.33398 10.7361 8.73703 11.333 8.00065 11.333C7.26427 11.333 6.66732 10.7361 6.66732 9.99967C6.66732 9.2633 7.26427 8.66634 8.00065 8.66634Z" fill="white"/>
          </svg>

          <div class="notification-info">
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 7L-1.25965e-06 7L5.58579 1.41421C6.36684 0.633163 7.63316 0.633165 8.41421 1.41421L14 7Z" fill="#393939"/></svg>

            <div class="rows">
              <span class="row"> {{ item.access_msg }}</span>
            </div>
          </div>
        </div>

        <svg :class="{active: item.visibleTypes}" class="arr" v-if="item.waste_type_selector.length" @click="showCategoryTypes(idx)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 10.5L10.9393 14.6893C11.5251 15.2751 12.4749 15.2751 13.0607 14.6893L17.25 10.5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span style="cursor: pointer" @click="item.waste_type_selector.length ? showCategoryTypes(idx) : 1">{{item.label}}</span>

        <svg class="remove-btn" @click="item.access === 'ro' ? 1 : deleteWasteCategoryPrepare(item)" width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="29" rx="11.5" stroke="#C6C6C6"/>
            <g clip-path="url(#clip0_12500_104040)">
            <path d="M25 15H20H15" stroke="#C6C6C6" stroke-width="2.5" stroke-linecap="round"/>
            </g>
            <defs>
            <clipPath id="clip0_12500_104040">
            <rect width="14" height="14" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 27 8)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="weight-item__subhead">
        <div @click="item.access === 'ro' ? 1 : addType(item)" class="add-type">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00033 2.6665V13.3332M2.66699 7.99991L13.3337 7.99991" stroke="#C6C6C6" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span>Вид груза</span>
        </div>
        <div class="vis-gravity">
          <span>Видимость в Gravity</span>
          <QToggle
            :checked="item.status"
            @click="changeGravityStatus(item)"
            :disabled="item.access === 'ro'"
          />
        </div>
      </div>

        <div
          class="weight-item__types-list"
          :class="{active: item.visibleTypes}"
          :id="item.id"
        >
          <div
            class="type"
            v-for="atype in item.waste_type_selector"
            :key="atype.id"
          >
            <span>{{ atype.label }}</span>
            <QToggle
              :checked="atype.status"
              @click="changeTypeStatus(item.id, atype)"
              :disabled="item.access === 'ro'"
            />
            <svg class="remove-btn" @click="item.access === 'ro' ? 1 : deleteWasteTypePrepare(atype, item)" width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="39" height="29" rx="11.5" stroke="#C6C6C6"/>
              <g clip-path="url(#clip0_12500_104040)">
              <path d="M25 15H20H15" stroke="#C6C6C6" stroke-width="2.5" stroke-linecap="round"/>
              </g>
              <defs>
              <clipPath id="clip0_12500_104040">
              <rect width="14" height="14" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 27 8)"/>
              </clipPath>
            </defs>
          </svg>
          </div>
        </div>
    </div>
  </div>

  <transition name="default">
    <QModalWasteAddCategory
      v-if="isShowQModalWasteAddCategory"
      @close="isShowQModalWasteAddCategory = false"
      @successSended="successAddedCategory"
    />
  </transition>

  <transition name="default">
    <QModalWasteAddType
      v-if="isShowQModalWasteAddType"
      @close="isShowQModalWasteAddType = false"
      @updateWasteList="getWasteList"
      @notification="notify({ title: $event, type: 'success'})"
      :type="bufferWasteCategory"
    />
  </transition>

  <transition name="default">
    <QModalConfirmation
      v-if="isShowQModalConfirmationDeleteCategory"
      @close="isShowQModalConfirmationDeleteCategory = false"
      :data="bufferWasteCategory"
      :title="`Удаление категории груза “${bufferWasteCategory.label}”`"
      @confirm="deleteWasteCategory($event)"
    >
        Вы точно хотите удалить “{{bufferWasteCategory.label}}”? <br/><br/>
        Акты этой категории будут перенесены на категорию “Прочее”
    </QModalConfirmation>
  </transition>

  <transition name="default">
    <QModalConfirmation
      v-if="isShowQModalConfirmationDeleteType"
      @close="isShowQModalConfirmationDeleteType = false"
      :data="bufferWasteType"
      :title="`Удаление вида груза у категории “${bufferWasteType.categoryLabel}”`"
      @confirm="deleteWasteType($event)"
    >
        Вы точно хотите удалить “{{bufferWasteType.typeLabel}}”?<br/><br/>
        Акты этого вида груза будут закреплены только за категорией груза
    </QModalConfirmation>
  </transition>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import store from '@/store'
import QModalWasteAddCategory from '@/components/modals/QModalWasteAddCategory.vue'
import QModalWasteAddType from '@/components/modals/QModalWasteAddType.vue'
import QModalConfirmation from '@/components/modals/QModalConfirmation.vue'
import useApi from '@/composables/useApi'
import { notify } from '@kyvg/vue3-notification'

const isShowQModalWasteAddCategory = ref(false)
const isShowQModalWasteAddType = ref(false)

const isShowQModalConfirmationDeleteCategory = ref(false)
const isShowQModalConfirmationDeleteType = ref(false)


const bufferWasteCategory = ref(null)
const bufferWasteType = ref(null)


const wasteList = ref([])

const successAddedCategory = (category, label) => {
  if(store.state.isDemoMode){
    store.dispatch('setDemoModal', true)
    return
  }
  bufferWasteCategory.value = category
  isShowQModalWasteAddCategory.value = false
  notify({ title: `Категория груза "${label}" успешно добавлена!`, type: 'success'})

  getWasteList()
  if(category){
    isShowQModalWasteAddType.value = true
  }
}

const getWasteList = async() => {
  const data = await useApi('get', `settings/waste/category`)
  wasteList.value = data.waste.map(item => {
    return {
      ...item,
      visibleTypes: false
    }
  })
}

const showCategoryTypes = idx => {

  if(wasteList.value[idx].visibleTypes){
    wasteList.value[idx].visibleTypes = false
  } else {
    wasteList.value.forEach(el => {
      el.visibleTypes = false
    })
    wasteList.value[idx].visibleTypes = true

    setTimeout(()=> {
      document.querySelector(`#${wasteList.value[idx].id}`).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 200)
  }
}

const addType = item => {
  bufferWasteCategory.value = item
  isShowQModalWasteAddType.value = true
}


const deleteWasteCategoryPrepare = data => {
  bufferWasteCategory.value = data
  isShowQModalConfirmationDeleteCategory.value = true
}

const deleteWasteCategory = async (data) => {
  if(store.state.isDemoMode){
    isShowQModalConfirmationDeleteCategory.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  const res = await useApi('delete', `waste/category_delete/${data.id}`)
  if(res.status === 'success'){
    isShowQModalConfirmationDeleteCategory.value = false
    getWasteList()
    notify({ title: `Категория “${data.label}” успешно удалена!`, type: 'success'})
  }
}

const deleteWasteTypePrepare = (type, category)  => {
  bufferWasteType.value = {
    categoryId: category.id,
    categoryLabel: category.label,
    typeId: type.id,
    typeLabel: type.label
  }
  isShowQModalConfirmationDeleteType.value = true
}

const deleteWasteType = async (data) => {

  if(store.state.isDemoMode){
    isShowQModalConfirmationDeleteType.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  const res = await useApi('delete', `waste/type_delete/${data.categoryId}/${data.typeId}`)

  const currArrTypes = wasteList.value[wasteList.value.findIndex(item => data.categoryId === item.id)].waste_type_selector
  currArrTypes.splice(currArrTypes.findIndex(item => item.id === data.typeId), 1)
  isShowQModalConfirmationDeleteType.value = false
  notify({ title: `Вид груза “${data.typeLabel}” успешно удален!`, type: 'success'})
}

const changeGravityStatus = async(item) => {

  if(store.state.isDemoMode){
    store.dispatch('setDemoModal', true)
    return
  }

  const res = await useApi('post', `waste/category_set_status`, {
    id: item.id,
    status: !item.status
  })
  if(res.status === 'success'){
    getWasteList()
    notify({ title: `Видимость в Gravity для категории  “${item.label}” успешно изменена!`, type: 'success'})
  }
}

const changeTypeStatus = async(categoryId, type) => {
  if(store.state.isDemoMode){
    store.dispatch('setDemoModal', true)
    return
  }

  const res = await useApi('post', `waste/type_set_status`, {
    category_id: categoryId,
    id: type.id,
    status: !type.status
  })
  if(res.status === 'success'){
    const currArrTypes = wasteList.value[wasteList.value.findIndex(item => categoryId === item.id)].waste_type_selector
    currArrTypes[currArrTypes.findIndex(item => item.id === type.id)].status = !currArrTypes[currArrTypes.findIndex(item => item.id === type.id)].status
    notify({ title: `Статус вида груза “${type.label}” успешно изменен!`, type: 'success'})
  }
}

onMounted(()=> {
  getWasteList()
})

watch(
  () => store.state.user.role,
  () => getWasteList()
)

</script>

<style scoped lang="scss">
.weight-row{
  display: flex;
  align-items: center;
  margin-top: 35px;
  gap: 0 24px;
  margin-bottom: 24px;
  padding-left: 7px;
}

.weight-list{
  display: flex;
  flex-direction: column;
  gap: 14px 0;
}

.weight-item{
  width: calc(50% - 10px);
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
  &__head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .notification-info{
  position: absolute;
  background: #393939;
  padding: 16px;
  width: 270px;
  border-radius: 14px;
  // top: 100px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: ease .3s;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  font-family: "TT Norms Pro Medium", sans-serif;
  svg{
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
  .row{
    color: white;
    display: block;
    margin-top: 26px;
    position: relative;
    width: 100%;
    font-size: 14px !important;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    &:before{
      content: "";
      height: 2px;
      width: 100%;
      background: #FFFFFF;
      opacity: 0.1;
      border-radius: 1px;
      position: absolute;
      top: -14px;
    }
    &:first-child {
      margin-top: 0px;
      &:before{
        display: none !important;
      }
    }
  }
}

    .blocked-ro{
      width: 30px;
      height: 30px;
      border-radius: 12px;
      background: #555;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 56px;
      top: 0px;
      cursor: pointer;
      .notification-info{
        left: auto;
        right: -140px;
        top: 40px;
        svg{
          left: auto;
          right: 6px;
        }
      }
      &:hover{
        .notification-info{
          opacity: 1;
          visibility: visible;
        }
      }
    }
    span{
      flex: 1;
      padding-right: 16px;
      font-size: 16px;
      width: 445px;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
    }
    svg{
      cursor: pointer;
    }
    .arr{
      margin-right: 16px;
      transition: ease .3s;
      &.active{
        transform: rotate(180deg);
      }
      path{
        transition: ease .3s;
        stroke: #C6C6C6;
      }
      &:hover{
        path{
          stroke: #b2b2b2;
        }
      }
    }
  }
  &__subhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    .add-type{
      display: flex;
      align-items: center;
      padding: 4px 12px;
      gap: 8px;
      width: 120px;
      height: 30px;
      border: 1px solid rgba(66, 66, 66, 0.3);
      border-radius: 12px;
      transition: ease .3s;
      cursor: pointer;
      span{
        white-space: nowrap;
        color: #C6C6C6;
        transition: ease .3s;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
      svg > path {
        transition: ease .3s;
      }
      &:hover{
        border: 1px solid #B2B2B2;
        span{
          color: #B2B2B2
        }
        svg{
          path{
            stroke: #B2B2B2
          };
        }
      }
    }
    .vis-gravity{
      display: flex;
      align-items: center;
      span{
        margin-right: 16px;
        opacity: .5;
      }
    }
  }
  &__types-list{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    max-height: 0;
    transition: all .5s ease-out;
    overflow: hidden;
    opacity: 0;
    &.active{
      max-height: 100vh;
      transition: all .5s ease-in;
      opacity: 1;
    }
    .type{
      background: #F4F4F4;
      padding: 14px 16px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
      svg{
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }

}


.remove-btn{
  margin-left: 22px;
  cursor: pointer;
  rect{
    transition: ease .3s;
  }
  g{
    path{
      transition: ease .3s;
    }
  }
  &:hover{
    rect{
      stroke: #A0A0A0;
    }
    g{
      path{
        stroke: #A0A0A0;
      }
    }

  }
}

.readonly{
  .remove-btn{
    pointer-events: none;
    opacity: 0.5;

  }
  .add-type{
    pointer-events: none;
    opacity: 0.5;
    &:hover{
      border-color: #C6C6C6;
      span{
        color: #C6C6C6
      }
      svg{
        path{
          stroke: #C6C6C6
        };
      }
    }

  }
}
</style>