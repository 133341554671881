<template>
  <div
    class="q-select"
    ref="target"
    :class="{
      white,
      switcher
    }"
  >
    <div
      class="q-select__label"
      @click="isOpenSelect = !isOpenSelect"
    >
      <span :class="{empty: selectedItem.label === 'Не выбрано' || selectedItem.label === 'Выберите район' || selectedItem.label === 'Выберите Марку ТС'}">{{selectedItem.label}}</span>
      <img
        src="@/assets/images/arrow-grey.svg"
        alt="открыть/закрыть селект"
        class="q-select__arrow"
        :class="{active: isOpenSelect}"
      >
    </div>
    <transition name="up">
      <div
        v-if="isOpenSelect"
        class="swither-list"
        @mouseleave="leaveSections"
      >
        <section ref="s1" @mouseover="hoverFirstSection">
          <div class="swither-list__label">Категории груза</div>
          <ul class="swither-list__list">
            <template
              v-for="item in items"
              :key="item.id"
            >
              <li
                class="q-select__item"
                :class="{
                  active: selectedItem.id === item.id,
                  subtree: item.children && item.children.length
                }"
                v-if="item.label !== 'Удаленные'"
                @mouseover="item.children.length ? fillSecondSection(item.children, false) : currentSection = 1"
                @click="setValue(item)"
              >
                <img v-if="item.children && item.children.length" src="@/assets/images/select-back.svg">
                <span>{{ item.label }}</span>
              </li>
            </template>
          </ul>
          <div
            @mouseover="fillSecondSection(items.find(f => f.label === 'Удаленные').children, true)"
            v-if="items.find(f => f.label === 'Удаленные')"
            class="swither-list__deleted q-select__item subtree"
          >
            <img src="@/assets/images/select-back.svg">
            <span>Удаленные</span>
          </div>
        </section>


        <section
          ref="s2"
          :style="{minHeight: s2H+'px'}"
          @mouseover="hoverSecondSection"
          v-if="currentSection > 1"
        >
          <div class="swither-list__label">{{ deletedType ? 'Удаленные категории' : 'Вид груза'}}</div>
          <ul
            class="swither-list__list"
            :style="{maxHeight: s2buffer.find(f => f.label === 'Удаленные') ? '240px' : '300px'}"
          >
            <template
              v-for="item in s2buffer"
              :key="item.id"
            >
              <li
                class="q-select__item"
                :class="{
                  active: selectedItem.id === item.id,
                  subtree: item.children && item.children.length
                }"
                @click="setValue(item)"
                v-if="item.label !== 'Удаленные'"
                @mouseover="item.children && item.children.length ? fillThirdSection(item.children) : currentSection = 2"
              >
                <img v-if="item.children && item.children.length" src="@/assets/images/select-back.svg">
                <span>{{ item.label }}</span>
              </li>
            </template>
          </ul>
          <div
            @mouseover="fillThirdSection(s2buffer.find(f => f.label === 'Удаленные').children)"
            v-if="s2buffer.find(f => f.label === 'Удаленные')"
            class="swither-list__deleted q-select__item subtree"
          >
            <img src="@/assets/images/select-back.svg">
            <span>Удаленные</span>
          </div>
        </section>

        <section
          v-if="currentSection > 2 && s3buffer.length"
          ref="s3"
          :style="{minHeight: s3H +'px'}"
        >
          <div class="swither-list__label">Удаленные виды</div>
          <ul class="swither-list__list">
            <template
              v-for="item in s3buffer"
              :key="item.id"
            >
              <li
                class="q-select__item"
                :class="{
                  active: selectedItem.id === item.id,
                }"
                @click="setValue(item)"
                v-if="item.label !== 'Удаленные'"
              >
                <span>{{ item.label }}</span>
              </li>
            </template>
          </ul>
        </section>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  selectedItem: {
    type: Object,
  },
  maxHeight: {
    type: Number,
  },
  filter: {
    type: Boolean,
    default: false
  },
  sort: {
    type: Boolean,
    default: false
  },
  black: {
    type: Boolean,
    default: false
  },
  white: {
    type: Boolean,
    default: false
  },
  multi: {
    type: Boolean,
    default: false
  },
  switcher: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['changeSelect', 'setSwitcherValue'])

const isOpenSelect = ref(false)

const target = ref(null)

onClickOutside(target, (event) => isOpenSelect.value = false)

const setValue = (item, parent) => {
  emit('changeSelect', item)
  currentSection.value = 1
  isOpenSelect.value = false
  emit('setSwitcherValue', item)
}

const s1 = ref(null)
const s2 = ref(null)
const s2H = ref(0)
const s3H = ref(0)

const deletedType = ref(false)

const currentSection = ref(1)

const hoverFirstSection = () => s2H.value = s1.value.clientHeight

const hoverSecondSection = () => s3H.value = s2.value.clientHeight


const leaveSections = () => {
  currentSection.value = 1
}

const s2buffer = ref([])
const s3buffer = ref([])


const fillSecondSection = (arr, type) => {
  currentSection.value = 2
  s2buffer.value = arr
  deletedType.value = type
}

const fillThirdSection = (arr) => {
  currentSection.value = 3
  s3buffer.value = arr
}

</script>


<style scoped lang="scss">
.q-select {
  position: relative;

  &__label{
    height: 40px;
    background: $caparol;
    padding: 8px 40px 8px 18px;
    border-radius: 14px;
    cursor: pointer;
    span{
      text-wrap: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      &.empty{
        opacity: 0.5;
      }
    }
  }

  &__arrow{
    position: absolute;
    right: 12px;
    top: 13px;
    transition: ease .2s;

    &.active{
      transform: rotate(180deg);
    }
  }

  &__list,
  .children__wrapp{
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    padding: 16px 8px;
    background: #FAFAFA;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
    border-radius: 22px;
    z-index: 11;
    ul{
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 2px 0;
    }
  }
  .children__wrapp{
    overflow-y: auto;
  }
  &__item,
  .children__item{
    margin-bottom: 0px !important;
    padding: 8px 10px;
    padding-right: 25px;
    border-radius: 14px;
    font-size: 16px;
    transition: ease .3s;
    cursor: pointer;
    position: relative;
    span{
      max-width: 100%;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      // text-wrap: nowrap;
    }
    &.active{
      >span{
        opacity: .3;
      }
      &:before{
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba($ford-medium-dark, .3);
      }
      &:hover{
        background: transparent;
      }
    }
    &:hover{
      background: #E0E0E0;
    }
    img{
      position: absolute;
    }
    // span{
    //   font-size: 16px;
    // }
  }

  .up-enter-active,
  .up-leave-active {
    transition: .2s ease;
  }

  .up-enter-from,
  .up-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
}
.white{
  .q-select__label{
    background: #F4F4F4;
    transition: ease .3s;
    &:hover{
      background: #D1D1D1;
    }
  }
}
.subtree{
  padding-left: 44px;
  img{
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
  span{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .children{
    opacity: 0;
    transition: ease .3s;
    visibility: hidden;
    position: absolute;
    right: 100%;
    padding-right: 7px;
    top: auto;
    bottom: -96px;
    z-index: 2;
    width: calc(100% + 16px);

    &__wrapp{
      position: relative;
      top: auto;
      padding: 16px 8px;
      background: $ford-medium-dark;
      box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
      gap: 2px 0;
      display: flex;
      flex-direction: column;
    }
    &__item{
      color: white;
      span{
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }
  }
  &:hover{
    .children{
      opacity: 1;
      visibility: visible;
    }
  }
}
.multi{
  overflow-y: visible;
  ul{
    overflow-y: visible;
  }
}
// .switcher{
//   .children{
//     top: -16px;
//     bottom: auto;
//     z-index: 2;
//     width: calc(100% + 16px);
//     .children__wrapp{
//       background: #FAFAFA;
//       box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
//       .children__item{
//         color: $ford-medium-dark;
//         font-size: 16px;
//       }
//     }
//   }
// }


.swither-list{
  position: absolute;
  right: 0;
  top: 40px;
  top: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  gap: 0px 5px;

  &__label{
    color: #A0A0A0;
    font-size: 12px;
    padding: 10px 8px;
    padding-top: 0px;
  }

  &__list{
    max-height: 240px;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px 0;
  }

  &__deleted{
    margin-top: 10px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      background: #E0E0E0;
      height: 2px;
      border-radius: 4px;
    }
  }

  section{
    width: 200px;
    padding: 16px 8px;
    border-radius: 22px;
    background: white;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.10);
    max-height: 352px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .q-select__item{
      margin-bottom: 0px !important;
      padding: 8px 10px;
      padding-right: 25px;
      border-radius: 14px;
      font-size: 16px;
      transition: ease .3s;
      cursor: pointer;
      position: relative;
      span{
        max-width: 100%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        // text-wrap: nowrap;
      }
      &.active{
        >span{
          opacity: .3;
        }
        &:before{
          content: '';
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba($ford-medium-dark, .3);
        }
        &:hover{
          background: transparent;
        }
      }
      &:hover{
        background: #E0E0E0;
      }
      img{
        position: absolute;
      }
      &.subtree{
        padding-left: 38px;
      }
    }
  }
}
</style>
