<template>
  <div class="user">
    <div class="user-admin">
      <div class="user-admin__info">
        <p> {{store.state.user.access === 'rw' ? 'Администратор' : 'Сотрудник' }}</p>
        <span>{{ store.state.user.company }}</span>
      </div>
      <div class="user-admin__btns">
        <q-button
          label="Изменить e-mail"
          view="secondary"
          @clicked="isShowQModalSettingsChangeAdminEmail = true"
        />
        <q-button
          label="Изменить пароль"
          view="secondary"
          @clicked="isShowQModalSettingsChangeAdminPassword = true"
        />
      </div>
    </div>

    <div v-if="store.state.user.access === 'rw'" class="user-workers">
      <div class="user-workers__row">
        <h5>Сотрудники</h5>

        <div class="add-user">
          <button
            class="add-new"
            @click="addUser"
          >
            <img src="@/assets/images/add.svg" alt="">
            Добавить
          </button>
          <div ref="target" v-if="isShowUserPanel" class="add-user__panel">
            <span @click="openModal('s')">Signall</span>
            <span @click="openModal('g')">Gravity</span>
          </div>
        </div>
      </div>

      <div class="user-workers__list">
        <div
          class="user-item"
          v-for="worker in workers"
          :key="worker.id"
        >
          <div class="left">
            <div class="tech">{{ worker.signal ? 'Signall' : 'Gravity' }}</div>
            <div class="name">{{ worker.signal ? worker.name : worker.full_name }}</div>
            <div class="grey">
              <p>{{ worker.signal ? 'e-mail:' : 'Пароль:' }}</p>
              <span>{{ worker.signal ? worker.email : worker.password }}</span>
            </div>
          </div>

          <div class="right">
            <svg :class="{disabled: worker.super}" @click="worker.super ? 1 : editWorker(worker)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.229 13.8625L19.8492 6.24229C20.2397 5.85177 20.2397 5.2186 19.8492 4.82808L19.7257 4.70458C19.3352 4.31406 18.702 4.31406 18.3115 4.70458L10.6912 12.3248L12.229 13.8625ZM9.77702 16.2768C8.94858 16.2768 8.27699 15.6052 8.277 14.7768L8.27703 11.9106L16.8973 3.29037C18.0688 2.1188 19.9683 2.11879 21.1399 3.29037L21.2634 3.41387C22.435 4.58544 22.435 6.48493 21.2634 7.6565L12.6432 16.2767L9.77702 16.2768Z" fill="#C6C6C6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4H7Z" fill="#C6C6C6"/>
            </svg>
            <QToggle
              :checked="worker.status"
              @click="disconnectUser(worker)"
              :disabled="worker.super"
            />
          </div>
        </div>

      </div>
    </div>

    <transition name="default">
      <QModalSettingsUser
        v-if="isShowQModalSettingsUserSignall"
        @close="isShowQModalSettingsUserSignall = false"
        type="Signall"
        @updateUsersList="getUsersList"
        :data="userItemBuffer"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>

    <transition name="default">
      <QModalSettingsUser
        v-if="isShowQModalSettingsUserGravity"
        @close="isShowQModalSettingsUserGravity = false"
        type="Gravity"
        @updateUsersList="getUsersList"
        :data="userItemBuffer"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>

    <transition name="default">
      <QModalDisconnectUser
        v-if="isShowQModalDisconnectUser"
        @close="isShowQModalDisconnectUser = false"
        :data="userItemBuffer"
        @disconnectUser="changeUserStatus($event)"
      />
    </transition>

    <transition name="default">
      <QModalSettingsChangeAdminEmail
        v-if="isShowQModalSettingsChangeAdminEmail"
        @close="isShowQModalSettingsChangeAdminEmail = false"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>

    <transition name="default">
      <QModalSettingsChangeAdminPassword
        v-if="isShowQModalSettingsChangeAdminPassword"
        @close="isShowQModalSettingsChangeAdminPassword = false"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>
  </div>
</template>

<script setup>
import { onMounted , ref, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import useApi from '@/composables/useApi'
import store from '@/store'
import QModalSettingsUser from '@/components/modals/QModalSettingsUser.vue'
import QModalDisconnectUser from '@/components/modals/QModalDisconnectUser.vue'
import QModalSettingsChangeAdminEmail from '@/components/modals/QModalSettingsChangeAdminEmail.vue'
import QModalSettingsChangeAdminPassword from '@/components/modals/QModalSettingsChangeAdminPassword.vue'
import { notify } from '@kyvg/vue3-notification'

const role = computed(() => store.state.user.role)


const target = ref(null)
onClickOutside(target, (event) => isShowUserPanel.value = false)


const workers = ref([])
const userItemBuffer = ref(null)

onMounted(()=> {
  getUsersList()
})

watch(
  () => store.state.user.role,
  () => getUsersList()
)

const getUsersList = async() => {
  workers.value = []

  const workersListSignall = await useApi('get', 'settings/get_user')
  const workersListGravity = await useApi('get', 'settings/get_weigher')

  workers.value.push(...workersListSignall.users.map(item => {
    return {
      ...item,
      signal: true
    }
  }))
  workers.value.push(...workersListGravity.users)
}

const isShowQModalSettingsUserSignall = ref(false)
const isShowQModalSettingsUserGravity = ref(false)
const isShowUserPanel = ref(false)
const isShowQModalDisconnectUser = ref(false)
const isShowQModalSettingsChangeAdminEmail = ref(false)
const isShowQModalSettingsChangeAdminPassword = ref(false)




const addUser = () => {
  userItemBuffer.value = null

  if(role.value === 1){
    isShowQModalSettingsUserSignall.value = true
  }
  else if(role.value === 2){
    isShowUserPanel.value = true
  } else if(role.value === 6){
    isShowQModalSettingsUserSignall.value = true
  } else {
    isShowQModalSettingsUserGravity.value = true
  }
}

const editWorker = worker => {
  userItemBuffer.value = worker
  worker.signal ? openModal('s') : openModal('g')
}

const openModal = type => {
  if(type === 's'){
    isShowQModalSettingsUserSignall.value = true
  } else {
    isShowQModalSettingsUserGravity.value = true
  }
  isShowUserPanel.value = false
}

const disconnectUser = user => {
  if(user.status){
    userItemBuffer.value = user
    isShowQModalDisconnectUser.value = true
  } else {
    changeUserStatus(user)
  }
}

const changeUserStatus = async(user) => {
  if(store.state.isDemoMode){
    isShowQModalDisconnectUser.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  const updateStatus = await useApi('post', `settings/set_status_${user.signal ? 'user' : 'weigher'}`, {
    id: user.id,
    status: !user.status
  })

  if(updateStatus.status === 'success'){
    isShowQModalDisconnectUser.value = false
    workers.value[workers.value.findIndex(worker => worker.id === user.id)].status = !user.status
    notify({ title: `Личный кабинет ${user.signal ? "Signall" : "Gravity"} для ${user.full_name ? user.full_name : user.name} ${workers.value[workers.value.findIndex(worker => worker.id === user.id)].status ? 'активирован' : 'отключен'}!`, type: 'success'})
  }
}
</script>

<style scoped lang="scss">
.user-admin{
  padding: 36px;
  background: #FFFFFF;
  border-radius: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  &__info{
    p{
      font-size: 18px;
      opacity: 0.5;
      margin-bottom: 8px;
    }
    span{
      font-size: 22px;
      line-height: 26px;
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      word-wrap: break-word;
    }
  }
  &__btns{
    display: flex;
    gap: 0 24px;
    :deep(.q-button){
      width: 200px;
    }
  }
}

.user-workers{
  &__row{
    display: flex;
    align-items: center;
    gap: 0 24px;
    margin-bottom: 24px;
    padding-left: 7px;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px
  }
}

.user-item{
  padding: 16px 12px 16px 24px;
  background: #FFFFFF;
  border-radius: 20px;
  width: calc(50% - 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    .tech{
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: rgba(66, 66, 66, 0.5);
    }
    .name{
      font-size: 16px;
      margin-bottom: 6px;
      width: 445px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
    .grey{
      padding: 5px 16px;
      background: #F4F4F4;
      border-radius: 12px;
      display: flex;
      align-items: center;
      width: fit-content;
      p{
        font-size: 14px;
        line-height: 20px;
        color: rgba(66, 66, 66, 0.5);
        margin-right: 10px;
      }
      span{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .right{
    display: flex;
    align-items: center;
    gap: 0 24px;
    svg{
      cursor: pointer;
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2;
        }
      }
      &.disabled{
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}

.add-user{
  position: relative;
  &__panel{
    position: absolute;
    left: 0;
    top: 42px;
    padding: 16px 8px;
    background: white;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    gap: 2px 0;
    width: 210px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
    span{
      font-size: 16px;
      line-height: 22px;
      padding: 8px 10px;
      transition: ease .3s;
      border-radius: 14px;
      cursor: pointer;
      &:hover{
        background: rgba(85, 85, 85, 0.1);
      }
    }
  }
}

</style>