import { useRoute, useRouter } from 'vue-router'
import store from '@/store'

export default function useRouterGuard(page: string = ''): void {
  const route = useRoute()
  const router = useRouter()

  if(page === 'login' && store.state.user.token) {
    router.push('/analytics')
  }

  if(route.meta.isAuth && !store.state.user.token) {
    router.push('/')
  }
}
