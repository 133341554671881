<template>
  <div class="page">
    <teleport to="#header__left-teleport-container">
      <button
        class="add"
        @click="isShowModalAddRfid = true"
      >
        <img src="@/assets/images/add.svg" alt="">
        Добавить
      </button>
    </teleport>

    <div class="rfid-list container">
      <RfidItem
        v-for="rfid in rfidList"
        :key="rfid.id"
        :rfid="rfid"
        @updateRfidList="getRfidList"
        @notification="notification($event)"
      />
    </div>

    <transition name="default">
      <QModalRfidCreate
        v-if="isShowModalAddRfid"
        @close="isShowModalAddRfid = false"
        @notification="notification($event)"
        @updateRfidList="getRfidList"
      />
    </transition>

    <transition name="left">
      <q-notification v-if="notificationText" :text="notificationText"/>
    </transition>

    <q-bottom-panel>
      <QRfidFilters
        @setRfid="filtersForm.rfid = $event"
        @setFree="filtersForm.is_free = $event"
        @setCompany="filtersForm.company_id = $event"
        @setType="filtersForm.type = $event"
      />
    </q-bottom-panel>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, watch } from 'vue'
import useApi from '@/composables/useApi'
import QModalRfidCreate from '@/components/modals/QModalRfidCreate.vue'
import QNotification from '@/components/ui/QNotification.vue'
import RfidItem from './RfidItem.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue'
import QRfidFilters from './QRfidFilters.vue'


const isShowModalAddRfid = ref(false)
const rfidList = ref([])

onMounted(()=> {
  getRfidList()
})

const filtersForm = reactive({
  company_id: '',
  rfid: '',
  is_free: 'true',
  type: ''
})

const getRfidList = async() => {
  const data = await useApi('post', 'admin/rfid/get', filtersForm)
  rfidList.value = data.rfid
}

const notificationText = ref('')
const notification = text => {
  notificationText.value = text
  setTimeout(()=> notificationText.value = '', 4000)
}

watch(
  ()=> filtersForm,
  ()=> getRfidList(),
  { deep: true }
)

</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}

.add{
  padding: 8px 16px;
  background-color: $lemon-lime;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  img{
    margin-right: 10px;
  }
}

.rfid-list{
  width: 1210px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 0;
}
.rfid-item{
  background: white;
  border-radius: 20px;
  padding: 14px 24px;
  width:calc(50% - 10px);
  display: flex;
  justify-content: space-between;
  .left{
    .type{
      font-size: 16px;
      opacity: .5;
      margin-bottom: 4px;
    }
    span{
      font-size: 18px;
    }
    .free{
      margin-left: 10px;
      color: #82EA89;
      &.active{
        color: #F69782;
      }
    }
    .company{
      marin-top: 4px;
      font-size: 18px;
    }
  }
  img{
    cursor: pointer;
    width: 40px;
  }
}
</style>