<template>
  <h5>Транспорт по геозонам</h5>

  <q-analytics-transporters-search
    @getCommonAnaliticData="$emit('getCommonAnaliticData')"
    @setTransportNumber="$emit('setTransportNumber', $event)"
    @setPolygonName="$emit('setPolygonName', $event)"
  />

  <div class="block-wrapper">
    <!-- <q-charts :series="!isCompareMode ? transportersChartTonnage : compareData"/> -->

    <q-charts
      :series="isCompareMode ? compareData : transportersChartTonnage"
      :compareData="compareData"
      :polygonChartSumm="transportersChartTonnage"
      :polygonChartTonnage="transportersChartTonnage"
      :month="month"
      :year="year"
      customTooltip
      type="transport"
    />

    <div class="table">
      <div class="thead">
        <div class="tr">
          <div class="td no-separator" style="width: 385px">
            <span>Все перевозчики</span>
            <button
              class="compare-btn"
              :class="{active: isCompareMode}"
              @click="changeCompareMode"
            >
              <span>{{isCompareMode ? 'Сбросить' : 'Сравнить'}}</span>
              <img src="@/assets/images/сompare.svg">
            </button>
          </div>
        </div>
      </div>
      <div class="tbody">
        <div
          class="item"
          :class="{'show-inner': showInnerId === item.id}"
          v-for="item in data.details"
          :key="item.id"
        >
          <div class="tr">
            <div class="td content" style="width: 385px">
              <transition name="slide">
                <q-checkbox
                  v-if="isCompareMode"
                  @clicked="chooseCompare(item)"
                  :checked="compareData.find(compareItem => compareItem.id === item.id) ? true : false"
                  :view="compareData[0] && compareData[0].id === item.id ? '1' : '2'"
                />
              </transition>
              <div class="title-row">
                <div class="title">{{ item.name }} <span v-if="item.description">({{item.description}})</span></div>
                <div class="subtitle ">Кол-во районов {{ item.distr_num}}</div>
              </div>
              <img
                src="@/assets/images/arrow-analytics.svg"
                @click="showInner(item.id)"
                class="table-arrow"
              />
              <img v-if="compareData[0] && compareData[0].id === item.id" class="compare-fixed" src="@/assets/images/compare-fixed.svg"/>
            </div>
            <div class="td" style="width: 170px">Кол-во машин: {{ item.cars.length }}</div>
            <div class="td" style="width: 340px">Тоннаж: {{ item.tonnage.toFixed(2) }} т</div>
            <div class="td no-separator" style="width: 230px">Кол-во взвешиваний: {{ item.weight_num }}</div>
          </div>

          <div class="inner">
            <div
              class="tr"
              v-for="car in item.cars"
              :key="car.id"
            >
              <div class="td content" style="width: 385px; padding-left: 35px;"> {{ car.distr_name }}</div>
              <div class="td" style="width: 170px">{{ prettyLicensePlate(car.state_number) }}</div>
              <div class="td" style="width: 170px">{{ car.tonnage.toFixed(2) }}</div>
              <div class="td" style="width: 170px">Ср.тоннаж: {{ car.tonnage_avg.toFixed(2) }}</div>
              <div class="td no-separator" style="width: 230px">{{ car.weight_num }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tfoot" v-if="!flagNumberTransporter">
        <div class="tr">
          <div class="td" style="width: 385px">
            <div>
              <div class="title ">Общий итог</div>
              <div class="subtitle">{{ data.details.length }} перевозчиков</div>
            </div>
          </div>
          <div class="td" style="width: 170px">Кол-во машин: {{ data.car_num }}</div>
          <div class="td" style="width: 340px">Тоннаж: {{ data.tonnage.toFixed(2) }} т</div>
          <div class="td no-separator" style="width:230px">Кол-во взвешиваний: {{ data.weight_num }} </div>
        </div>
      </div>
    </div>
  </div>

  <transition name="top">
    <div v-if="!data.tonnage" class="not-found">
      <img
        src="@/assets/images/not-found-analytic-transports.svg"
      >
    </div>
  </transition>

</template>

<script setup>
import { defineProps, defineEmits, ref, inject, provide, watch } from "vue";
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts";
import prettyLicensePlate from "@/helpers/prettyLicensePlate";
import QCharts from "@/components/ui/QCharts.vue";
import QAnalyticsTransportersSearch from './AnalyticsTransportersSearch'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  transportersChartTonnage: {
    type: Array
  },
    month: {
    type: String
  },
  year: {
    type: String
  },
})

const emit = defineEmits(['getCommonAnaliticData', 'setTransportNumber', 'setPolygonName'])

const flagNumberTransporter = inject('flagNumberTransporter')

const showInner = id => id === showInnerId.value ? showInnerId.value = '' : showInnerId.value = id
const showInnerId = ref('')

const isCompareMode = ref(false)
provide('isCompareMode', isCompareMode)

const compareData = ref([])

const changeCompareMode = () => {
  if(!isCompareMode.value){
    isCompareMode.value = true
  } else {
    isCompareMode.value = false
    compareData.value = []
  }
}

const chooseCompare = item => {
  const s = compareData.value.findIndex(compareItem => compareItem.id === item.id)
  if(s === 0 || s === 1){
    compareData.value.splice(s, 1)
    return
  }
  if(compareData.value.length === 2){
    return
  }

  compareData.value.push({
    isFixed: compareData.value.length ? true : false,
    id: item.id,
    name: item.name,
    data: item.period.tonnage.map(item => item === '' ? null : item),
    diff: item.period.diff_previous.map(item => item === '' ? null : item),
    weightNum: item.period. weight_num.map(item => item === '' ? null : item),
  })
}

watch(
  () => props.month,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
watch(
  () => props.year,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
</script>

<style scoped lang="scss">
h5{
  margin-top: 36px;
  margin-left: 20px;
  margin-bottom: 30px;
}
.not-found{
  padding: 70px 0;
  display: flex;
  justify-content: center;
  background: #fafafa;
  margin-top: -30px;
  border-radius: 0 0 22px 22px;
}
.title-row{
  flex: 1;
}
</style>
