<template>
  <q-modal @close="$emit('close')">
    <div class="content">
      <h5>Добавление RFID <br/> {{ data.label }}</h5>
      <div class="wrap">
        <div class="rfid-list">
          <div class="rfid-list__label">Доступные</div>
          <div class="rfid-list__list">
            <div
              class="rfid-item"
              v-for="rfid in rfidFree"
              :key="rfid.id"
            >
              <div class="left">
                <p>{{ rfid.type.label }}</p>
                <span>{{ rfid.id }}  {{ rfid.number }}</span>
              </div>
              <img
                @click="setRfidOwner(rfid)"
                src="@/assets/images/card_show.svg"
              >
            </div>
          </div>
        </div>

        <div class="rfid-list">
          <div class="rfid-list__label">Прикрепленные</div>
          <div class="rfid-list__list">
            <div
              class="rfid-item"
              v-for="rfid in rfidAttached"
              :key="rfid.id"
            >
              <div class="left">
                <p>{{ rfid.type.label }}</p>
                <span>{{ rfid.id }}   {{ rfid.number }}</span>
              </div>
              <img
                @click="removeRfidOwner(rfid)"
                src="@/assets/images/delete.svg"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, ref, reactive, computed, onMounted, watch, watchEffect } from 'vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import QNotification from '@/components/ui/QNotification.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, ipAddress, minLength, maxLength } from '@vuelidate/validators'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const rfidFree = ref([])
const rfidAttached = ref([])


onMounted(async()=> {
  getLists()
})

const setRfidOwner = async(rfid) => {
  const data = await useApi('post', 'admin/rfid/set_owner', {
    owner_id: props.data.id,
    id: rfid.id
  })
  if(data.status = 'successful'){
    getLists()
  }
}

const removeRfidOwner = async(rfid) => {
  const data = await useApi('post', 'admin/rfid/reset_owner', {
    id: rfid.id
  })
  if(data.status = 'successful'){
    getLists()
  }
}



const getLists = async() => {
  store.dispatch('setGlobalPreloader', true)

  const free = await useApi('get', 'admin/rfid/get_free')
  rfidFree.value = free.rfid

  const owner = await useApi('post', 'admin/rfid/get_by_owner', { owner_id: props.data.id})
  rfidAttached.value = owner.rfid

  store.dispatch('setGlobalPreloader', false)
}
</script>

<style scoped lang="scss">
.content{
  width: 635px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 22px;
  line-height: normal;
}

.rfid-list{
  width: calc(50% - 5px);
  &__label{
    font-size: 16px;
    opacity: .5;
    margin-bottom: 10px;
  }

  &__list{
    border-radius: 22px;
    background: #f8f8f8;
    overflow-y: auto;

    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    height: 380px;
  }
}

.rfid-item{
  padding: 14px 12px 14px 24px;
  border-radius: 20px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img{
    cursor: pointer;
  }
  .left{
    span{
      font-size: 18px;
    }
    p{
      font-size: 16px;
      margin-bottom: 3px;
      opacity: 0.5;
    }
  }
}

.wrap{
  display: flex;
  justify-content: space-between;
}
</style>