<template>
  <div class="q-counterparties-filters">
    <div class="q-counterparties-filters__item q-counterparties-filters__item--status">
      <q-select
        :items="statusSelectItems"
        @changeSelect="statusSelectItemActive = $event"
        :selectedItem="statusSelectItemActive"
        :maxHeight="170"
        :filter="true"
      />
    </div>
    <div class="q-counterparties-filters__item q-counterparties-filters__item--transporter">
      <q-input
        v-model="transporter"
        placeholder="Контрагент"
        type="text"
        filter
        :search="searchedTransporters"
        @searchItemChoose="chooseTransporter"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputTransporters"
        cross
      />
    </div>
    <div class="q-counterparties-filters__item q-counterparties-filters__item--status">
      <q-select
        :items="typeSelectItems"
        @changeSelect="typeSelectItemActive = $event"
        :selectedItem="typeSelectItemActive"
        :maxHeight="250"
        :filter="true"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, watchEffect, watch } from 'vue'
import useApi from '@/composables/useApi'

const emit = defineEmits([
  'setStatus',
  'setType',
  'setTransporter',
])

const statusSelectItems = ref([
  {
    label: 'Действующие',
    id: 'true'
  },
  {
    label: 'Недействующие',
    id: 'false'
  },
])
const statusSelectItemActive = ref({
  label: 'Действующие',
  id: 'true'
})
const typeSelectItems = ref([
  {
    label: 'Все контрагенты',
    id: ''
  },
  {
    label: 'Полигон',
    id: 'polygon'
  },
  {
    label: 'Сортировка',
    id: 'sorting'
  },
  {
    label: 'Перевозчик',
    id: 'transporter'
  },
])
const typeSelectItemActive = ref({
  label: 'Все контрагенты',
  id: ''
})


const transporter = ref('')
const searchedTransporters = ref([])

const inputTransporters = async(e) => {
  if(e){
    const transporterData = await useApi('get', `filters/find_transporter/${e}`)
    searchedTransporters.value = transporterData.transporters
  } else {
    searchedTransporters.value = []
  }
}
const chooseTransporter = item => {
  transporter.value = item.label
  emit('setTransporter', item.id)
  searchedTransporters.value = []
}

const closeSearchPanel = () => {
  searchedTransporters.value = []
}

watch(
  () => statusSelectItemActive.value,
  (val) => emit('setStatus', val.id)
)

watch(
  () => typeSelectItemActive.value,
  (val) => emit('setType', val.id)
)
</script>

<style scoped lang="scss">
.q-counterparties-filters{
  display: flex;
  justify-content: space-between;
  padding: 18px 9px;
  &__item{
    padding: 0 9px;
    &--status{
      width: 243px;
    }
    &--transporter{
      flex: 1;
    }
  }
}
</style>
