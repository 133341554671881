import { createApp } from 'vue'
import App from './App.vue'
import VueCustomDirectives from "vue3-directives";
import router from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification'

import QSelect from "@/components/ui/QSelect.vue"
import QCheckbox from "@/components/ui/QCheckbox.vue"
import QButton from "@/components/ui/QButton.vue"
import QInput from "@/components/ui/QInput.vue"
import QInputNumber from "@/components/ui/QInputNumber.vue"
import QToggle from '@/components/ui/QToggle.vue'


import QModal from "@/components/ui/QModal.vue"

createApp(App)
  .use(store)
  .use(VueCustomDirectives)
  .use(Notifications)
  .use(router)

  .component('QSelect', QSelect)
  .component('QCheckbox', QCheckbox)
  .component('QButton', QButton)
  .component('QInput', QInput)
  .component('QInputNumber', QInputNumber)
  .component('QToggle', QToggle)

  .component('QModal', QModal)

  .mount('#app')
