<template>
  <div class="page">
    <teleport to="#header__left-teleport-container">
      <button
        class="add-new"
        @click="isQModalTransport = true"
      >
        <img src="@/assets/images/add.svg" alt="">
        Добавить
      </button>
    </teleport>
    <teleport to="#header__right-teleport-container">
      <div class="teleport-container">
        <div class="quantity-cars">
          <span>Количество ТС:</span> &nbsp; {{ quantity }}
        </div>
      </div>
    </teleport>

    <q-scroll-up-button :quantityPaginationPage="quantityPaginationPage"/>

    <v-pagination
      v-if="quantityPaginationPage > 1"
      v-model="currentPaginationPage"
      :pages="quantityPaginationPage"
      :range-size="1"
      @update:modelValue="updateHandler"
    />

    <q-transport-preloader v-if="preloader"/>

    <q-empty-content
      v-if="notFoundMessage"
      :message="notFoundMessage"
    />

    <transition name="default">
      <div class="transport-list container" v-if="!preloader">
        <div
          style="width: calc(50% - 8px)"
          v-for="(car, idx) in cars"
          :key="idx"
        >
          <q-transport-item
            :data="car"
            @setDataEdit="setDataEdit"
            @deleteTransport="deleteTransport"
          />
        </div>
        <transition name="default">
          <QModalTransport
            v-if="isQModalTransport"
            @close="isQModalTransport = false"
            type="add"
            @setDataCreate="setDataCreate"
          />
        </transition>
      </div>
    </transition>

    <q-bottom-panel>
      <QTransportFilters
        @getFilteredContent="getTransport"
        @setCarNumber="setCarNumber"
        @setTransporter="setTransporter"
        @setBrand="setBrand"
        @setRfid="setRfid"
        @setSortType="setSortType"
        @downloadExcel="downloadExcel"
      />
    </q-bottom-panel>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, provide } from 'vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import QBottomPanel from '@/components/common/QBottomPanel.vue';
import useRouterGuard from '@/composables/useRouterGuard'
import QTransportItem from './QTransportItem.vue'
import QModalTransport from '@/components/modals/QModalTransport.vue'
import QTransportFilters from './QTransportFilters.vue'
import QTransportPreloader from './QTransportPreloader.vue';
import QEmptyContent from '@/components/common/QEmptyContent.vue'
import VPagination from '@hennge/vue3-pagination'
import QScrollUpButton from '@/components/common/QScrollUpButton.vue'
import { notify } from '@kyvg/vue3-notification'


onMounted(()=> {
  useRouterGuard()
  getTransport()
})

const cars = ref([])
const quantity = ref(0)
const notFoundMessage = ref('')
const preloader = ref(false)

const isDirtyFields = ref(false)

const quantityPaginationPage = ref(1)
const currentPaginationPage = ref(1)

const carNumber = ref('')
const carBrand = ref('')
const rfid = ref('')
const transporter = ref('')

const isQModalTransport = ref(false)

watch(
  () => quantityPaginationPage.value,
  (val) => currentPaginationPage.value = 1
)

const getTransport = async() => {
  store.dispatch('setGlobalPreloader', true)
  preloader.value = true
  cars.value = []

  const transportData = await useApi('post', 'car/get_cars', {
    brand: carBrand.value,
    car_number: carNumber.value,
    car_rfid: rfid.value,
    current_page: isDirtyFields.value ? 1 : currentPaginationPage.value,
    transporter_name: transporter.value,
    sorting_by: String(sortType.value),
  })
  notFoundMessage.value = ''
  if(transportData.message) {
    notFoundMessage.value = transportData.message.text
  }

  if(transportData.error || !transportData.cars.length){
    notFoundMessage.value = 'Транспорт не найден'
  }
  cars.value = transportData.cars
  quantityPaginationPage.value = transportData.pages
  quantity.value = transportData.num
  preloader.value = false

  isDirtyFields.value = false
  store.dispatch('setGlobalPreloader', false)
}

watch(
  () => store.state.user.role,
  () => getTransport()
)

const updateHandler = (e) => {
  currentPaginationPage.value = e
  getTransport()
}

const setTransporter = e => {
  isDirtyFields.value = true
  transporter.value = e
  getTransport()
}

const setBrand = e => {
  isDirtyFields.value = true
  carBrand.value = e
  getTransport()
}

const setRfid = e => {
  isDirtyFields.value = true
  rfid.value = e
  getTransport()
}

const setCarNumber = e => {
  isDirtyFields.value = true
  carNumber.value = e
  getTransport()
}

const setDataEdit = pl => {
  notify({ title: 'Транспорт успешно изменен!', type: 'success'})
  getTransport()
}

const setDataCreate = data => {
  notify({ title: 'Транспорт успешно добавлен!', type: 'success'})
  getTransport()
}
const sortType = ref('date_desc')

const setSortType = e => {
  sortType.value = e
  getTransport()
}

const deleteTransport = id => {
  notify({ title: 'ТС удалено!', type: 'success'})
  getTransport()
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13) getTransport()
});

const downloadExcel = async() => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'car/get_cars_excel', {
    brand: carBrand.value,
    car_number: carNumber.value,
    car_rfid: rfid.value,
    current_page: isDirtyFields.value ? 1 : currentPaginationPage.value,
    transporter_name: transporter.value,
    sorting_by: String(sortType.value),
  })

  if(req.docs){
    window.open(req.docs, '_self');
  }
  store.dispatch('setGlobalPreloader', false)
}
</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quantity-cars{
  padding: 8px 16px;
  background: $white;
  border-radius: 14px;
  span{
    opacity: .5;
  }
}
.teleport-container{
  display: flex;
  .waste-switcher{
    margin-left: 20px;
    width: 200px;
  }
}

.add-new{
  margin-left: 35px;
}
</style>