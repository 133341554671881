<template>
  <q-modal @close="$emit('close')">
    <div class="content">
      <h5>Привязанные  Gravity</h5>
      <button
        class="add"
        @click="editData.id ? isCreateMode = false : isCreateMode = true"
        :class="{disabled: editData.id || isCreateMode}"
      >
        <img src="@/assets/images/add.svg" alt="">
        Добавить Gravity
      </button>

      <div class="gravity-list" ref="target">
        <div v-if="!gravityList.length && !isCreateMode" class="empty-gravity">Нет ни одного Gravity</div>

        <div
          class="gravity-item"
          v-if="editData.id || isCreateMode"
        >
          <div class="gravity-item__row">
            <div class="form-item">
              <label>IP</label>
              <q-input
                placeholder="Введите IP"
                type="text"
                :white="true"
                v-model="editData.ip"
                :error="v$.ip.$errors.length ? 'Заполните поле' : ''"
              />
            </div>
            <div class="form-item">
              <label>Порт</label>
              <q-input
                placeholder="Введите порт"
                type="text"
                :white="true"
                v-model="editData.port"
                :error="v$.port.$errors.length ? 'Заполните поле' : ''"
                masked="port"
              />
            </div>
          </div>

          <div class="form-item">
            <label>Описание</label>
            <q-input
              placeholder="Координаты, место, контакты"
              type="text"
              :white="true"
              v-model="editData.description"
              :error="v$.description.$errors.length ? 'Заполните поле' : ''"
            />
          </div>

          <div class="btns">
            <div>
              <q-button
                label="Отменить"
                view="secondary"
                @click="cancelEdit"
              />
            </div>
            <div>
              <q-button
                label="Сохранить"
                view="secondary"
                @click="save"
              />
            </div>
          </div>
        </div>
        <div
          class="gravity-item"
          v-for="item in gravityList"
          :key="item.id"
        >
          <div class="gravity-item__header">
            <img
              src="@/assets/images/edit_icon.svg"
              alt="редактировать"
              @click="setGrafityDataForEdit(item)"
            >
            <QToggle
              :checked="item.status"
              @click="changeStatus(item)"
            />
          </div>

          <div class="gravity-item__row">
            <div class="form-item">
              <label>IP</label>
              <q-input
                placeholder="Введите IP"
                type="text"
                :white="true"
                v-model="item.ip"
                disabled
              />
            </div>
            <div class="form-item">
              <label>Порт</label>
              <q-input
                placeholder="Введите порт"
                type="text"
                :white="true"
                v-model="item.port"
                disabled
              />
            </div>
          </div>

          <div class="form-item mb0">
            <label>Описание</label>
            <q-input
              placeholder="Координаты, место, контакты"
              type="text"
              :white="true"
              v-model="item.description"
              disabled
            />
          </div>
        </div>
      </div>

      <transition name="default">
        <QModalConfirmation
          v-if="isShowModalConfirm"
          @close="isShowModalConfirm = false"
          :data="fantomData"
          title="Отключение Gravity"
          @confirm="changeStatusAction($event)"
        >
          Уверены, что хотите отключить Gravity? Отключая Gravity, вы подтверждаете, что у пользователя больше не будет доступа к Gravity
        </QModalConfirmation>
      </transition>
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, ref, reactive, computed, onMounted, watch, watchEffect, onBeforeUnmount } from 'vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import QModalConfirmation from '@/components/modals/QModalConfirmation.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, ipAddress, minLength, maxLength } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

const route = useRoute()
const router = useRouter()


const isShowModalConfirm = ref(false)
const fantomData = ref(null)

const editData = reactive({
  id: '',
  ip: '',
  port: '',
  description: '',
})

const target = ref(null)

const isCreateMode = ref(false)

const validShema = ref({
  ip: { required, ipAddress },
  port: { required, minLength: minLength(4), maxLength: maxLength(6),},
  description: { required }
})

const v$ = useVuelidate(validShema, editData)

const props = defineProps({
  id: {
    type: String,
  },
})

const save = async() => {
  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }
  const payload = {
    description: editData.description,
    ip: editData.ip,
    port: editData.port
  }
  editData.id ? payload.id = editData.id : payload.platform_id = props.id || route.query.id

  const data = await useApi('post', `admin/gravity/${editData.id ? 'update' : 'create'}`, payload)

  if(data.status === "successful"){
    cancelEdit()
    getGravity()
    notify({ title: editData.id.length ? 'Gravity успешно отредактировано' : 'Gravity успешно создано', type: 'success'})
  }
}

onMounted(()=> {
  setTimeout(() => {
    if(route.query.id){
      isCreateMode.value = true
    }
  }, 200);

  getGravity()
})

const gravityList = ref([])

const getGravity = async() => {
  const data = await useApi('post', 'admin/gravity/get_by_platform_id', { platform_id: props.id || route.query.id })
  gravityList.value = data.gravity
}

const setGrafityDataForEdit = data => {
  v$.value.$reset()

  editData.id = data.id
  editData.ip = data.ip
  editData.port = data.port
  editData.description = data.description

  target.value.scrollTop = 0
}

const cancelEdit = () => {
  editData.id = ''
  editData.ip = ''
  editData.port = ''
  editData.description = ''

  isCreateMode.value = false
  v$.value.$reset()
}

const changeStatus = data => {
  if (!data.status){
    changeStatusAction(data)
  } else {
    fantomData.value = data
    isShowModalConfirm.value = true
  }
}

const changeStatusAction = async(data) => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'admin/gravity/set_status', {
    id: data.id,
    status: !data.status,
  })

  gravityList.value[gravityList.value.findIndex(item => data.id === item.id)].status = !gravityList.value[gravityList.value.findIndex(item => item.id === data.id)].status
  store.dispatch('setGlobalPreloader', false)
  notify({ title: 'Статус Gravity изменен', type: 'success'})

  isShowModalConfirm.value = false
  fantomData.value = null
}

onBeforeUnmount(()=> router.push({query: null}))
</script>

<style scoped lang="scss">
.content{
  width: 708px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 22px;
}
.add{
  position: absolute;
  right: 36px;
  top: 27px;
  padding: 8px 16px;
  background-color: $lemon-lime;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  transition: ease .3s;
  img{
    margin-right: 10px;
  }
  &.disabled{
    opacity: .5;
  }
}

.gravity-list{
  margin-top: 35px;
  max-height: 440px;
  overflow: auto;
  padding: 18px;
  background: #f8f8f8;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  min-height: 450px;
  position: relative;
}
.empty-gravity{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #A0A0A0;
}
.gravity-item{
  padding: 16px;
  border-radius: 20px;
  background: white;


  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img{
      margin-right: 12px;
      cursor: pointer;
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .form-item{
      width: calc(50% - 8px);
    }
  }
}

.form-item{
  margin-bottom: 15px;
  &.mb0{
    margin-bottom: 0;
  }
}

.btns{
  display: flex;
  justify-content: flex-end;
  gap: 0 16px;
  margin-top: 24px;
  div{
    width: 124px;
  }
}

.confirm-text{
  opacity: .5;
  font-size: 16px;
}
</style>