import { createRouter, createWebHistory,  RouteRecordRaw } from 'vue-router'
import Login from '../views/login/Login.vue'
import About from '../views/about/About.vue'
import Support from '../views/Support.vue'
import Acts from '../views/acts/Acts.vue'
import QActsPublic from '../views/acts/QActsPublic.vue'
import Orders from '../views/orders/Orders.vue'
import Analytics from '../views/analytics/Analytics.vue'
import Counterparties from '../views/counterparties/Counterparties.vue'
import Transport from '../views/transport/Transport.vue'
import Districts from '../views/districts/Districts.vue'
import Settings from '../views/settings/Settings.vue'
import Rfid from '../views/rfid/Rfid.vue'
import ConfirmEmail from '../views/ConfirmEmail.vue'
import Kpp from '../views/kpp/Kpp.vue'
import Incident from '../views/kpp/incidents/Incidents.vue'



import NotFound from '../views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      routeTitle: 'Авторизация',
      pageTheme: 'dark',
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      routeTitle: 'Поддержка'
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      routeTitle: 'Аналитика',
      isAuth: true,
    },
  },
  {
    path: '/acts',
    name: 'Acts',
    component: Acts,
    meta: {
      routeTitle: 'Акты',
      isAuth: true,
    },
  },
  {
    path: '/acts/:id',
    name: 'ActsPublic',
    component: QActsPublic,
    meta: {
      routeTitle: 'Aкты',
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      routeTitle: 'Заявки',
      isAuth: true,
    },
  },
  {
    path: '/counterparties',
    name: 'Counterparties',
    component: Counterparties,
    meta: {
      routeTitle: 'Контрагенты',
      isAuth: true,
    },
  },
  {
    path: '/transport',
    name: 'Transport',
    component: Transport,
    meta: {
      routeTitle: 'Транспорт',
      isAuth: true,
    },
  },
  {
    path: '/districts',
    name: 'Districts',
    component: Districts,
    meta: {
      routeTitle: 'Районы',
      isAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      routeTitle: 'Настройки',
      isAuth: true,
    },
  },
  {
    path: '/rfid',
    name: 'Rfid',
    component: Rfid,
    meta: {
      routeTitle: 'RFID',
      isAuth: true,
    },
  },
  {
    path: '/kpp',
    name: 'Kpp',
    component: Kpp,
    meta: {
      routeTitle: 'КПП',
      isAuth: true,
    },
  },
  {
    path: '/kpp/incidents',
    name: 'Imcidents',
    component: Incident,
    meta: {
      routeTitle: 'Инциденты',
      isAuth: true,
    },
  },
  {
    path: '/settings/check_update_email/:key',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      routeTitle: 'Confirm email',
      isAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      routeTitle: 'Не найдено',
      isAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
