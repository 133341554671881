<template>
  <div class="global-preloader">
    <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
      <circle fill="#424242" stroke="none" cx="6" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="0.7s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0.1"/>
      </circle>
      <circle fill="#424242" stroke="none" cx="30" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="0.7s"
          type="translate"
          values="0 10 ; 0 -10; 0 10"
          repeatCount="indefinite"
          begin="0.2"/>
      </circle>
      <circle fill="#424242" stroke="none" cx="54" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="0.7s"
          type="translate"
          values="0 5 ; 0 -5; 0 5"
          repeatCount="indefinite"
          begin="0.3"/>
      </circle>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.global-preloader{
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  svg{
    width: 100px;
    height: 100px;
    margin: 20px;
    display:inline-block;
    margin-left: 60px;
  }
}
</style>