<template>
  <div
    class="q-modal"
    @mousedown="$emit('close')"
  >
    <div @mousedown.stop class="q-modal__window" >
      <div class="q-modal__close-btn" @click="$emit('close')">
        <q-round-button />
      </div>
      <div class="q-modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted, onUnmounted } from 'vue'
import QRoundButton from '@/components/common/QRoundButton.vue'

const emit = defineEmits(['close'])

const position = ref(0)

onMounted(()=> {
  document.addEventListener('keyup', function (e) {
    if(e.keyCode === 27){
      emit('close')
    }
  })

  position.value = window.scrollY
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = 'fixed'
})

onUnmounted(()=> {
  document.body.style.position = ''
  document.body.style.top = ''
  window.scrollTo({top: position.value})
})
</script>

<style scoped lang="scss">
.q-modal{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, .7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__window{
    background: $white;
    border-radius: 24px;
    position: relative;
  }

  &__content{
    width: 100%;
    height: 100%;
    // overflow: hidden;
    border-radius: 24px;
  }

  &__close-btn{
    position: absolute;
    right: -60px;
    top: 0;
  }
}
</style>
