<template>
  <div class="date-picker" ref="target">
    <div
      class="date-picker__btn filter-round-btn"
      :class="{'filter-round-btn--active': isShowDP}"
      @click="switchDP"
    >
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.875 7C0.875 4.23858 3.11358 2 5.875 2H13.875C16.6364 2 18.875 4.23858 18.875 7V14C18.875 16.7614 16.6364 19 13.875 19H5.875C3.11358 19 0.875 16.7614 0.875 14V7ZM5.875 4C4.21815 4 2.875 5.34315 2.875 7H16.875C16.875 5.34315 15.5319 4 13.875 4H5.875ZM16.875 9H2.875V14C2.875 15.6569 4.21815 17 5.875 17H13.875C15.5319 17 16.875 15.6569 16.875 14V9Z" fill="#C7C7C7"/><path d="M5.42969 0.888889C5.42969 0.397969 5.82766 0 6.31858 0C6.8095 0 7.20747 0.397969 7.20747 0.888889V2.90058C7.20747 3.3915 6.8095 3.78947 6.31858 3.78947C5.82766 3.78947 5.42969 3.3915 5.42969 2.90058V0.888889Z" fill="#C7C7C7"/><path d="M12.5391 0.888889C12.5391 0.397969 12.937 0 13.428 0C13.9189 0 14.3168 0.397969 14.3168 0.888889V2.90058C14.3168 3.3915 13.9189 3.78947 13.428 3.78947C12.937 3.78947 12.5391 3.3915 12.5391 2.90058V0.888889Z" fill="#C7C7C7"/></svg>
    </div>
    <div class="date-picker__menu" :class="{show: isShowDP}">
      <transition name="default">
        <div
          v-if="date[0] === '' && date[1] === ''"
          class="date-picker__error"
        >
          Выберите дату
        </div>
      </transition>
      <div class="date-picker__choosen-date">
        <svg @click="clear" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6569 1.75744C12.0474 1.36692 12.0474 0.733751 11.6569 0.343227C11.2663 -0.047298 10.6332 -0.0472978 10.2426 0.343227L11.6569 1.75744ZM0.343146 10.2427C-0.0473781 10.6332 -0.0473788 11.2664 0.343146 11.6569C0.73367 12.0475 1.36684 12.0475 1.75736 11.6569L0.343146 10.2427ZM1.75729 0.343294C1.36677 -0.04723 0.733602 -0.0472299 0.343078 0.343294C-0.0474462 0.733819 -0.0474466 1.36698 0.343078 1.75751L1.75729 0.343294ZM10.2426 11.657C10.6331 12.0475 11.2663 12.0475 11.6568 11.657C12.0473 11.2665 12.0473 10.6333 11.6568 10.2428L10.2426 11.657ZM10.2426 0.343227L0.343146 10.2427L1.75736 11.6569L11.6569 1.75744L10.2426 0.343227ZM0.343078 1.75751L10.2426 11.657L11.6568 10.2428L1.75729 0.343294L0.343078 1.75751Z" fill="#C6C6C6"/></svg>
        <div class="name">Дата поиска</div>
        <div
          class="label"
          :class="{error: date[0] === '' && date[1] === ''}"
        >
          {{ formattedDate }}
        </div>
      </div>

      <div class="date-picker__choose-period">
        <div class="name">Выберите период</div>
        <div class="buttons">
          <div class="date-picker__btn-period" @click="setPeriod('yesterday')">Вчера</div>
          <div class="date-picker__btn-period" @click="setPeriod('week')">Эта неделя</div>
          <div class="date-picker__btn-period" @click="setPeriod('year')">Год</div>
          <div class="date-picker__btn-period" @click="setPeriod('month')">Этот месяц</div>
          <div class="date-picker__btn-period" @click="setPeriod('lastMonth')">Прошлый месяц</div>
        </div>
      </div>

      <Datepicker
        v-if="isShowDP"
        v-model="date"
        range
        locale="ru"
        ref="datePick"
        selectText="Готово"
        :monthChangeOnScroll="false"
        @internalModelChange="internalModelChange"
        @update:modelValue="updateDate"
        @blur="blur"
      />
    </div>
  </div>
</template>

<script setup>
// https://vue3datepicker.com/api/events/#update-modelvalue

import { defineEmits, ref, onMounted } from 'vue';
import Datepicker from 'vue3-date-time-picker';
import moment from 'moment';
import store from '@/store'
import { onClickOutside } from '@vueuse/core'
import 'vue3-date-time-picker/dist/main.css'

const emit = defineEmits(['setFilteredDate'])

const isShowDP = ref(false)
const date = ref(['', '']);
const datePick = ref(null)
const formattedDate = ref('')

onMounted(()=> date.value = [new Date(), ''])

const switchDP = () => {
  if(isShowDP.value) {
    datePick.value.closeMenu()
    document.querySelector('body').style.overflowY = "scroll"
    document.querySelector('body').style.paddingRight = "0"
    store.dispatch('changeIsBlockWindow', false)
    isShowDP.value = false
  } else {
    store.dispatch('changeIsBlockWindow', true)
    isShowDP.value = true
    document.querySelector('body').style.overflowY = "hidden"
    document.querySelector('body').style.paddingRight = "5px"
    setTimeout(()=> datePick.value.openMenu(), 0)
  }
}

const setPeriod = (period) => {
  const d = new Date();

  if(period === 'yesterday') {
    const startDate = new Date(new Date().setDate(d.getDate() - 1))
    date.value = [startDate, startDate];
  }
  if(period === 'week') {
    let startDate = '';
    if(d.getDay() === 0){
      startDate = new Date(new Date().setDate(d.getDate() - d.getDay() - 6))
    } else {
      startDate = new Date(new Date().setDate(d.getDate() - d.getDay() + 1))
    }
    const stopDate = new Date()
    date.value = [startDate, stopDate];
  }
  if(period === 'year') {
    const startDate = new Date(d.getFullYear(), 0, 1, 4)
    const stopDate = new Date()
    date.value = [startDate, stopDate];
  }
  if(period === 'month') {
    const startDate = d.setDate(1);
    const stopDate = new Date()
    date.value = [startDate, stopDate];
  }
  if(period === 'lastMonth') {
    function daysInMonth (month, year) {
      return new Date(year, month, 0).getDate();
    }
    const startDate = new Date(d.getFullYear(), d.getMonth() - 1, 1, 4)
    const stopDate = new Date(d.getFullYear(), d.getMonth() - 1, daysInMonth(d.getMonth(), d.getFullYear()), 4)
    date.value = [startDate, stopDate];
  }
}

const updateDate = () => {
  emit('setFilteredDate', date.value)
  switchDP()
}

const clear = () => {
  formattedDate.value = ''
  date.value = ['', '']
  emit('setFilteredDate', date.value)
}

const blur = () => {
  datePick.value.openMenu()
}

const internalModelChange = (e) => {
  if(e) formattedDate.value = `${moment(e[0]).format('DD.MM.YYYY')} ${e[1] ?'- '+ moment(e[1]).format('DD.MM.YYYY') : 'ДД.ММ.ГГГГ'}`
}

const target = ref(null)

onClickOutside(target, (event) => {
  if (isShowDP.value){
    isShowDP.value = false
    datePick.value.closeMenu()
    document.querySelector('body').style.overflowY = "scroll"
    store.dispatch('changeIsBlockWindow', false)
  }
})
</script>

<style scoped lang="scss">
.date-picker{
  position: relative;
  &__btn{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $davy;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__menu{
    position: absolute;
    border-radius: 18px;
    right: -17px;
    bottom: 70px;
    width: 615px;
    height: 320px;
    background: $ford-medium-dark;
    opacity: 0;
    visibility: hidden;

    &.show{
      opacity: 1;
      visibility: visible;
    }
  }

  &__error{
    color: $diamond-vogel;
    position: absolute;
    right: 212px;
    top: 87px;
    font-size: 14px;
  }

  &__choose-period{
    position: absolute;
    left: 303px;
    top: 107px;
    width: 296px;

    .name{
      color: white;
      opacity: .5;
    }

    .buttons{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-left: -4px;
    }
  }

  &__btn-period{
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px 16px;
    background: $davy;
    color: white;
    border-radius: 14px;
    margin: 4px;
    transition: ease .2s;
    font-size: 16px;
    &:hover{
      opacity: .7;
    }
  }

  &__choosen-date{
    width: 296px;
    height: 100px;
    position: absolute;
    right: 15px;
    top: 13px;

    svg{
      position: absolute;
      right: 11px;
      top: 46px;
      cursor: pointer;
    }

    .name{
      color: white;
      opacity: .5;
    }

    .label{
      width: 100%;
      height: 40px;
      border: 1px solid transparent;
      background: $davy;
      margin-top: 10px;
      border-radius: 14px;
      padding: 9px 18px;
      color: white;
      transition: ease .3s;

      &.error{
        border: 1px solid $diamond-vogel;
        background: rgba($diamond-vogel, .1);
      }
    }
  }
}
</style>