<template>
  <div
    class="act"
  >
    <div class="act__image">
      <div
        class="default-image"
        :style="{ backgroundImage: data.preview === '' ? 'url(' + require('@/assets/images/noImg.svg') + ')' : `url(${assetsEnv}/media/incidents/preview/${data.preview})`}"
      />

      <div class="act__panel">
        <button class="act__open-gallery" @click="isShowModalVideo = true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 18C22 20.2091 20.2091 22 18 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18C19.1046 20 20 19.1046 20 18V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V18ZM8 21C8 21.5523 7.55229 22 7 22H4C2.89543 22 2 21.1046 2 20V17C2 16.4477 2.44772 16 3 16C3.55228 16 4 16.4477 4 17V18.5858L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.41422 20H7C7.55229 20 8 20.4477 8 21ZM3 10C2.44772 10 2 9.55229 2 9V6C2 3.79086 3.79086 2 6 2H9C9.55229 2 10 2.44772 10 3C10 3.55228 9.55229 4 9 4H6C4.89543 4 4 4.89543 4 6V9C4 9.55229 3.55228 10 3 10ZM21 8C20.4477 8 20 7.55229 20 7V5.41422L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.5858 4H17C16.4477 4 16 3.55228 16 3C16 2.44772 16.4477 2 17 2H20C21.1046 2 22 2.89543 22 4V7C22 7.55229 21.5523 8 21 8Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="act__text">
      <span>Ручное открытие № {{ data.number }}</span>
      <p>{{ date }}</p>
    </div>
  </div>

  <transition name="default">
    <q-modal-video
      v-if="isShowModalVideo"
      @close="isShowModalVideo = false"
      :data="data"
    />
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from "vue"
import store from '@/store'
import moment from 'moment'
import QModalVideo from '@/components/modals/QModalVideos.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const isShowModalVideo = ref(false)

const assetsEnv = computed(()=> store.state.isDemoMode ? process.env.VUE_APP_BASE_API_DEMO_ASSETS : process.env.VUE_APP_BASE_API_ASSETS)

const isShowModalVisit = ref(false)

const date = computed(() => {
  return `${moment(props.data.time_start).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(props.data.time_stop).utc().format('HH:mm')}`
})

</script>

<style scoped lang="scss">
.act {
  padding: 12px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  &__panel{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, #292929);
    height: 30px;
  }
  &__image {
    margin-right: 32px;
    width: 102px;
    height: 64px;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: ease-out .4s;
    flex: none;

    .default-image{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
  &__text{
    span{
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 6px;
    }
    p{
      font-size: 14px;
      color: #A0A0A0;
    }
  }
}

.act__open-gallery{
  position: absolute;
  right: 7px;
  bottom: 2px;
}
</style>
