<template>
  <div
    class="act"
    :class="{active: isOpenAct}"
  >
    <div class="act__top">
      <div class="act__image">
        <div
          class="default-image"
          :style="{ backgroundImage: data.preview === '' ? 'url(' + require('@/assets/images/noImg.svg') + ')' : `url(${assetsEnv}/media/${kpp ? 'passes/preview' : 'acts/preview'}/${data.preview})`}"
        />
        <div class="act__panel">
          <div class="act__number">
            <q-license-plate :number="data.state_number"/>
          </div>
          <button class="act__open-gallery" @click="isShowModalVisit = true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 18C22 20.2091 20.2091 22 18 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18C19.1046 20 20 19.1046 20 18V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V18ZM8 21C8 21.5523 7.55229 22 7 22H4C2.89543 22 2 21.1046 2 20V17C2 16.4477 2.44772 16 3 16C3.55228 16 4 16.4477 4 17V18.5858L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.41422 20H7C7.55229 20 8 20.4477 8 21ZM3 10C2.44772 10 2 9.55229 2 9V6C2 3.79086 3.79086 2 6 2H9C9.55229 2 10 2.44772 10 3C10 3.55228 9.55229 4 9 4H6C4.89543 4 4 4.89543 4 6V9C4 9.55229 3.55228 10 3 10ZM21 8C20.4477 8 20 7.55229 20 7V5.41422L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.5858 4H17C16.4477 4 16 3.55228 16 3C16 2.44772 16.4477 2 17 2H20C21.1046 2 22 2.89543 22 4V7C22 7.55229 21.5523 8 21 8Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="act__content">
        <div class="act__header">
          <div class="act__header-left">

            <div class="act__id">{{kpp ? "Пропуск" : "Акт"}} № {{ data.number }}

            <svg
              v-if="$store.state.user.role == 2"
              @click="kpp ? isShowModalEditPass = true : isShowModalEditAct = true"

              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.229 13.8625L19.8492 6.24229C20.2397 5.85177 20.2397 5.2186 19.8492 4.82808L19.7257 4.70458C19.3352 4.31406 18.702 4.31406 18.3115 4.70458L10.6912 12.3248L12.229 13.8625ZM9.77702 16.2768C8.94858 16.2768 8.27699 15.6052 8.277 14.7768L8.27703 11.9106L16.8973 3.29037C18.0688 2.1188 19.9683 2.11879 21.1399 3.29037L21.2634 3.41387C22.435 4.58544 22.435 6.48493 21.2634 7.6565L12.6432 16.2767L9.77702 16.2768Z" fill="#C6C6C6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V13C20 12.4477 20.4477 12 21 12C21.5523 12 22 12.4477 22 13V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H13C13.5523 2 14 2.44772 14 3C14 3.55228 13.5523 4 13 4H7Z" fill="#C6C6C6"/>
            </svg>

            <svg v-if="!kpp" @click="copyPublicAct(data.id)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3268 5.6729C17.0164 4.36253 14.8918 4.36253 13.5815 5.6729L11.9997 7.25467C11.5629 7.69146 10.8547 7.69146 10.4179 7.25467C9.98112 6.81788 9.98112 6.1097 10.4179 5.6729L11.9997 4.09114C14.1836 1.90717 17.7246 1.90717 19.9085 4.09114C22.0925 6.2751 22.0925 9.81601 19.9085 12L18.3268 13.5817C17.89 14.0185 17.1818 14.0185 16.745 13.5817C16.3082 13.1449 16.3082 12.4368 16.745 12L18.3268 10.4182C19.6371 9.10783 19.6371 6.98328 18.3268 5.6729ZM5.67261 18.327C4.36223 17.0167 4.36223 14.8921 5.67261 13.5817L7.25438 12C7.69117 11.5632 7.69117 10.855 7.25438 10.4182C6.81759 9.98141 6.10941 9.98141 5.67261 10.4182L4.09085 12C1.90688 14.1839 1.90688 17.7248 4.09085 19.9088C6.27481 22.0928 9.81572 22.0928 11.9997 19.9088L13.5815 18.327C14.0182 17.8903 14.0182 17.1821 13.5815 16.7453C13.1447 16.3085 12.4365 16.3085 11.9997 16.7453L10.4179 18.327C9.10754 19.6374 6.98299 19.6374 5.67261 18.327ZM15.9541 9.62732C16.3909 9.19053 16.3909 8.48235 15.9541 8.04556C15.5173 7.60876 14.8091 7.60876 14.3723 8.04556L8.04526 14.3726C7.60847 14.8094 7.60847 15.5176 8.04527 15.9544C8.48206 16.3912 9.19024 16.3912 9.62703 15.9544L15.9541 9.62732Z" fill="#C6C6C6"/>
            </svg>

            <div v-if="data.dispute" class="disput-label">Оспорен</div>

            </div>
            <div class="act__period">{{ date }}</div>
          </div>
          <div class="act__header-right">
            <div v-if="!kpp" class="act__info">
              <div class="act-info">
                <div class="act-info__item"><span>Брутто</span> {{ (data.brutto / 1000).toFixed(2) }}</div>
                <div class="act-info__separator"></div>
                <div class="act-info__item"><span>Тара</span> {{ (data.tara / 1000).toFixed(2) }}</div>
                <div class="act-info__separator"></div>
                <div class="act-info__item"><span>Нетто</span> {{ (data.netto / 1000).toFixed(2) }}</div>
              </div>
            </div>

            <button class="act__btn-show-inner" @click="isOpenAct = !isOpenAct">
              <img
                src="@/assets/images/arrow-grey.svg"
                alt="Открыть/закрыть"
              />
            </button>

          </div>
        </div>
        <div class="act__body">
          <div class="act-body-descr">
            <div class="act-body-descr__title">Перевозчик</div>
            <div class="act-body-descr__text">{{ data.transporter.name }} </div>
          </div>
          <div v-if="!kpp" class="act-body-descr">
            <div class="act-body-descr__title">Площадка</div>
            <div class="act-body-descr__text">{{ data.platform }}</div>
          </div>
          <div class="act-body-descr">
            <div class="act-body-descr__title">Клиент</div>
            <div class="act-body-descr__text">{{ data.client.name }}</div>
          </div>
        </div>
        <div class="act__warnings">
          <div class="warning-item" :class="{activeWarn: data.alerts.length}">
            <img :src="require(`@/assets/images/warning${data.alerts.length ? '-active' : ''}.svg`)" alt="" />
            <span>{{data.alerts.length}}</span>
          </div>
          <div class="warning-item" :class="{activeMess: data.comments.length}">
            <img :src="require(`@/assets/images/messages${data.comments.length ? '-active' : ''}.svg`)" alt="" />
            <span>{{data.comments.length}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <transition name="slide-fade"> -->
    <div class="act__bottom">
      <div class="bottom-left">
        <div v-if="$store.state.user.role == 1 || $store.state.user.role == 6">
          <button v-if="data.dispute" class="btn-dispute" @click="changeStatus">
            <span>Акт оспорен</span>
            <div class="divider" />
            <img src="@/assets/images/back-around-arrow.svg" alt="">
          </button>
          <q-button
            v-else
            label="Оспорить акт"
            view="primary"
            @clicked="changeStatus"
          />
        </div>

        <div v-if="!kpp" class="bottom-left__info">
          <div class="bottom-left__info-title">Доп. данные</div>
          <div class="bottom-left__info-row">
            <span>Категория отходов</span>
            <span>{{data.waste_category.label || '-'}}</span>
          </div>
          <div class="bottom-left__info-row">
            <span>Вид груза</span>
            <span>{{data.waste_type.label || '-'}}</span>
          </div>
          <div class="bottom-left__info-row">
            <span>Объем кузова ТС</span>
            <span> {{ data.capacity }} </span>
          </div>
          <div class="bottom-left__info-row">
            <span>Пункт назначения</span>
            <span> {{ data.platform_type }} </span>
          </div>
          <div class="bottom-left__info-row">
            <span>Маршрут</span>
            <span>{{data.route}}</span>
          </div>
          <div class="bottom-left__info-row">
            <span>Собрано контейнеров</span>
            <span>{{data.containers}}</span>
          </div>
          <div
            class="bottom-left__info-row"
            v-if="($store.state.user.role !== 2 || $store.state.user.role !== 6) && waste.id === 'po'"
          >
            <span>Заявка</span>
            <span>{{ data.bid_id }}</span>
          </div>
        </div>
      </div>
      <div class="bottom-right">
        <div class="bottom-right__separator"></div>
        <div class="bottom-right__activity">
          <div class="activity-item">
            <span class="activity-item__header">
              <img src="@/assets/images/warning.svg" alt="" />
              Предупреждения
            </span>
            <div class="activity-item__body">
              <span class="item empty" v-if="!data.alerts.length">Предупреждений нет</span>
              <span
                class="item"
                v-for="(alert, idx) in data.alerts"
                :key="idx"
              >
                <div class="warn-date">{{alert.date ? alert.date : 'Время неизвестно'}}</div>
                <div class="text">{{alert.text}}</div>
              </span>
            </div>
          </div>
          <div class="activity-item">
            <span class="activity-item__header">
              <img src="@/assets/images/messages.svg" alt="" />
              Комментарии
            </span>
            <div class="activity-item__body">
              <span class="item empty" v-if="!data.comments.length">Комментариев нет</span>
              <span
                class="item"
                v-for="(comment, idx) in data.comments"
                :key="idx"
              >
                {{comment}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="default">
      <q-modal-acts-visit
        v-if="isShowModalVisit"
        @close="isShowModalVisit = false"
        :data="data"
        :kpp="kpp"
      />
    </transition>
    <transition name="default">
      <q-modal-edit-act
        v-if="isShowModalEditAct "
        @close="isShowModalEditAct  = false"
        :data="data"
        @showNotification="$emit('showNotification', $event)"
        @updateActsList="$emit('updateActsList')"
        :waste="waste"
      />
    </transition>

    <transition name="default">
      <q-modal-edit-pass
        v-if="isShowModalEditPass"
        @close="isShowModalEditPass  = false"
        :data="data"
        @showNotification="$emit('showNotification', $event)"
        @updateKppList="$emit('updateKppList')"
      />
    </transition>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from "vue"
import store from '@/store'
import moment from 'moment'
import useApi from '@/composables/useApi'
import { useRoute } from 'vue-router'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import QModalActsVisit from '@/components/modals/QModalActsVisit.vue'
import QModalEditAct from '@/components/modals/QModalEditAct.vue'
import QModalEditPass from '@/components/modals/QModalEditPass.vue'


const assetsEnv = computed(()=> store.state.isDemoMode ? process.env.VUE_APP_BASE_API_DEMO_ASSETS : process.env.VUE_APP_BASE_API_ASSETS)

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  waste: {
    type: Object,
    required: true,
  },
  kpp: {
    type: Boolean,
    default: false,
  },
})


const route = useRoute()

const emit = defineEmits(['updateActStatus', 'updateActsList', 'showNotification'])

const isOpenAct = ref(false)
const isShowModalVisit = ref(false)
const isShowModalEditAct = ref(false)
const isShowModalEditPass = ref(false)

const date = computed(() => {
  return `${moment(props.data.date_in).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(props.data.date_out).utc().format('HH:mm')}`
})

const changeStatus = async() => {

  if(store.state.isDemoMode){
    store.dispatch('setDemoModal', true)
    return
  }

  const changeStatusData = await useApi('get', `acts/update_dispute/${props.data.id}`)
  if(changeStatusData.status === 'success'){
    emit('updateActStatus', props.data.id)
  }
}

const copyPublicAct = async(id) => {
  const setPublicAct = await useApi('post', `acts/share/${id}`)

  navigator.clipboard.writeText(`${document.location.href}/${id}`)

  emit('showNotification', 'Ссылка скопирована. Действие ссылки 3-е суток')
}
</script>

<style scoped lang="scss">
.act {
  padding: 12px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 16px;
  &__top{
    display: flex;
  }
  &__header-left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
  &__image {
    margin-right: 32px;
    width: 198px;
    height: 124px;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: ease-out .4s;
    flex: none;

    .default-image{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
  &__panel{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, #292929);
    height: 50px;
  }
  &__number {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
  &__open-gallery {
    position: absolute;
    right: 12px;
    bottom: 6px;
    svg{
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2;
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    position: relative;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    &-right {
      display: flex;
    }
  }
  &__id {
    font-size: 22px;
    line-height: 26px;
    margin-top: 8px;
    margin-bottom: 4px;
    display: flex;

    svg{
      margin-left: 10px;
      cursor: pointer;
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2
        }
      }
    }
  }
  &__period {
    color: $dulux-australia;
    width: 100%;
  }
  &__btn-show-inner {
    width: 40px;
    height: 30px;
    border: 1px solid $california-paints;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-right: 4px;
    img{
      transition: ease-out .4s
    }
  }
  &__body {
    display: flex;
    gap: 0 15px;
  }
  .act-info {
    background: $caparol;
    width: 400px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    margin-right: 17px;
    margin-top: 4px;
    &__item {
      span {
        color: $dulux-australia;
        margin-right: 8.5px;
      }
    }
    &__separator {
      width: 1px;
      height: 18px;
      background: $silver-bullet;
      border-radius: 2px;
      margin: 0 24px;
    }
  }
  .act-body-descr {
    width: 250px;

    &__title {
      color: rgba($ford-medium-dark, 0.5);
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      font-size: 16px;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__warnings {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    transition: ease .1s;
  }
  .warning-item {
    display: flex;
    align-items: center;
    color: $dulux-australia;
    padding: 4px 13px;
    background: $caparol;
    border-radius: 12px;
    line-height: 13px;
    margin-left: 10px;
    span {
      display: block;
      margin-left: 5px;
      color: $dulux-australia;
    }
    &.activeWarn{
      background: $orange;
      span{
        color: $ford-medium-dark;
      }
    }
    &.activeMess{
      background: $blue-light;
      span{
        color: $ford-medium-dark;
      }
    }
  }

  &__bottom{
    display: flex;
    overflow: hidden;
    height: 0px;
    transition: all .4s;

    .bottom-left{
      width: 250px;
      padding-top: 20px;
      transition: ease-out .4s;
      transform: translateY(-30px);
      opacity: 0;
      transition-delay: .1s;
      flex: none;

      &__info{
        padding: 0 14px;
        margin-top: 19px;
      }
      &__info-title{
        font-size: 18px;
        margin-bottom: 7px;
      }
      &__info-row{
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        span:first-child{
          opacity: .5;
        }
      }
    }

    .bottom-right{
      margin-left: 32px;
      flex-grow: 1;
      display: flex;
      position: relative;
      padding-top: 20px;
      transition: ease-out .4s;
      transform: translateY(-30px);
      transition-delay: .1s;
      opacity: 0;
      &__separator{
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: $warmweiss;
        position: absolute;
        left: 0;
        top: -22px;
      }

      &__container{
        width: 255px;
        font-size: 14px;
        line-height: 20px;
        padding: 7px 16px;
        background: $caparol;
        border-radius: 14px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        span{
          opacity: .5;
        }
        &.lime{
          background-color: $lemon-lime;
          span{
            opacity: 1;
          }
        }
      }
      &__activity{
        display: flex;
        justify-content: space-between;
        padding-right: 5px;
        flex-grow: 1;

        .activity-item{
          width: 48%;
          &__header{
            font-size: 18px;
            margin-bottom: 11px;
            display: flex;
            img{
              margin-right: 4px;
            }
          }
          &__body{
            font-size: 14px;
            padding: 7px 16px;
            background: $caparol;
            border-radius: 14px;
            color: rgba($ford-medium-dark, .5);
            .item{
              display: block;
              margin-bottom: 7px;
              &:last-child{
                margin-bottom: 0;
              }
              &.empty{
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }

  &.active{
    .act__image{
      width: 250px;
      height: 160px;
    }
    .act__btn-show-inner{
      img{
        transform: rotate(180deg);
      }
    }
    .act-body-descr__text{
      overflow: auto;
      white-space: normal;
    }
    .act__warnings{
      opacity: 0;
    }
    .act__bottom{
      height: -webkit-fill-available;
    }
    .bottom-left,
    .bottom-right{
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.disput-label{
  font-size: 12px;
  line-height: 22px;
  background:  $orange;
  margin-left: 10px;
  padding: 2px 10px;
  width: fit-content;
  border-radius: 8px;
}

.btn-dispute{
  border-radius: 14px;
  padding: 10px 20px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #E1FF4C;
  .divider{
    width: 1px;
    height: 18px;
    background: rgba(66, 66, 66, 0.3);
    border-radius: 2px;
    margin: 0 15px;
  }
  span{
    margin-right: 25px;
  }
}

.warn-date{
  opacity: 0.5;
  line-height: 22px;
}
</style>
