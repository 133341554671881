<template>
  <div
    class="q-counterparty-item"
    v-if="large"
  >
    <div class="transporter">
      {{ data.platform_type_text || role }}
      <div class="transporter__value">
        {{ data.company_name }} <template v-if="data.description">({{data.description}})</template>
      </div>

      <div
        v-if="data.role !== 3 && $store.state.user.role !== 3 && $store.state.user.role !== 6"
        class="q-counterparty-item__row"
      >
        <div
          class="q-counterparty-item__district"
          @click="isShowModalPolygonTKO = true"
        >
          <span>Районы</span>
          <p>{{data.distr_num}}</p>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.256706 10.3305C-0.112753 10.7411 -0.0794748 11.3733 0.331035 11.7428C0.741545 12.1123 1.37384 12.079 1.74329 11.6685L0.256706 10.3305ZM4.69724 6.89146L5.44054 7.56043L5.44054 7.56043L4.69724 6.89146ZM1.74329 0.330547C1.37383 -0.0799628 0.741544 -0.113241 0.331034 0.256218C-0.0794758 0.625676 -0.112754 1.25797 0.256705 1.66848L1.74329 0.330547ZM4.69724 5.10756L5.44054 4.43859L5.44054 4.43859L4.69724 5.10756ZM1.74329 11.6685L5.44054 7.56043L3.95395 6.2225L0.256706 10.3305L1.74329 11.6685ZM0.256705 1.66848L3.95395 5.77652L5.44054 4.43859L1.74329 0.330547L0.256705 1.66848ZM5.44054 7.56043C6.23918 6.67305 6.23918 5.32597 5.44054 4.43859L3.95395 5.77652C4.06804 5.90329 4.06804 6.09573 3.95395 6.2225L5.44054 7.56043Z" fill="#424242"/></svg>
        </div>
        <div class="q-counterparty-item__tariff">
          <span>Тариф</span>
          <p>{{Number(data.tariff).toFixed(2)}} ₽/т</p>
        </div>
      </div>
    </div>

    <div
      class="q-counterparty-item__right"
      :class="{large: data.role !== 3 && $store.state.user.role !== 3 && $store.state.user.role !== 6 }"
      :style="{'min-width': data.access === 'ro' ? '155px' : '95px'}"
    >

      <div v-if="data.access === 'ro'" class="blocked-ro">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33398 5.99967V5.33301C5.33398 3.86025 6.52789 2.66634 8.00065 2.66634C9.47341 2.66634 10.6673 3.86025 10.6673 5.33301V5.99967L5.33398 5.99967ZM4.00065 6.22971V5.33301C4.00065 3.12387 5.79151 1.33301 8.00065 1.33301C10.2098 1.33301 12.0007 3.12387 12.0007 5.33301V6.22971C12.4017 6.48787 12.6673 6.93824 12.6673 7.45065V9.99967C12.6673 12.577 10.578 14.6663 8.00065 14.6663C5.42332 14.6663 3.33398 12.577 3.33398 9.99967V7.45065C3.33398 6.93824 3.5996 6.48787 4.00065 6.22971ZM4.78496 7.33301C4.71999 7.33301 4.66732 7.38568 4.66732 7.45065L4.66732 9.99967C4.66732 11.8406 6.1597 13.333 8.00065 13.333C9.8416 13.333 11.334 11.8406 11.334 9.99967V7.45065C11.334 7.38568 11.2813 7.33301 11.2163 7.33301H10.6673H5.33398H4.78496ZM8.00065 8.66634C8.73703 8.66634 9.33398 9.2633 9.33398 9.99967C9.33398 10.7361 8.73703 11.333 8.00065 11.333C7.26427 11.333 6.66732 10.7361 6.66732 9.99967C6.66732 9.2633 7.26427 8.66634 8.00065 8.66634Z" fill="white"/>
        </svg>

        <div class="notification-info">
          <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 7L-1.25965e-06 7L5.58579 1.41421C6.36684 0.633163 7.63316 0.633165 8.41421 1.41421L14 7Z" fill="#393939"/></svg>

          <div class="rows">
            <span class="row">{{ data.access_msg }}</span>
          </div>
        </div>
      </div>

      <button ref="target" class="dots-menu">
        <svg :class="{disabled: $store.state.user.role === 3}" @click="$store.state.user.role === 3 ? 1 :isShowDotsMenu = true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="12" r="2" fill="#C6C6C6"/>
          <circle cx="12" cy="12" r="2" fill="#C6C6C6"/>
          <circle cx="18" cy="12" r="2" fill="#C6C6C6"/>
        </svg>

        <transition name="bottom">
          <div  v-if="isShowDotsMenu" class="menu">

            <div
              class="menu-item"
              @click="isShowModalEditAgent = true"
              v-if="$store.state.user.role === 1  && data.role === 3 || $store.state.user.role == 2 || $store.state.user.role == 6 && data.role === 3"
            >
              <img src="@/assets/images/edit_icon.svg" alt="редактировать">
              <span>Редактировать</span>
            </div>

            <div
              class="menu-item"
              @click="isShowModalPolygonTKO = true"
              v-if="$store.state.user.role !== 3 && data.role !== 3"
            >
              <img src="@/assets/images/edit_icon.svg" alt="редактировать">
              <span>Редактировать</span>
            </div>

            <div
              class="menu-item"
              v-if="$store.state.user.role === 1 || $store.state.user.role === 2 || $store.state.user.role === 6"
              @click="data.access === 'ro' ? 1 : isShowQModalSurprint = true"
              :class="{disabled: data.access === 'ro'}"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM11.0484 7.30902C11.3478 6.38771 12.6512 6.3877 12.9505 7.30902L13.7098 9.6459H16.167C17.1357 9.6459 17.5385 10.8855 16.7548 11.4549L14.7669 12.8992L15.5262 15.2361C15.8256 16.1574 14.7711 16.9235 13.9874 16.3541L11.9995 14.9098L10.0116 16.3541C9.22791 16.9235 8.17343 16.1574 8.47278 15.2361L9.23208 12.8992L7.24421 11.4549C6.4605 10.8855 6.86327 9.6459 7.83199 9.6459H10.2891L11.0484 7.30902Z" fill="#C6C6C6"/>
              </svg>
              <span>Печать компании</span>
            </div>

          </div>
        </transition>
      </button>

      <QToggle
        @clicked="!data.status ? changeStatus() : isShowModalDisconnectCounterparty = true"
        :checked="data.status"
        :disabled="data.access === 'ro' || $store.state.user.role === 3"
      />
    </div>
  </div>

  <transition name="default">
    <QModalCounterparties
      v-if="isShowModalEditAgent"
      @close="isShowModalEditAgent = false"
      type="edit"
      :data="data"
      @changedData="changedData"
      @showNotification="notify({ title: $event, type: 'success'})"
      @updateCounterpartiesList="$emit('updateCounterpartiesList')"
      :isEdit="true"
    />
  </transition>

  <transition name="default">
    <QModalPolygonTKO
      v-if="isShowModalPolygonTKO"
      @close="isShowModalPolygonTKO = false"
      @updateCounterpartiesList="$emit('updateCounterpartiesList')"
      :allDistricts="allDistricts"
      :data="data"
      @showNotification="notify({ title: $event, type: 'success'})"
    />
  </transition>

  <transition name="default">
    <QModalDisconnectCounterparty
      v-if="isShowModalDisconnectCounterparty"
      @close="isShowModalDisconnectCounterparty = false"
      @disconnectAgent="changeStatus()"
      :name="`${data.company_name} ${data.description ? '('+data.description+')' : ''}`"
    />
  </transition>

  <transition name="default">
    <QModalSurprint
      v-if="isShowQModalSurprint"
      @close="isShowQModalSurprint = false"
      @showNotification="notify({ title: $event, type: 'success'})"
      @updateCounterpartiesList="$emit('updateCounterpartiesList')"
      :id="data.id"
      :surprints="data.seals"
    />
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, computed } from 'vue'
import QModalCounterparties from '@/components/modals/QModalCounterparties.vue'
import QModalPolygonTKO from '@/components/modals/QModalPolygonTKO.vue'
import QModalDisconnectCounterparty from '@/components/modals/QModalDisconnectCounterparty'
import QModalSurprint from '@/components/modals/QModalSurprint.vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import { onClickOutside } from '@vueuse/core'
import { notify } from '@kyvg/vue3-notification'

const isShowQModalSurprint = ref(false)

const target = ref(null)
onClickOutside(target, (event) => isShowDotsMenu.value = false)


const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  large: {
    type: Boolean,
    default: false
  },
  allDistricts: {
    type: Array,
    default: ()=>[],
  },
})

const emit = defineEmits(['changedData', 'updateCounterpartiesList', 'disableEnable'])

const isShowModalEditAgent = ref(false)
const isShowModalPolygonTKO = ref(false)
const isShowModalDisconnectCounterparty = ref(false)

const isShowDotsMenu = ref(false)


const changeStatus = async() => {
  if(store.state.isDemoMode){
    isShowModalDisconnectCounterparty.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  isShowModalDisconnectCounterparty.value = false

  const statusData = await useApi('post', 'counterparties/set_status_counterparties', {
    id: props.data.id,
    status: !props.data.status
  })
  emit('disableEnable', props.data.id)
  notify({ title: 'Статус контрагента успешно изменен!', type: 'success'})
}

const changedData = (data) => emit('changedData', props.data)

const role = computed(()=>{
  if(props.data.role === 1) return 'Региональный оператор'
  if(props.data.role === 2) return 'Площадка'
  if(props.data.role === 3) return 'Перевозчик'
})
</script>

<style scoped lang="scss">
.q-counterparty-item {
  width: calc(50% - 9px);
  background: $white;
  margin-bottom: 14px;
  padding: 16px 12px 16px 24px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__right {
    min-width: 95px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.large{
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
      height: 100%;
      button{
        margin-right: 0;
      }
    }

    button{
      height: 24px;
      margin-right: 30px;
    }
  }

  .transporter{
    color: rgba(66, 66, 66, 0.5);
    font-size: 16px;
    line-height: 30px;
    max-width: 410px;
    position: relative;

    &__value{
      margin-top: 0;
      color: $ford-medium-dark;
      font-size: 16px;
      margin-top: -2px;
      -webkit-line-clamp: 2; /* Число отображаемых строк */
      display: -webkit-box; /* Включаем флексбоксы */
      -webkit-box-orient: vertical; /* Вертикальная ориентация */
      overflow: hidden;
    }
  }

  &__row{
    margin-top: 25px;
    display: flex;
    align-items: center;
  }

  &__district,
  &__tariff{
    padding: 8px 16px;
    background: #D9EBFF;
    border-radius: 14px;
    display: flex;
    align-items: center;
    width: 200px;
    cursor: pointer;
    span{
      width: inherit;
    }
    p{
      margin-right: 10px;
      white-space: nowrap;
    }
  }
  &__tariff{
    background: $caparol;
    margin-left: 16px;
    cursor: auto;
  }
}

.rfid-block{
  margin-top: 30px;
}

.dots-menu{
  position: relative;
  svg{
    circle{
      transition: ease .3s;
    }
    &:hover{
      circle{
        fill: #B2B2B2;
      }
    }
    &.disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  .menu{
    position: absolute;
    right: 0;
    top: 32px;
    padding: 8px;
    border-radius: 22px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    background: white;
    z-index: 2;
    gap: 10px 0;
    display: flex;
    flex-direction: column;
    .menu-item{
      padding: 8px 10px;
      display: flex;
      align-items: center;
      border-radius: 14px;
      transition: ease .3s;
      span{
        font-size: 16px;
        margin-left: 10px;
        white-space: nowrap;
      }
      &:hover{
        background: rgba(85, 85, 85, 0.10);
      }
      &.disabled{
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}

.notification-info{
  position: absolute;
  background: #393939;
  padding: 16px;
  width: 270px;
  border-radius: 14px;
  // top: 100px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: ease .3s;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  svg{
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
  .row{
    color: white;
    display: block;
    margin-top: 26px;
    position: relative;
    font-family: "TT Norms Pro Medium", sans-serif;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    &:before{
      content: "";
      height: 2px;
      width: 100%;
      background: #FFFFFF;
      opacity: 0.1;
      border-radius: 1px;
      position: absolute;
      top: -14px;
    }
    &:first-child {
      margin-top: 0px;
      &:before{
        display: none !important;
      }
    }
  }
}

.blocked-ro{
  width: 30px;
  height: 30px;
  border-radius: 12px;
  background: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  // position: absolute;
  // right: 5px;
  // top: 42px;
  cursor: pointer;
  .notification-info{
    left: auto;
    right: -140px;
    top: 40px;
    svg{
      left: auto;
      right: 6px;
    }
  }
  &:hover{
    .notification-info{
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
