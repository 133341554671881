
<template>
  <q-modal @close="$emit('close')">
    <div class="content" v-if="act">
      <div class="wrapper">
        <div class="slider">
          <div class="content__slide"
            :style="{ backgroundImage: act.photo_in === '' ? 'url(' + require('@/assets/images/noImg.svg') + ')' : `url(https://signall.qodex.tech/media/acts/full/${act.photo_in})`}"
            :class="{'show': !showSlide}"
          >
            <div class="content__row">
              <q-license-plate :number="act.state_number"/>
              <span>Время въезда – {{ getDate(act.date_in) }}</span>
              <div class="content__btn">
                <q-button
                  label="Выезд"
                  view="blur"
                  @clicked="showSlide = true"
                >
                  <template v-slot:icon-right>
                    <img class="content__arrow" src="@/assets/images/next-arrow.svg"/>
                  </template>
                </q-button>
              </div>
            </div>
          </div>
          <div class="content__slide"
            :style="{ backgroundImage: act.photo_out === '' ? 'url(' + require('@/assets/images/noImg.svg') + ')' : `url(https://signall.qodex.tech/media/acts/full/${act.photo_out})`}"
            :class="{'show': showSlide}"
          >
            <div class="content__row">
              <q-license-plate :number="act.state_number"/>
              <span>Время выезда – {{ getDate(act.date_out) }}</span>
              <div class="content__btn">
                <q-button
                  label="Въезд"
                  view="blur"
                  @clicked="showSlide = false"
                >
                  <template v-slot:icon-left>
                    <img class="content__arrow content__arrow--left" src="@/assets/images/next-arrow.svg"/>
                  </template>
                </q-button>
              </div>
            </div>
          </div>
          <img class="fake-img" :src="`https://signall.qodex.tech/media/acts/full/${act.photo_in}`" alt="">
        </div>
        <div class="info">
        <div
          class="act active"
          v-if="act"
        >
          <div class="act__top">
            <div class="act__content">
              <div class="act__header">
                <div class="act__header-left">

                  <div class="act__id">Акт № {{ act.number }}
                    <img
                      v-if="$store.state.user.role == 2"
                      src="@/assets/images/edit_icon.svg"
                      alt="редактировать"
                      @click="isShowModalEditAct = true"
                    >
                    <div v-if="act.dispute" class="disput-label">Оспорен</div>

                  </div>
                  <div class="act__period">{{ date }}</div>

                </div>
                <div class="act__header-right">
                  <div class="act__info">
                    <div class="act-info">
                      <div class="act-info__item"><span>Брутто</span> {{ (act.brutto / 1000).toFixed(2) }}</div>
                      <div class="act-info__separator"></div>
                      <div class="act-info__item"><span>Тара</span> {{ (act.tara / 1000).toFixed(2) }}</div>
                      <div class="act-info__separator"></div>
                      <div class="act-info__item"><span>Нетто</span> {{ (act.netto / 1000).toFixed(2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="act__body">
                <div class="act-body-descr">
                  <div class="act-body-descr__title">Перевозчик</div>
                  <div class="act-body-descr__text">{{ act.transporter.name }} </div>
                </div>
                <div class="act-body-descr">
                  <div class="act-body-descr__title">Площадка</div>
                  <div class="act-body-descr__text">{{ act.platform }}</div>
                </div>
              </div>
              <div class="bottom-right">
              <div class="bottom-right__separator"></div>
              <!-- <div class="bottom-right__containers">
                <div class="bottom-right__container lime">
                  <span>Всего контейнеров</span>
                  <span>67</span>
                </div>
                <div class="bottom-right__container">
                  <span>Планировалось</span>
                  <span>80</span>
                </div>
              </div> -->
              <div class="bottom-right__activity">
                <div class="activity-item">
                  <span class="activity-item__header">
                    <img src="@/assets/images/warning.svg" alt="" />
                    Предупреждения
                  </span>
                  <div class="activity-item__body">
                    <span class="item empty" v-if="!act.alerts.length">Предупреждений нет</span>
                    <span
                      class="item"
                      v-for="(alert, idx) in act.alerts"
                      :key="idx"
                    >
                      <div class="warn-date">{{alert.date ? alert.date : 'Время неизвестно'}}</div>
                      <div class="text">{{alert.text}}</div>
                    </span>
                  </div>
                </div>
                <div class="activity-item">
                  <span class="activity-item__header">
                    <img src="@/assets/images/messages.svg" alt="" />
                    Комментарии
                  </span>
                  <div class="activity-item__body">
                    <span class="item empty" v-if="!act.comments.length">Комментариев нет</span>
                    <span
                      class="item"
                      v-for="(comment, idx) in act.comments"
                      :key="idx"
                    >
                      {{comment}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
              <div class="act__warnings">
                <div class="warning-item" :class="{activeWarn: act.alerts.length}">
                  <img :src="require(`@/assets/images/warning${act.alerts.length ? '-active' : ''}.svg`)" alt="" />
                  <span>{{act.alerts.length}}</span>
                </div>
                <div class="warning-item" :class="{activeMess: act.comments.length}">
                  <img :src="require(`@/assets/images/messages${act.comments.length ? '-active' : ''}.svg`)" alt="" />
                  <span>{{act.comments.length}}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <transition name="slide-fade"> -->
          <div class="act__bottom">
            <div class="bottom-left">
              <div v-if="$store.state.user.role == 1">
                <button v-if="act.dispute" class="btn-dispute" @click="changeStatus">
                  <span>Акт оспорен</span>
                  <div class="divider" />
                  <img src="@/assets/images/back-around-arrow.svg" alt="">
                </button>
                <q-button
                  v-else
                  label="Оспорить акт"
                  view="primary"
                  @clicked="changeStatus"
                />
              </div>

              <div class="bottom-left__info">
                <div class="bottom-left__info-title">Доп. данные</div>
                <div class="bottom-left__info-row">
                  <span>Категория отходов</span>
                  <span>{{act.waste_category.label || '-'}}</span>
                </div>
                <div class="bottom-left__info-row">
                  <span>Вид груза</span>
                  <span>{{act.waste_type.label || '-'}}</span>
                </div>
                <div class="bottom-left__info-row">
                  <span>Объем кузова ТС</span>
                  <span> {{ act.capacity }} </span>
                </div>
                <div class="bottom-left__info-row">
                  <span>Пункт назначения</span>
                  <span> {{ act.platform_type }} </span>
                </div>
                <div class="bottom-left__info-row">
                  <span>Маршрут</span>
                  <span>{{act.route}}</span>
                </div>
                <div class="bottom-left__info-row">
                  <span>Собрано контейнеров</span>
                  <span>{{act.containers}}</span>
                </div>
                <div
                  class="bottom-left__info-row"
                  v-if="$store.state.user.role !== 2 || $store.state.user.role !== 6"
                >
                  <span>Заявка</span>
                  <span>{{ act.bid_id }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import moment from 'moment'
import store from '@/store'


const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const showSlide = ref(false)

const getDate = (time) => {
  return moment(time).utc().format('DD.MM.YYYY ・ HH:mm')
}

const act = ref(null)

onMounted(async()=> {
  const actData = await useApi('post', 'acts/get_act_by_id', {
    act_id: props.id
  })
  act.value = actData.act
})


const emit = defineEmits(['updateActStatus', 'updateActsList'])

const date = computed(() => {
  return `${moment(act.date_in).utc().format('DD.MM.YYYY ・ HH:mm')} - ${moment(act.date_out).utc().format('HH:mm')}`
})

const changeStatus = async() => {
  const changeStatusData = await useApi('get', `acts/update_dispute/${act.id}`)
  if(changeStatusData.status === 'success'){
    emit('updateActStatus', act.id)
  }
}
</script>

<style scoped lang="scss">
.content{
  width: 1180px;
  padding: 16px;
  max-height: 100vh;

  .wrapper{
    max-height: calc(100vh - 72px);
    overflow: auto;
    overflow-x: hidden;
  }

  .slider{
    width: 100%;
    height: 690px;
    position: relative;
  }

  .default-image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    border-radius: 24px;
  }

  &__slide{
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;

    display: inline-block;
    transition: ease .3s;
    z-index: 2;
    opacity: 0;
    border-radius: 24px;
    visibility: hidden;
    &.show{
      opacity: 1;
      visibility: visible;
    }
  }

  &__btn{
    width: 116px;
  }

  &__arrow{
    margin-left: 10px;
    &--left{
      margin-left: 0px;
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }

  &__row{
    padding: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 150px;
    background: linear-gradient(transparent, $gm-dark-gray);
    border-radius: 22px;
    span{
      flex: 1;
      color: $white;
      padding: 0 18px;
      margin-bottom: 4px;
    }
  }
}

.fake-img{
  max-height: 100%;
  opacity: 0;
  visibility: hidden;
}

.warn-date{
  opacity: 0.5;
  line-height: 22px;
}


.act {
  background: $white;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  &__top{
    display: flex;
    margin-top: 20px;
    width: -webkit-fill-available;
  }
  &__header-left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
  &__image {
    margin-right: 32px;
    width: 198px;
    height: 124px;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: ease-out .4s;
    flex: none;

    .default-image{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
  &__panel{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, #292929);
    height: 50px;
  }
  &__number {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
  &__open-gallery {
    position: absolute;
    right: 12px;
    bottom: 6px;
  }
  &__content {
    flex-grow: 1;
    position: relative;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    &-right {
      display: flex;
    }
  }
  &__id {
    font-size: 22px;
    line-height: 26px;
    margin-top: 8px;
    margin-bottom: 4px;
    display: flex;

    img{
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &__period {
    color: $dulux-australia;
    width: 100%;
  }
  &__body {
    display: flex;
  }
  .act-info {
    background: $caparol;
    width: 400px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    margin-right: 17px;
    margin-top: 4px;
    &__item {
      span {
        color: $dulux-australia;
        margin-right: 8.5px;
      }
    }
    &__separator {
      width: 1px;
      height: 18px;
      background: $silver-bullet;
      border-radius: 2px;
      margin: 0 24px;
    }
  }
  .act-body-descr {
    margin-right: 63px;
    &__title {
      color: rgba($ford-medium-dark, 0.5);
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      font-size: 16px;
      line-height: 20px;
      width: 314px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__warnings {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    transition: ease .1s;
  }
  .warning-item {
    display: flex;
    align-items: center;
    color: $dulux-australia;
    padding: 4px 13px;
    background: $caparol;
    border-radius: 12px;
    line-height: 13px;
    margin-left: 10px;
    span {
      display: block;
      margin-left: 5px;
      color: $dulux-australia;
    }
    &.activeWarn{
      background: $orange;
      span{
        color: $ford-medium-dark;
      }
    }
    &.activeMess{
      background: $blue-light;
      span{
        color: $ford-medium-dark;
      }
    }
  }
  &__bottom{
    margin-right: 32px;
    transition: all .4s;
    .bottom-left{
      width: 250px;
      transition: ease-out .4s;
      transform: translateY(-30px);
      opacity: 0;
      transition-delay: .1s;
      flex: none;

      &__info{
        padding: 0 14px;
        margin-top: 20px;
      }
      &__info-title{
        font-size: 18px;
        margin-bottom: 7px;
      }
      &__info-row{
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        span:first-child{
          opacity: .5;
        }
      }
    }
  }
  .bottom-right{
    flex-grow: 1;
    display: flex;
    position: relative;
    padding-top: 50px;
    transition: ease-out .4s;
    transform: translateY(-30px);
    transition-delay: .1s;
    opacity: 0;
    &__separator{
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background: $warmweiss;
      position: absolute;
      left: 0;
      width: 99%;
      top: 22px;
    }

    &__container{
      width: 255px;
      font-size: 14px;
      line-height: 20px;
      padding: 7px 16px;
      background: $caparol;
      border-radius: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      span{
        opacity: .5;
      }
      &.lime{
        background-color: $lemon-lime;
        span{
          opacity: 1;
        }
      }
    }
    &__activity{
      display: flex;
      justify-content: space-between;
      padding-right: 5px;
      flex-grow: 1;

      .activity-item{
        width: 48%;
        &__header{
          font-size: 18px;
          margin-bottom: 11px;
          display: flex;
          img{
            margin-right: 4px;
          }
        }
        &__body{
          font-size: 14px;
          padding: 7px 16px;
          background: $caparol;
          border-radius: 14px;
          color: rgba($ford-medium-dark, .5);
          .item{
            display: block;
            margin-bottom: 7px;
            &:last-child{
              margin-bottom: 0;
            }
            &.empty{
              opacity: .5;
            }
          }
        }
      }
    }
  }

  &.active{
    .act-body-descr__text{
      overflow: auto;
      white-space: normal;
    }
    .act__warnings{
      opacity: 0;
    }
    .act__bottom{
      height: -webkit-fill-available;
    }
    .bottom-left,
    .bottom-right{
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.disput-label{
  font-size: 12px;
  line-height: 22px;
  background:  $orange;
  margin-left: 10px;
  padding: 2px 10px;
  width: fit-content;
  border-radius: 8px;
}

.btn-dispute{
  border-radius: 14px;
  padding: 10px 20px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #E1FF4C;
  .divider{
    width: 1px;
    height: 18px;
    background: rgba(66, 66, 66, 0.3);
    border-radius: 2px;
    margin: 0 15px;
  }
  span{
    margin-right: 25px;
  }
}
</style>
