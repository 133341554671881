<template>
  <q-modal
    @close="$emit('close')"
  >
    <div class="content">
      <div class="default-image" :style="{ backgroundImage: 'url(' + require('@/assets/images/noImg.svg') + ')' }"></div>
      <div
        class="content__slide"
        :style="{ backgroundImage: `url(${assetsEnv}/media/acts/full/${photo}` }"
      />
    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import store from '@/store'

const assetsEnv = computed(()=> store.state.isDemoMode ? process.env.VUE_APP_BASE_API_DEMO_ASSETS : process.env.VUE_APP_BASE_API_ASSETS)

const props = defineProps({
  photo: {
    type: String,
    default: '',
  }
})
</script>

<style scoped lang="scss">
.content{
  width: 860px;
  height: 575px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  .default-image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__slide{
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    transition: ease .3s;
  }
}
</style>
