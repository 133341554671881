<template>
  <div class="rfid-item">
    <div class="left">
      <div class="type">{{ rfid.type.label }}</div>
      <span class="mask">{{ rfid.mask }}</span> <span>{{ rfid.number }}</span> <span class="free" :class="{active: !rfid.is_free}"> {{ rfid.is_free ? 'Свободна' : 'Занята' }} </span>
      <div v-if="rfid.owner" class="company">{{ rfid.owner.label }}</div>
    </div>
    <img
      src="@/assets/images/delete.svg"
      @click="isShowModalConfirm = true"
    />
  </div>

  <transition name="default">
    <QModalConfirmation
      v-if="isShowModalConfirm"
      @close="isShowModalConfirm = false"
      :data="rfid"
      title="Удаление метки RFID"
      @confirm="removeRfid($event)"
    >
      Уверены, что хотите удалить метку "{{ rfid.number }}" ?
    </QModalConfirmation>
  </transition>
</template>

<script setup>
import { defineProps, ref, computed, onMounted, watch, watchEffect, reactive } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import QModalConfirmation from '@/components/modals/QModalConfirmation.vue'

const emit = defineEmits(['notification', 'updateRfidList'])

const props = defineProps({
  rfid: {
    type: Object,
    required: true
  },
})

const isShowModalConfirm = ref(false)

const removeRfid = async(e) => {
  store.dispatch('setGlobalPreloader', true)

  const req = await useApi('delete', `admin/rfid/delete/${props.rfid.id}`)
  if(req.status === "successful"){
    emit('updateRfidList')
    emit('notification', `Метка "${props.rfid.number}" успешно удалена`)
    isShowModalConfirm.value = false

    store.dispatch('setGlobalPreloader', false)
  }
}

</script>

<style scoped lang="scss">
.rfid-item{
  background: white;
  border-radius: 20px;
  padding: 14px 24px;
  width:calc(50% - 10px);
  display: flex;
  justify-content: space-between;
  .left{
    .type{
      font-size: 16px;
      opacity: .5;
      margin-bottom: 4px;
    }
    span{
      font-size: 18px;
    }
    .free{
      margin-left: 10px;
      color: #82EA89;
      &.active{
        color: #F69782;
      }
    }
    .company{
      marin-top: 4px;
      font-size: 18px;
    }
  }
  img{
    cursor: pointer;
    width: 40px;
  }
}

.mask{
  margin-right: 10px;
  font-size: 18px;
  line-height: 22px;
}
</style>