<template>
  <div class="page container">
    <SettingsUser />
    <SettingsWeight v-if="visibleWeightSection" />

    <q-bottom-panel :isFixed="true" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'

import QBottomPanel from "@/components/common/QBottomPanel.vue"
import SettingsUser from './SettingsUser.vue'
import SettingsWeight from './SettingsWeight.vue'
import store from '@/store'
import useRouterGuard from '@/composables/useRouterGuard'

onMounted(async ()=> {
  await useRouterGuard()
})

const route = useRoute()
document.title = `${route.meta.routeTitle} | QODEX`

const visibleWeightSection = computed(()=> {
  if(store.state.user.role === 3 || store.state.user.role === 6){
    return false
  }
  return true
})
</script>

<style scoped lang="scss">
.page {
  padding: 110px 0;
}
</style>