<template>
  <q-modal @close="$store.dispatch('setDemoModal', false)">
    <form class="content" @submit.prevent="">
      <h5>Вы успешно изучили данный функционал</h5>

      <p>Это демоверсия. Функция работает, но ваши изменения внесены не будут</p>

      <q-button
        label="Хорошо"
        view="secondary"
        @clicked="$store.dispatch('setDemoModal', false)"
      />
    </form>
  </q-modal>
</template>

<script setup></script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;

  p{
    font-size: 16px;
    line-height: 19px;
    opacity: .5;
    margin: 24px 0;
  }
}
</style>
