<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5>{{data ? 'Редактирование' : 'Добавление'}} района</h5>

      <div class="form-item">
        <label>Название района</label>
        <q-input
          placeholder="Наример: Белинский район"
          type="text"
          :disabled="false"
          :white="true"
          v-model="title"
        />
      </div>

      <div class="form-item">
        <label>Тоннаж по тер. схеме</label>
        <q-input
          placeholder=""
          type="number"
          :disabled="false"
          :white="true"
          v-model="tonnage"
        />
      </div>

      <div class="error-text">
        {{errorText}}
      </div>

      <div class="form-buttons">
        <div class="">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="submitForm"
            :disabled="validate"
          />
        </div>
      </div>
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'

const props = defineProps({
  data: {
    type: Object,
  }
})

const emit = defineEmits(['close', 'updateDistrictsList', 'showNotification'])

const title = ref('')
const tonnage = ref('')
const errorText = ref('')

onMounted(()=> {
  errorText.value = ''

  if(props.data) {
    title.value = props.data.name
    tonnage.value = props.data.tonnage_scheme
  }
})

const validate = computed(() => {
  if  (title.value.length && tonnage.value.length){
    return false
  } else {
    return true
  }
})

const submitForm = async() => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  const payload = {
    id: props.data ? props.data.id : '',
    name: title.value,
    tonnage_scheme: tonnage.value
  }

  const actsData = await useApi('post', `distrs/${props.data ? 'update' : 'create'}_distr`, payload)

  if(actsData.message){
    errorText.value = actsData.message.text
    return
  }
  emit('updateDistrictsList')
  emit('close')
  emit('showNotification', props.data ? 'Успешное редактирование района' : 'Успешное добавление района')
}
</script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;

  .form-item{
    margin-bottom: 24px;

    &--btn{
      padding-top: 10px;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }

  .error-text{
    position: absolute;
    bottom: 84px;
    color: $red;
  }
}</style>
