<template>
  <div class="item">
    <div class="row">
      <div class="block w100">
        <span>{{ data.role_text }}</span>
        <span>{{ data.label }}</span>
      </div>
      <img
        src="@/assets/images/edit_icon.svg"
        alt="редактировать"
        @click="isShowModalCompany = true"
      >
    </div>

    <div class="row">
      <div class="block">
        <span>ИНН/КПП</span>
        <span>{{ data.inn }}{{ data.kpp ? '/' : '' }}{{ data.kpp }}</span>
      </div>
      <div class="block">
        <span>Сфера деятельности</span>
        <span>{{ data.sphere.label }}</span>
      </div>
    </div>
    <div class="row">
      <div class="block">
        <span>E-mail</span>
        <span>{{ data.email }}</span>
      </div>
    </div>
    <div class="row">
      <div class="btns">
        <button class="rfid" @click="isShowModalRfidChoose = true">
          Добавить RFID
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.6"><path d="M7 13.9995L10.6972 9.89146C11.1536 9.38439 11.1536 8.61463 10.6972 8.10756L7 3.99951" stroke="#424242" stroke-width="2" stroke-linecap="round"/></g></svg>
        </button>
        <button @click="isShowModalGravity = true" class="grav">
          Gravity
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.6"><path d="M7 13.9995L10.6972 9.89146C11.1536 9.38439 11.1536 8.61463 10.6972 8.10756L7 3.99951" stroke="#424242" stroke-width="2" stroke-linecap="round"/></g></svg>
        </button>
        <a
          v-if="data.disk"
          :href="data.disk"
          target="_blank"
          class="disc"
        >Диск</a>
      </div>
      <QToggle
        :checked="data.status"
        @click="setStatus(data)"
      />
    </div>
  </div>

  <transition name="default">
    <QModalCompany
      v-if="isShowModalCompany"
      @close="isShowModalCompany = false"
      @notification="$emit('notification', $event)"
      :data="data"
      :spheres="spheres"
      :roles="roles"
      :ro="ro"
      @updateCounterpartiesList="$emit('updateCounterpartiesList')"
    />
  </transition>

  <transition name="default">
    <QModalGravity
      v-if="isShowModalGravity"
      @close="isShowModalGravity = false"
      :id="data.id"
    />
  </transition>

  <transition name="default">
    <QModalRfidChoose
      v-if="isShowModalRfidChoose"
      @close="isShowModalRfidChoose = false"
      :data="data"
    />
  </transition>

</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, computed } from 'vue'
import QModalCompany from '@/components/modals/QModalCompany.vue'
import QModalGravity from '@/components/modals/QModalGravity.vue'
import QModalRfidChoose from '@/components/modals/QModalRfidChoose.vue'
import store from '@/store'
import useApi from '@/composables/useApi'


const isShowModalCompany = ref(false)
const isShowModalGravity = ref(false)
const isShowModalRfidChoose = ref(false)


const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  spheres: {
    type: Array,
    default: () => []
  },
  roles: {
    type: Array,
    default: () => []
  },
  ro: {
    type: Array,
    default: () => []
  },
})
const emit = defineEmits(['notification', 'updateCounterpartiesList', 'updateStatus'])

const setStatus = async(data) => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'admin/company/set_status', {
    id: data.id,
    status: !data.status,
  })
  emit('updateStatus', data.id)
}
</script>

<style lang="scss" scoped>
  .item{
    width: calc(50% - 9px);
    background: $white;
    margin-bottom: 14px;
    padding: 12px 16px 12px 12px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 9px 0;

    .row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        cursor: pointer;
      }
      &:nth-child(1){
        align-items: flex-start;
      }
      &:nth-child(4){
        margin-top: 8px;
      }
      .btns{
        display: flex;
        gap: 0 10px;
        button, a{
          display: flex;
          align-items: center;
          padding: 8px 8px 8px 16px;
          gap: 10px;
          border-radius: 14px;
          background: #F7FFD9;
          text-decoration: none;
          &.disc{
            background: #BBDAFF;
            padding: 8px 16px;
          }
        }
      }
    }
    .block{
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 4px 0;
      padding-left: 12px;
      &.w100{
        width: 100%;
      }
      span{
        display: block;
        font-size: 16px;
        opacity: 0.5;
        &:nth-child(2){
          opacity: 1;
          font-size: 18px;
        }
      }
    }
  }
</style>