<template>
  <button
    class="q-button"
    :class="`q-button--${view}`"
    @click="onClick"
    :disabled="disabled"
  >
    <slot name="icon-left" />
    {{ label }}
    <slot name="icon-right" />
  </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  view: {
    type: String,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['clicked'])

const onClick = () => {
  if (!props.disabled) emit('clicked')
}
</script>


<style scoped lang="scss">
.q-button {
  border-radius: 14px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled{
    opacity: .3;
    pointer-events: none;
  }

  &--primary {
    background-color: $caparol;
    &:active{
      background-color: $flannel-gray;
    }
    &:hover{
      background-color: $ford-grey;
    }
  }
  &--secondary{
    background-color: $ford-medium-dark;
    color: $white;
    &:active{
      background-color: $ford-graphite;
    }
    &:hover{
      background-color: $chancellor-grey;
    }
  }
  &--blur{
    background-color: rgba($white, .3);
    color: $white;
    &:active{
      background-color: rgba($white, .2);
    }
    &:hover{
      background-color: rgba($white, .4);
    }
  }
  &--flat-w{
    background: transparent;
    color: $white;
    &:active{
      color: $flannel-gray;
    }
    &:hover{
      color: $ford-grey
    }
  }
  &--flat-b{
    background: transparent;
    &:active{
      color: $sonic-silver;
    }
    &:hover{
      color: $sleigh-bells;
    }
  }
  &--filter{
    background: rgba($white, 10%);
    color: rgba($white, 30%);
    height: 48px;
    &:hover{
      background: rgba($matthews, 100%);
      color: $california-paints;
    }
  }
  &--green{
    background: $lemon-lime;
    &:active{
      background: #CDEB00;
    }
    &:hover{
      background: #D7FC00;
    }
  }
}
</style>
