<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5>Редактирование e-mail</h5>

      <div class="form-item">
        <label>Введите пароль</label>
        <q-input
          v-model="form.password"
          placeholder="Введите пароль"
          type="password"
          :white="true"
          :error="v$.password.$errors.length ? 'Заполните поле' : '' || errorMsg"
        />
      </div>

      <div class="form-item">
        <label>Новый e-mail</label>
        <q-input
          placeholder="Введите e-mail"
          type="text"
          :white="true"
          v-model="form.new_email"
          :error="v$.new_email.$errors.length ? 'Заполните поле' : ''"
        />
      </div>

      <div class="form-item form-item--btn">
        <q-button
          label="Далее"
          view="secondary"
          @clicked="submitForm"
        />
      </div>
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, reactive } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const form = reactive({
  password: '',
  new_email: ''
})

const errorMsg = ref('')

const emit = defineEmits(['close', 'showNotification'])


const submitForm = async() => {
  errorMsg.value = ''

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  const send = await useApi('post', `settings/user_update_email`, form)

  if(send.status === 'success'){
    emit('showNotification', 'На вашу электронную почту было отправлено письмо с подтверждением!')
    emit('close')
  }

  else if(send.status === 'error'){
    if(send.msg){
      errorMsg.value = send.msg
    }
  }
}

const validShema = ref({
  password: { required },
  new_email: { required, email },
})

const v$ = useVuelidate(validShema, form)
</script>

<style scoped lang="scss">
h5{
  margin-bottom: 24px;
}
.content{
  width: 350px;
  padding: 36px;
  position: relative;
}
.form-item--btn{
  margin-bottom: 0;
}
</style>
