<template>
  <transition name="default">
    <q-modal-delete-confirmation
      v-if="isShowModalDeleteDistrict"
      @close="isShowModalDeleteDistrict = false"
      title="Удаление района"
      :id="data.id"
      @delete="deleteDistrict"
    >
      <span> Этот район подключен у {{data.num_platform}} {{data.num_platform === 1 ? 'площадки' : 'площадок'}}. Вы точно хотите его удалить?</span> <br/>
    </q-modal-delete-confirmation>
  </transition>

  <transition name="default">
    <q-modal-district
      v-if="isShowModalEditDistrict"
      @close="isShowModalEditDistrict = false"
      :data="data"
      @updateDistrictsList="$emit('updateDistrictsList')"
      @showNotification="notify({ title: $event, type: 'success'})"
    />
  </transition>


  <div class="district-item">
    <div class="district-item__left">
      <div class="district-item__name">{{data.name}}</div>
      <div class="district-item__scheme"><span>Тоннаж по тер. схеме: </span>{{data.tonnage_scheme}}</div>
    </div>
    <div class="district-item__right">
      <img
        src="@/assets/images/edit_icon.svg"
        alt="редактировать"
        @click="isShowModalEditDistrict = true"
      >
      <svg class="remove-btn" @click="isShowModalDeleteDistrict = true" width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="29" rx="11.5" stroke="#C6C6C6"/>
        <g clip-path="url(#clip0_12500_104040)">
        <path d="M25 15H20H15" stroke="#C6C6C6" stroke-width="2.5" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_12500_104040">
        <rect width="14" height="14" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 27 8)"/>
        </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import useApi from '@/composables/useApi'
import useRouterGuard from '@/composables/useRouterGuard'
import QModalDeleteConfirmation from '@/components/modals/QModalDeleteConfirmation.vue'
import QModalDistrict from '@/components/modals/QModalDistrict.vue'
import store from '@/store'
import { notify } from '@kyvg/vue3-notification'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['updateDistrictsList', 'showNotification'])

const isShowModalDeleteDistrict = ref(false)
const isShowModalEditDistrict = ref(false)

const deleteDistrict = async() => {

  if(store.state.isDemoMode){
    // emit('close')
    isShowModalDeleteDistrict.value = false
    store.dispatch('setDemoModal', true)
    return
  }

  const deleteDistrData = await useApi('delete', `distrs/delete_distr/${props.data.id}`)
  if(deleteDistrData.status === 'success'){
    emit('showNotification', 'Район успешно удален')
    emit('updateDistrictsList')
  }
}
</script>

<style scoped lang="scss">
.district-item{
  width: calc(50% - 10px);
  background: white;
  padding: 14px 24px;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__name{
    font-size: 16px;
    margin-bottom: 5px;
  }
  &__scheme{
    background: #F4F4F4;
    border-radius: 12px;
    padding: 5px 16px;
    width: 247px;
    display: flex;
    justify-content: space-between;
    span{
      opacity: .5;
      margin-right: 10px;
    }
  }
  &__right{
    display: flex;
    align-items: center;
  }
}

.remove-btn{
  margin-left: 22px;
  cursor: pointer;
  rect{
    transition: ease .3s;
  }
  g{
    path{
      transition: ease .3s;
    }
  }
  &:hover{
    rect{
      stroke: #A0A0A0;
    }
    g{
      path{
        stroke: #A0A0A0;
      }
    }

  }
}
</style>
