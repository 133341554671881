<template>
  <label class="q-checkbox">
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @click="onClick"
    >
    <span>
      {{ label }}
      <transition name="default">
        <img v-if="checked" :src="require(`@/assets/images/checkbox-${view}.svg`)">
      </transition>
    </span>
  </label>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  view: {
    type: String,
    default: 'default'
  }
})

const emit = defineEmits(['clicked'])

const onClick = () => {
  if (!props.disabled) emit('clicked')
}
</script>


<style scoped lang="scss">
label {
  padding-left: 30px;
  cursor: pointer;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;

  &:disabled ~ span {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
}
span{
  color: $white;
  position: relative;
  &:before {
    position: absolute;
    left: -30px;
    top: -1px;
    content: '';
    width: 14px;
    height: 14px;
    border: 2px solid $darkgrey;
    border-radius: 4px;
    transition: ease .3s;
  }
  &:hover{
    &:before{
      border-color: $ressource-peintures;
    }
    svg{
      path{
        fill: $ford-grey;
      }
    }
  }
  &:active{
    &:before{
      border-color: $sleigh-bells;
    }
    svg{
      path{
        fill: $silver-bullet;
      }
    }
  }
  img{
    position: absolute;
    left: -30px;
    top: -1px;
    transition: ease .3s;
    path{
      transition: ease .3s
    }
  }
}
</style>
