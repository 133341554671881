<template>
  <div class="order-item">

    <div class="order-item__left">
      <div class="row">
        <div class="main">
          <div class="order-item__num">
            <span>
              Заявка № {{ data.number }}
            </span>

            <svg @click="isShowModalOrder = true" v-if="data.status === 'Ожидание'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.229 13.8625L19.8492 6.24229C20.2397 5.85177 20.2397 5.2186 19.8492 4.82808L19.7257 4.70458C19.3352 4.31406 18.702 4.31406 18.3115 4.70458L10.6912 12.3248L12.229 13.8625ZM9.77702 16.2768C8.94858 16.2768 8.27699 15.6052 8.277 14.7768L8.27703 11.9106L16.8973 3.29037C18.0688 2.1188 19.9683 2.11879 21.1399 3.29037L21.2634 3.41387C22.435 4.58544 22.435 6.48493 21.2634 7.6565L12.6432 16.2767L9.77702 16.2768Z" fill="#C6C6C6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4H7Z" fill="#C6C6C6"/>
            </svg>

          </div>
          <div class="order-item__date">{{ date }}</div>
        </div>
        <div class="order-item__transporter">
          <p>Перевозчик</p>
          <span>{{ data.transporter.name }}</span>
        </div>
      </div>
      <div class="row">
        <q-license-plate :number="data.car.state_number"/>
        <div class="order-item__rfid">
          <p>RFID</p>
          <span>{{ data.car.rfid || '-'}}</span>
        </div>
        <div class="order-item__brutto">
          <p>Брутто</p>
          <span>{{ data.brutto }} т</span>
        </div>
      </div>
    </div>

    <div class="order-item__polygon">
      <p>Площадка</p>
      <span>{{ data.platform.name }}</span>
    </div>

    <div
      class="order-item__status"
      :class="{
        red: data.status === 'Просрочено',
        yellow: data.status === 'Ожидание',
        green: data.status === 'Выполнено',
      }"
      @click="data.status === 'Выполнено' ? isShowModalOrderAct = true : false"
    >
      <p>Статус</p>
      <span>{{ data.status }} <template v-if="data.status === 'Выполнено'">・Акт №{{data.act.number }}</template></span>
    </div>

    <transition name="default">
      <q-modal-order
        v-if="isShowModalOrder"
        @close="isShowModalOrder  = false"
        :data="data"
        @successSendOrder="successSendOrder"
      />
    </transition>

    <transition name="default">
      <q-modal-order-act
        v-if="isShowModalOrderAct"
        @close="isShowModalOrderAct = false"
        :id="data.act.id"
      />
    </transition>

    <transition name="left">
      <q-notification v-if="notificationText" :text="notificationText"/>
    </transition>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import moment from 'moment'
import QLicensePlate from '@/components/common/QLicensePlate.vue'
import QModalOrder from '@/components/modals/QModalOrder.vue'
import QModalOrderAct from '@/components/modals/QModalOrderAct.vue'
import QNotification from '@/components/ui/QNotification.vue'


const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const isShowModalOrder = ref(false)

const isShowModalOrderAct = ref(false)

const emit = defineEmits(['updateOrdersList'])


const date = computed(() => {
  return `${moment(props.data.date_start).utc().format('DD.MM.YYYY')} ・ ${moment(props.data.date_start).utc().format('HH:mm')} - ${moment(props.data.date_stop).utc().format('HH:mm')}`
})

const notificationText = ref('')

const successSendOrder = () => {
  isShowModalOrder.value = false
  notificationText.value = 'Заявка успешно отредактирована'
  setTimeout(()=> notificationText.value = '', 4000)
  emit('updateOrdersList')
}
</script>

<style scoped lang="scss">
.order-item{
  padding: 14px 16px 12px 24px;
  background: white;
  border-radius: 20px;
  display: flex;
  position: relative;
  margin-bottom: 16px;

  &__left{
    width: 630px;
    padding-right: 30px;
    width: 630px;
    .row{
      display: flex;
      .main{
        width: 230px;
        margin-bottom: 14px;
      }
    }
  }
  &__num{
    display: flex;
    span{
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.01em;
      margin-right: 10px;
    }
    svg{
      cursor: pointer;
      path{
        transition: ease .3s;
      }
      &:hover{
        path{
          fill: #B2B2B2;
        }
      }
    }
  }
  &__date{
    font-size: 14px;
    line-height: 22px;
    color: $dulux-australia;
    margin-top: 6px;
  }
  &__polygon{
    width: 50%;
  }
  &__transporter,
  &__polygon{
    p{
      font-size: 16px;
      line-height: 22px;
      color: $dulux-australia;
    }
    span{
      font-size: 16px;
      line-height: 20px;
      max-width: 300px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__rfid,
  &__brutto{
    display: flex;
    margin-left: 16px;
    padding: 8px 16px;
    background-color: $caparol;
    border-radius: 12px;
    p{
      font-size: 14px;
      line-height: 22px;
      opacity: .5;
      margin-right: 12px;
    }
    span{
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__status{
    position: absolute;
    right: 16px;
    top: 14px;
    display: flex;
    padding: 5px 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    p{
      color: rgba(66, 66, 66, 0.5);
      margin-right: 4px;
      font-size: 14px;
      line-height: 18px;
    }

    &.yellow{
      background: $orange;
    }
    &.red{
      background: rgba(241, 78, 78, 0.3);
    }
    &.green{
      background: rgba(78, 241, 90, 0.3);
      span{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
