<template>
  <div class="page">
    <img src="@/assets/images/not-found.svg" />
    <span>Страница не найдена</span>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()
document.title = `${route.meta.routeTitle} | QODEX`
</script>

<style scoped lang="scss">
.page{
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0 15px;
  img{
    max-width: 100%;
  }
  span{
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}

:deep(.header__right) {
  display: none !important;
}

</style>