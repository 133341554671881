<template>

  <div class="q-acts-filters">
    <div class="q-acts-filters__item q-acts-filters__item--number">
      <q-input-number
        v-model="number"
        placeholder="М 000 ММ 000"
        type="text"
        filter
        :search="searchedNumbers"
        @searchItemChoose="chooseNumber"
        license
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputNumbers"
        cross
        :masked="maskVal"
      />
    </div>

    <div class="q-acts-filters__item q-acts-filters__item--transporter">
      <q-input
        v-model="transporter"
        placeholder="Перевозчик"
        type="text"
        filter
        :search="searchedTransporters"
        @searchItemChoose="chooseTransporter"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputTransporters"
        cross
      />
    </div>

    <div class="q-acts-filters__item q-acts-filters__item--polygon">
      <q-input
        v-model="polygon"
        placeholder="Площадка"
        type="text"
        filter
        :search="searchedPolygons"
        @searchItemChoose="choosePolygon"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputPolygons"
        cross
      />
    </div>

    <div class="q-acts-filters__item q-acts-filters__item--datepicker">
      <q-date-picker @setFilteredDate="setFilteredDate"/>
    </div>

    <div class="q-acts-filters__item">
      <q-select
        :items="typeSelectItems"
        @changeSelect="typeSelectItemActive = $event"
        :selectedItem="typeSelectItemActive"
        :filter="true"
        :sort="true"
      />
    </div>

    <div
      class="q-acts-filters__item q-acts-filters__item-download-excel"
      @click="$emit('downloadExcel')"
    >
      <img src="@/assets/images/download-file.svg" alt="">
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch, computed } from 'vue'
import QDatePicker from '@/components/common/QDatePicker.vue'
import store from '@/store'
import useApi from '@/composables/useApi'

const props = defineProps({
  tonnage: {
    type: Number
  },
  weights: {
    type: Number
  },
  tonnageDispute: {
    type: Number
  },
  wasteCategory: {
    type: String,
    default: ''
  }
})

const emit = defineEmits([
  'setCarNumber',
  'setTransporter',
  'setPolygon',
  'setSortType',
  'setFilteredDate',
  'downloadExcel'
])

const number = ref('')
const searchedNumbers = ref([])

const inputNumbers = async(e) => {
  if(e){
    const numberData = await useApi('get', `filters/find_state_number/${e}${props.wasteCategory ? '/'+props.wasteCategory : ''}`)
    searchedNumbers.value = numberData.cars
  } else {
    searchedNumbers.value = []
  }
}

const chooseNumber = item => {
  number.value = item.label
  emit('setCarNumber', item.label)
}

const transporter = ref('')
const searchedTransporters = ref([])

const inputTransporters = async(e) => {
  if(e){
    const transporterData = await useApi('get', `filters/find_transporter/${e}`)
    searchedTransporters.value = transporterData.transporters
  } else {
    searchedTransporters.value = []
  }
}
const chooseTransporter = item => {
  transporter.value = item.label
  emit('setTransporter', item.label)
  searchedTransporters.value = []
}


const polygon = ref('')
const searchedPolygons = ref([])

const inputPolygons = async(e) => {
  if(e){
    const polygonsData = await useApi('get', `filters/find_platform/${e}`)
    searchedPolygons.value = polygonsData.platforms
  } else {
    searchedPolygons.value = []
  }
}

const choosePolygon = item => {
  polygon.value = item.label
  emit('setPolygon', item.label)
  searchedPolygons.value = []
}

const filteredDate = ref([])

const setFilteredDate = (date) => {
  filteredDate.value = date
  emit('setFilteredDate', date)
}

const typeSelectItems = ref([
  {
    label: 'Сначала новые',
    id: 'date_desc'
  },
  {
    label: 'Сначала старые',
    id: 'date_asc'
  },
  {
    label: 'По возрастанию брутто',
    id: 'brutto_asc'
  },
  {
    label: 'По убыванию брутто',
    id: 'brutto_desc'
  },
  {
    label: 'Выполненные',
    id: 'done'
  },
  {
    label: 'Ожидаются',
    id: 'wait'
  },
  {
    label: 'Отминет',
    id: 'cancel'
  },
])

const typeSelectItemActive = ref({
  label: 'Сначала новые',
  id: 'date_desc'
})

watch(
  () => typeSelectItemActive.value,
  (val) => emit('setSortType', val.id)
)

const closeSearchPanel = () => {
  searchedNumbers.value = []
  searchedTransporters.value = []
  searchedPolygons.value = []
}

const maskVal = computed(() => Number(number.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>


<style scoped lang="scss">
.q-acts-filters{
  display: flex;
  justify-content: space-between;
  padding: 18px 9px;
  &__item{
    padding: 0 9px;
    &--number{
      width: 215px;
    }
    &--polygon,
    &--transporter{
      flex: 1;
    }
    &-download-excel{
      margin: 0 9px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #555555;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.teleport-container-left{
  display: flex;
}

.acts-dispute{
  background: $natural-color-system;
  padding: 6px 10px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  span{
    margin-left: 5px;
    color: white;
  }
  &__num{
    color: #FFE4D9 !important;
  }
  .hint{
    position: absolute;
    transition: ease .3s;
    top: 90%;
    opacity: 0;
    visibility: hidden;
    left: 50%;
    transform: translateX(-50%);
    background: #393939;
    backdrop-filter: blur(5px);
    border-radius: 14px;
    display: flex;
    &:before{
      position: absolute;
      content: url('~@/assets/images/hint-trinagle.svg');
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
    .hint-col{
      margin: 13px;
      display: flex;
      flex-direction: column;
      span{
        white-space: nowrap;
        &:first-child{
          opacity: .7;
          margin-bottom: 3px;
        }
      }
    }
  }

  &:hover{
    .hint{
      top: 120%;
      opacity: 1;
      visibility: visible;
    }
  }

}

.acts-info {
  background-color: $lemon-lime;
  padding: 8px 16px;
  border-radius: 14px;
  display: flex;
  margin-left: 32px;

  &__item {
    line-height: 20px;
    &:last-child{
      margin-left: 40px;
    }
  }
}
</style>
