<template>
  <q-header />

  <router-view />

  <transition name="default">
    <QGlobalPreloader v-show="$store.state.globalPreloader"/>
  </transition>

  <QDemoSwitchRole v-if="$store.state.isDemoMode"/>

  <transition name="default">
    <QModalDemo v-if="$store.state.demoModal"/>
  </transition>

  <notifications
    :duration="5000"
    classes="notification-class"
  />
</template>

<script setup>
import { watch, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import QHeader from '@/components/common/header/QHeader.vue'
import QGlobalPreloader from '@/components/common/QGlobalPreloader.vue'
import QDemoSwitchRole from '@/components/common/QDemoSwitchRole.vue'
import QModalDemo from '@/components/modals/QModalDemo.vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import { useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()


onMounted(async()=> {
  if(window.location.host.includes('demo')){
    store.dispatch('setDemoMode')
    store.dispatch('login', {
      token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI5MmZhYzAzZS1jZTg2LTRlNDItYmQwNy1jZWExYmI1YTE1MzAiLCJVc2VyUm9sZSI6InJlZ29wZXJhdG9yIiwiVXNlckNvbXBhbnkiOiJiMzEyZTU5ZC0wYjFjLTQ1NmQtYmNkMi1mZGQ4YjJmY2Y4NzkiLCJEYXRlIjoiMjAyMy0wOS0wOFQwNjo1ODo0Ny40MzAyNTg2MDQrMDM6MDAiLCJMb25nIjp0cnVlfQ.N13_L_OX96I21hVkbSh2ZxQMNfWRV73a-eAl20sNTyg',
      role: 1,
      company_name: 'Региональный опреатор',
      access: 'rw',
      remain: true
    })
  }
})

watch(
  () => route.meta.routeTitle,
  () => document.title = `${route.meta.routeTitle} | QODEX`
)
</script>

<style lang="scss">
  #app{ position: relative }
</style>
