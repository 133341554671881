<template>
  <div class="q-transport-filters">
    <div class="q-transport-filters__item q-transport-filters__item--number">
      <q-input-number
        v-model="number"
        placeholder="М 000 ММ 000"
        type="text"
        filter
        :search="searchedNumbers"
        @searchItemChoose="chooseNumber"
        :license="true"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputNumbers"
        cross
        :masked="maskVal"
      />
    </div>
    <div class="q-transport-filters__item q-transport-filters__item--brand">
      <q-input
        v-model="brand"
        placeholder="Марка"
        type="text"
        filter
        :search="searchedBrands"
        @searchItemChoose="chooseBrand"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputBrands"
        cross
      />
    </div>
    <div
      v-if="$store.state.user.role !== 3"
      class="q-transport-filters__item q-transport-filters__item--transporter"
    >
      <q-input
        v-model="transporter"
        placeholder="Перевозчик"
        type="text"
        filter
        :search="searchedTransporters"
        @searchItemChoose="chooseTransporter"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputTransporters"
        cross
      />
    </div>
    <div class="q-transport-filters__item q-transport-filters__item--rfid">
      <q-input
        v-model="rfid"
        placeholder="№ RFID"
        type="text"
        filter
        :search="searchedRfid"
        @searchItemChoose="chooseRfid"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputRfid"
        cross
        masked="rfid"
      />
    </div>
    <div class="q-transport-filters__item">
      <q-select
        :items="typeSelectItems"
        @changeSelect="typeSelectItemActive = $event"
        :selectedItem="typeSelectItemActive"
        :filter="true"
        :sort="true"
        multi
      />
    </div>
    <div
      class="q-transport-filters__item q-transport-filters__item-download-excel filter-round-btn"
      @click="$emit('downloadExcel')"
    >
      <img src="@/assets/images/download-file.svg" alt="">
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watchEffect, watch, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'

const props = defineProps({

})

const emit = defineEmits([
  'getFilteredContent',
  'setCarNumber',
  'setTransporter',
  'setBrand',
  'setRfid',
  'setSortType',
  'downloadExcel'
])

const number = ref('')
const searchedNumbers = ref([])

const inputNumbers = async(e) => {
  if(e){
    const numberData = await useApi('get', `filters/find_state_number/${e}`)
    searchedNumbers.value = numberData.cars
  } else {
    searchedNumbers.value = []
  }
}

const chooseNumber = item => {
  number.value = item.label
  emit('setCarNumber', item.label)
}

const transporter = ref('')
const searchedTransporters = ref([])

const inputTransporters = async(e) => {
  if(e){
    const transporterData = await useApi('get', `filters/find_transporter/${e}`)
    searchedTransporters.value = transporterData.transporters
  } else {
    searchedTransporters.value = []
  }
}
const chooseTransporter = item => {
  transporter.value = item.label
  emit('setTransporter', item.id)
  searchedTransporters.value = []
}


const brand = ref('')
const searchedBrands = ref([])

const inputBrands = async(e) => {
  if(e){
    const brandData = await useApi('get', `filters/find_brand/${e}`)
    searchedBrands.value = brandData.brands
  } else {
    searchedBrands.value = []
  }
}
const chooseBrand = item => {
  brand.value = item.label
  emit('setBrand', item.label)
  searchedBrands.value = []
}


const rfid = ref('')
const searchedRfid = ref([])

const inputRfid = async(e) => {
  if(e){
    const rfidData = await useApi('get', `filters/find_rfid/${e}`)
    searchedRfid.value = rfidData.cars
  } else {
    searchedRfid.value = []
  }
}
const chooseRfid = item => {
  rfid.value = item.label
  emit('setRfid', item.label)
  searchedRfid.value = []
}

const closeSearchPanel = () => {
  searchedNumbers.value = []
  searchedTransporters.value = []
  searchedBrands.value = []
  searchedRfid.value = []
}


onMounted( async()=> {
  const filters = await useApi('get', `filters/car_all`)

  typeSelectItems.value = filters.items.map(item => {
    return {
      label: item.label,
      id: item.key,
      children: item.children ? item.childrens : null
    }
  })
})

const typeSelectItems = ref([])

const typeSelectItemActive = ref({})

watch(
  () => typeSelectItemActive.value,
  (val) => emit('setSortType', val.id)
)

const maskVal = computed(() => Number(number.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>


<style scoped lang="scss">
.q-transport-filters{
  display: flex;
  justify-content: space-between;
  padding: 18px 9px;
  &__item{
    padding: 0 9px;
    &--number{
      width: 215px;
    }
    &--polygon,
    &--transporter{
      flex: 1;
    }
    &--brand{
      flex: 1
    }
    &--rfid{
      width: 165px;
    }
    &-download-excel{
      margin: 0 9px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #555555;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
:deep(.multi) {
  width: 270px !important;
}

:deep(.subtree) {
  &:hover{
    .children{
      transform: translateX(-65px) !important;
    }
  }
  width: 270px !important;
}

:deep(.subtree .children) {
  width: 300px !important;
  right: 202px !important;
  bottom: -16px !important;
}

</style>
