<template>
  <div></div>
</template>

<script setup>
import useApi from '@/composables/useApi'
import { useRoute, useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import { onMounted } from 'vue'
import store from '@/store'

const route = useRoute()
const router = useRouter()

onMounted(async ()=> {
  store.dispatch('setGlobalPreloader', true)

  const send = await useApi('post', `settings/check_update_email/${route.params.key}`, {})

  if(send.status == "success"){
    store.dispatch('logout')
    notify({ title: 'Ваш E-mail был успешно изменен!', type: 'success'})
  }
    router.push('/')
    store.dispatch('setGlobalPreloader', false)
})

</script>