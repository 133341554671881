<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <!-- liscensePlate.value.length >= 8 &&
  organisationSelectItemActive.value.id &&
  clientItemActive.value.id &&
  comment.length
      <pre>{{ liscensePlate.value.length >= 8 }}</pre>
      <pre>{{ organisationSelectItemActive.value.id }}</pre>
      <pre>{{ clientItemActive.value.id }}</pre>
      <pre>{{  }}</pre> -->


      <h5>Редактирование пропуска №{{data.number}}</h5>

      <div class="form-item">
        <label class="required">Гос. № ТС</label>
        <q-input-number
          placeholder="М 000 М 000"
          type="text"
          :disabled="false"
          :white="true"
          v-model="liscensePlate"
          license
          :masked="maskVal"
        />
      </div>
      <div class="form-item">
        <label class="required">Клиент</label>
        <q-select
          :items="clientsItems"
          @changeSelect="clientItemActive = $event"
          :selectedItem="clientItemActive"
          :maxHeight="145"
        />
      </div>
      <div class="form-item">
        <label class="required">Перевозчик</label>
        <q-select
          :items="organisationSelectItems"
          @changeSelect="organisationSelectItemActive = $event"
          :selectedItem="organisationSelectItemActive"
          :maxHeight="145"
        />
      </div>
      <div class="form-item">
        <label class="required">Комментарий</label>
        <q-input
          placeholder="Введите комментарий"
          type="text"
          :white="true"
          v-model="comment"
        />
      </div>
      <q-button
        label="Сохранить"
        view="secondary"
        @clicked="submitForm"
        :disabled="!validate"
      />
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue'
import QNotification from '@/components/ui/QNotification.vue';
import useApi from '@/composables/useApi'
import moment from 'moment'
import store from '@/store'

const props = defineProps({
  data: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'updateActsList', 'showNotification'])

const liscensePlate = ref('')
const comment = ref('')

const clientsItems = ref([])
const clientItemActive = ref({
  id: '',
  label: 'Выберите клиента'
})

const organisationSelectItems = ref([]);
const organisationSelectItemActive = ref({id: '', label: ''});

const date = computed(()=> moment(props.data.date_update).utc().format('DD.MM.YYYY'))

const getOrganisations = async() => {
  const transportersData = await useApi('get', `company/get_transporters`)
  organisationSelectItems.value = transportersData.transporters
};


onMounted(async ()=> {
  const clients = await useApi('get', 'acts/clients')

  getOrganisations()

  clientsItems.value = clients.data.clients

  clientItemActive.value = {
    id: props.data.client.id,
    label: props.data.client.name
  }

  organisationSelectItemActive.value = {id: props.data.transporter.id, label: props.data.transporter.name}
  liscensePlate.value = props.data.state_number
})

const submitForm = async() => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  store.dispatch('setGlobalPreloader', true)

  const sendForm = await useApi('post', 'passes/update', {
    client_id: props.data.client.id,
    comment: comment.value,
    id: props.data.id,
    state_number: liscensePlate.value,
    transporter_id: organisationSelectItemActive.value.id
  })

  if (sendForm.status === "successful"){
    store.dispatch('setGlobalPreloader', false)
    emit('updateKppList')
    emit('showNotification', 'Пропуск успешно отредактирован')
    emit('close')
  }
}

const validate = computed(() =>
  liscensePlate.value.length >= 8 &&
  organisationSelectItemActive.value.id &&
  clientItemActive.value.id &&
  comment.value.length
)

const maskVal = computed(() => Number(liscensePlate.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>

<style scoped lang="scss">
.content{
  padding: 36px;
  width: 348px;
  .form-content{
    display: flex;
    justify-content: space-between;
  }

  &__left{
    width: 50%;
    margin-right: 12px;
  }

  &__right{
    width: 50%;
    margin-left: 12px;
  }
  .form-item{
    margin-bottom: 24px;

    &--btn{
      padding-top: 10px;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }
}

.bottom-row{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  &__btn{
    width: 275px;
  }
  span{
    font-size: 16px;
    opacity: .5;
  }
}
</style>
