<template>
  <div class="page">

    <div class="container">

      <router-link
        to="/kpp/incidents"
        class="inc"
      >
        <div class="incident-btn">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8466 4.0317L15.7427 12.1919C16.5925 13.6083 15.5723 15.4102 13.9205 15.4102H4.12828C2.47653 15.4102 1.45629 13.6083 2.30611 12.1919L7.20224 4.0317C8.0276 2.6561 10.0212 2.6561 10.8466 4.0317ZM9.56035 4.80344C9.31759 4.39885 8.73123 4.39885 8.48848 4.80344L3.59235 12.9637C3.3424 13.3802 3.64247 13.9102 4.12828 13.9102H13.9205C14.4064 13.9102 14.7064 13.3802 14.4565 12.9637L9.56035 4.80344Z" fill="#404040"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.02441 7.16022C9.43863 7.16022 9.77441 7.496 9.77441 7.91022V9.91022C9.77441 10.3244 9.43863 10.6602 9.02441 10.6602C8.6102 10.6602 8.27441 10.3244 8.27441 9.91022V7.91022C8.27441 7.496 8.6102 7.16022 9.02441 7.16022Z" fill="#404040"/>
            <path d="M9.77441 12.1602C9.77441 12.5744 9.43863 12.9102 9.02441 12.9102C8.6102 12.9102 8.27441 12.5744 8.27441 12.1602C8.27441 11.746 8.6102 11.4102 9.02441 11.4102C9.43863 11.4102 9.77441 11.746 9.77441 12.1602Z" fill="#404040"/>
          </svg>
          <span>Инциденты</span>
        </div>
      </router-link>
    </div>


    <q-scroll-up-button :quantityPaginationPage="quantityPaginationPage" />

    <q-acts-preloader v-if="preloader" />

    <q-empty-content v-if="notFoundMessage" :message="notFoundMessage" />

    <transition name="default">
      <div v-if="!preloader && !isShowIncidents" class="container">
        <q-acts-item v-for="act in acts"
          :key="act.id"
          :data="act"
          @updateActStatus="updateActStatus"
          @updateActsList="getActs"
          @showNotification="notify({ title: $event, type: 'success' })"
          :waste="currentWaste"
          kpp
          @updateKppList="getActs"
        />
      </div>
    </transition>

    <v-pagination
      v-if="quantityPaginationPage > 1"
      v-model="currentPaginationPage"
      :pages="quantityPaginationPage"
      :range-size="1"
      @update:modelValue="updateHandler"
    />

    <q-bottom-panel>
      <QActsFilters
        @setCarNumber="setCarNumber"
        @setTransporter="setTransporter"
        @setClient="setClient"
        @setPolygon="setPolygon"
        @setSortType="setSortType"
        @setFilteredDate="setFilteredDate"
        @setTemporaryActNumber="setTemporaryActNumber($event)"
        @downloadExcel="downloadExcel"
        :tonnage="tonnage"
        :weights="weights"
        :passes="acts.length"
        :numDispute="numDispute"
        :tonnageDispute="tonnageDispute"
        :wasteCategory="switcherSelectItems.length ? currentWaste.id : ''"
        kpp
      />
    </q-bottom-panel>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch, computed } from 'vue'
import moment from 'moment'
import store from '@/store'
import useRouterGuard from '@/composables/useRouterGuard'
import useApi from '@/composables/useApi'
import QActsItem from '@/views/acts/QActsItem.vue'
import QActsFilters from '@/views/acts/QActsFilters.vue'
import QActsPreloader from '@/views/acts/QActsPreloader.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue'
import QEmptyContent from '@/components/common/QEmptyContent.vue'
import VPagination from '@hennge/vue3-pagination'
import QScrollUpButton from '@/components/common/QScrollUpButton.vue'
import { notify } from '@kyvg/vue3-notification'
import QWasteSwitcher from '@/components/ui/QWasteSwitcher.vue'
import { useRouter } from 'vue-router'


const router = useRouter()

const preloader = ref(false)
const acts = ref([])
const tonnage = ref(0)
const weights = ref(0)
const numDispute = ref(0)
const tonnageDispute = ref(0)
const notFoundMessage = ref('')

const isShowIncidents = ref(false)


onMounted(async () => {
  if (store.state.user.role === 4) {
    router.push('/counterparties')
  }

  date.value = [moment().utc(moment()._d), moment().utc(moment()._d)]
  await useRouterGuard()
  await getSwitchers()
  preloader.value = true
})

const carNumber = ref('')
const transporter = ref('')
const client = ref('')
const polygon = ref('')
const sortType = ref('date_desc')
const date = ref(['', ''])

const isDirtyFields = ref(false)

const quantityPaginationPage = ref(1)
const currentPaginationPage = ref(1)

const temporaryActNumber = ref(null)

const currentWasteType = ref('')
const currentWasteCategory = ref('')

watch(
  () => quantityPaginationPage.value,
  (val) => currentPaginationPage.value = 1
)

const getActs = async (dispute = false) => {
  store.dispatch('setGlobalPreloader', true)
  acts.value = []
  preloader.value = true
  notFoundMessage.value = ''

  const actsData = await useApi('post', 'passes/by_filters', {
    car_number: carNumber.value,
    client: client.value,
    current_page: isDirtyFields.value ? 1 : currentPaginationPage.value,
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    sorting_by: sortType.value,
    transporter_name: transporter.value,
  })

  if (actsData.status === 'error') {
    notFoundMessage.value = actsData.error.message
  }

  acts.value = actsData.data.passes
  quantityPaginationPage.value = actsData.pages

  preloader.value = false

  isDirtyFields.value = false
  store.dispatch('setGlobalPreloader', false)

  temporaryActNumber.value = null
}

const setTemporaryActNumber = e => {
  temporaryActNumber.value = e
  getActs()
}


const switcherSelectItems = ref([])

const currentWaste = ref({
  id: 'tko',
  label: 'ТКО'
})
watch(
  () => currentWaste.value,
  (val) => getActs()
)

const getSwitchers = async () => {
  const res = await useApi('get', 'waste/get_info')

  if (res.waste.length) {
    switcherSelectItems.value = res.waste
    currentWaste.value = switcherSelectItems.value[0]
  }
  getActs()
}

const updateHandler = (e) => {
  currentPaginationPage.value = e
  getActs()
}

const setSortType = e => {
  isDirtyFields.value = true
  sortType.value = e.id
  getActs()
}

const setPolygon = e => {
  polygon.value = e
  isDirtyFields.value = true
  getActs()
}

const setTransporter = e => {
  transporter.value = e
  isDirtyFields.value = true
  getActs()
}

const setClient = e => {
  client.value = e
  isDirtyFields.value = true
  getActs()
}

const setCarNumber = e => {
  carNumber.value = e
  isDirtyFields.value = true
  getActs()
}

const setFilteredDate = e => {
  isDirtyFields.value = true
  date.value = [moment(e[0]).utc(e[0]), moment(e[1]).utc(e[1])]
  getActs()
}

const updateActStatus = id => {
  acts.value[acts.value.findIndex(item => item.id === id)].dispute = !acts.value[acts.value.findIndex(item => item.id === id)].dispute
  setTimeout(() => getActs(), 1000)
}

const downloadExcel = async () => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'acts/get_excel', {
    car_number: carNumber.value,
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    platform_name: polygon.value,
    transporter_name: transporter.value,
    waste_category: currentWasteCategory.value,
    waste_type: currentWasteType.value,
    client: client.value,
  })

  if (req.docs) {
    window.open(req.docs, '_self');
  }
  store.dispatch('setGlobalPreloader', false)
}


const setSwitcherValue = item => {
  if (item.category_id) {
    currentWasteType.value = item.id
    currentWasteCategory.value = item.category_id
  } else {
    currentWasteType.value = ''
    currentWasteCategory.value = item.id
  }
}
</script>

<style scoped lang="scss">
.page {
  padding-bottom: 150px;
}

.q-acts-switcher {
  position: relative;
  width: 200px;
}

.incident-btn{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #E0E0E0;
  width: fit-content;
  border-radius: 14px;
  cursor: pointer;
  span{
    font-size: 16px;
  }
}

.inc{
  text-decoration: none;
  margin-bottom: 15px;
  display: block;
}

.incident-btn-back{
  cursor: pointer;
}
</style>
