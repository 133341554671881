<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5>Добавление категории груза</h5>

      <div class="form-item">
        <label>Категория груза</label>
        <q-input
          placeholder="Введите категорию"
          type="text"
          :white="true"
          v-model="form.label"
          :error="v$.label.$errors.length ? 'Заполните поле' : ''"
        />
      </div>

      <div class="form-item form-item--btn">
        <q-button
          label="Сохранить"
          view="secondary"
          @clicked="submitForm"
        />
      </div>

      <q-button
        label="Сохранить и добавить виды"
        view="primary"
        @clicked="submitForm(1)"
      />
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, reactive } from 'vue'
import useApi from '@/composables/useApi'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import store from '@/store'

const emit = defineEmits(['close', 'successSended'])

const form = reactive({
  label: ''
})

const validShema = ref({
  label: { required },
})

const v$ = useVuelidate(validShema, form)

const submitForm = async(isAddType) => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }
  const send = await useApi('post', `waste/category_add`, form)

  if(send.status === 'success'){
    emit('successSended', isAddType ? send.category : null, form.label)
  }
}


</script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 24px;
}

.form-item--btn{
  margin-bottom: 10px;
  margin-top: 32px;
}
</style>
