<template>
  <div class="info box">
    <div class="item">
      <div class="item__top">
        <div>
          <p>Фактический тоннаж</p>
          <div class="summ">{{ data.tonnage }} т.</div>
        </div>
        <div class="label">
          <span>Прогноз на <br /> конец {{ periodType === 'month' ? 'месяца' : 'года' }}</span>
          <span>{{ data.tonnage_prognosis }} т.</span>
        </div>
      </div>
      <div class="item__bottom">
        <span>Выполнение тер.схемы:</span>
        <div class="analyt-summ">
          <p>{{ data.tonnage_scheme }} т</p>
          <div :style="`background: ${Number(data.tonnage_realization.slice(0, -1)) > 100 ? '#B2EAFC' : '#B2EAFC'}`">{{ data.tonnage_realization }} </div>
        </div>
      </div>
    </div>
    <div class="info__separator"></div>
    <div class="item">
      <div class="item__top">
        <div>
          <p>Объём начислений</p>
          <div class="summ">{{ data.summ }} ₽</div>
        </div>
        <div class="label">
          <span> Прогноз на <br />конец {{ periodType === 'month' ? 'месяца' : 'года' }}</span
          >
          <span>{{ data.summ_prognosis }} ₽</span>
        </div>
      </div>
      <div class="item__bottom">
        <span class="info-row">
          <span>
            Сравнение с аналогич. периодом

          </span>

          <span class="hint">
            <img src="@/assets/images/info-icon.svg"/>
            <p> {{ periodTime }}</p>
          </span>
        </span>

        <div class="analyt-summ">
          <p>
            <template v-if="data.summ_last !== '-'">{{data.summ_last}} ₽</template>
            <span v-else>Данных нет</span>
          </p>

          <div :style="`background: ${data.summ_percent.includes('-') ? '#A4F0AA' : '#ff6e63'}; color: ${data.summ_percent.includes('-') ? '#424242' : 'white'}`">
            {{ data.summ_percent !== '-' ? data.summ_percent : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, inject, computed } from "vue"
import moment from "moment"
import "moment/locale/ru"
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts"

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
})


const periodType = inject('periodType')
const yearValue = inject('yearValue')
const monthValue = inject('monthValue')

const currYear = new Date().getFullYear()
const currMonth = new Date().getMonth() + 1
const currDay = new Date().getDate()

const periodTime = computed(() => {
  if(periodType.value !== 'month'){
    return `01.01.${yearValue.value - 1} - 31.12.${yearValue.value - 1}`
  }
  if(currMonth === +monthValue.value && +yearValue.value === currYear){
    return `01.${currMonth < 10 ? String('0' + currMonth) : currMonth}.${currYear - 1} - ${currDay < 10 ? String('0' + currDay) : currDay}.${currMonth < 10 ? String('0' + currMonth) : currMonth}.${currYear - 1}`
  }
  else {
    return `01.${Number(monthValue.value) < 10 ? '0' + monthValue.value : monthValue.value}.${yearValue.value - 1} - ${33 - new Date(yearValue.value, monthValue.value - 1, 33).getDate()}.${Number(monthValue.value) < 10 ? '0' + monthValue.value : monthValue.value}.${yearValue.value - 1}`
  }
})
</script>

<style scoped lang="scss">
.box {
  padding: 23px 26px;
  border-radius: 22px;
  height: 150px;
  background: $ford-medium-dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    color: rgba(white, 0.6);
    font-size: 16px;
    line-height: 22px;
  }
  .summ {
    color: white;
    font-size: 30px;
    line-height: 35.94px;
    font-family: "TT Norms Pro Regular";
  }
  &__completeness {
    background: $caparol;
    color: $ford-medium-dark;
    margin: 0px 35px;
    width: 500px;
    p {
      color: $ford-medium-dark;
    }
    .summ {
      color: $ford-medium-dark;
    }
    .sceme {
      display: flex;
      align-items: center;
      &__title {
        white-space: nowrap;
        font-size: 16px;
      }
      &__bar {
        margin-left: 35px;
        background: white;
        height: 36px;
        width: -webkit-fill-available;
        border-radius: 14px;
        overflow: hidden;
        div {
          display: flex;
          justify-content: center;
          padding-left: 12px;
          align-items: center;
          height: 100%;
          background: $ford-tyrolean-blue;
          transition: ease .5s;
        }
      }
    }
    .tonnage {
      display: flex;
      justify-content: space-between;
      &__item {
        span {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 5%;
          font-family: "TT Norms Pro Regular";
        }
        p {
          font-size: 16px;
          padding-left: 20px;
          color: rgba($ford-medium-dark, 0.6);
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 6px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 3px;
            background: white;
          }
          &.blue {
            &:before {
              background: $ford-tyrolean-blue;
            }
          }
          &.red {
            &:before {
              background: $natural-color-system;
            }
          }
        }
      }
    }
  }
  &__warn {
    background: $caparol;
    color: $ford-medium-dark;
    font-size: 16px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: black;
      }
      span {
        width: 65px;
        height: 36px;
        background: $ford-medium-dark;
        color: white;
        margin-left: 27px;
        border-radius: 14px;
        font-size: 16px;
        padding: 7px 0;
        text-align: center;
      }
    }
  }
}


.info {
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  align-items: center;
  &__separator {
    border-radius: 29px;
    width: 2px;
    height: calc(100% - 36px);
    background: rgba(white, 0.1);
  }
  .item {
    padding: 16px 26px;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__top {
      display: flex;
      justify-content: space-between;
      .label {
        padding: 13px 16px;
        background: $davy;
        border-radius: 16px;
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: white;
          &:first-child {
            opacity: 0.6;
          }
          &:last-child {
            font-size: 18px;
            font-family: "TT Norms Pro Regular";
          }
        }
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: white;
      }
      .analyt-summ {
        display: flex;
        align-items: center;
        p {
          color: white;
          font-size: 20px;
          font-family: "TT Norms Pro Regular";
          display: flex;
          align-items: center;
        }
        div {
          color: $ford-medium-dark;
          border-radius: 14px;
          padding: 8px 17px;
          margin-left: 20px;
        }
      }
    }
  }
}

.hint{
  margin-left: 15px;
  height: 18px;
  position: relative;
  p{
    transition: ease .3s;
    opacity: 0;
    top: 24px;
    right: 0;
    padding: 8px 16px;
    position: absolute;
    background: linear-gradient(0deg, #393939, #393939), #FFE4D9;
    border-radius: 14px;
    font-size: 14px;
    font-family: "TT Norms Pro Medium";
    white-space: nowrap;
    color: white;
  }
  img{
    cursor: pointer;
    &:hover{
      ~p{
        opacity: 1;
      }
    }
  }
}

.info-row{
  display: flex;
  align-items: center;
}
</style>
