
<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">

      <h5 v-if="data">Редактирование заявки №{{data.number}}</h5>
      <h5 v-else>Создание заявки</h5>

      <div class="form-content">
        <div class="content__left">

          <div class="form-item">
            <label class="required">Гос. № ТС</label>
            <q-input-number
              v-model="number"
              placeholder="М 000 ММ 000"
              type="text"
              :search="searchedNumbers"
              @searchItemChoose="chooseNumber"
              license
              @closeSearchPanel="searchedNumbers = []"
              cross
              grey
              greybg
              :masked="maskVal"
              @onInput="inputNumbers"
            />
          </div>

          <div class="form-item">
            <label class="required">Перевозчик</label>
            <q-select
              :items="organisationSelectItems"
              @changeSelect="organisationSelectItemActive = $event"
              :selectedItem="organisationSelectItemActive"
              :maxHeight="145"
            />
          </div>

          <div class="form-item">
            <label>Брутто</label>
            <q-input
              placeholder="Введите вес в кг"
              type="text"
              :white="true"
              v-model="brutto"
              masked="inn"
            />
          </div>

          <div class="form-item">
            <label>Место вывоза</label>
            <q-input
              placeholder="Цех, помещение, место"
              type="text"
              :white="true"
              v-model="place"
            />
          </div>

          <div v-if="orderPolygonItemActive.id.length" class="form-item">
            <label>Категория груза</label>
            <q-select
              :items="orderWasteItems"
              @changeSelect="orderWasteItemActive = $event"
              :selectedItem="orderWasteItemActive"
              :maxHeight="145"
            />
          </div>

          <div class="form-item">
            <label>Срок действия заявки</label>
            <q-select
              :items="orderTimeItems"
              @changeSelect="orderTimeItemActive = $event"
              :selectedItem="orderTimeItemActive"
              :maxHeight="145"
            />
          </div>

        </div>
        <div class="content__right">

          <div class="form-item">
            <label>№ RFID метки</label>
            <q-input
              placeholder="0000"
              type="text"
              :disabled="false"
              :white="true"
              v-model="rfid"
              masked="rfid"
            />
          </div>

          <div class="form-item">
            <label class="required">Место назначения</label>
            <q-select
              :items="orderPolygonItems"
              @changeSelect="orderPolygonItemActive = $event"
              :selectedItem="orderPolygonItemActive"
              :maxHeight="145"
            />
          </div>

          <div class="form-item">
            <label>Должность ответсвенного</label>
            <q-input
              placeholder="Введите должность"
              type="text"
              :white="true"
              v-model="jobTitle"
            />
          </div>

          <div class="form-item">
            <label>ФИО ответсвенного</label>
            <q-input
              placeholder="Введите ФИО"
              type="text"
              :white="true"
              v-model="fullName"
            />
          </div>

          <div v-if="orderPolygonItemActive.id.length" class="form-item">
            <label>Вид груза</label>
            <q-select
              :items="orderWasteTypeItems"
              @changeSelect="orderWasteTypeItemActive = $event"
              :selectedItem="orderWasteTypeItemActive"
              :maxHeight="145"
            />
          </div>



        </div>
      </div>

      <div class="bottom-row">
        <span>
          <p v-if="data">{{data.created_at !== data.updated_at ? `Отредактировано: ${moment(data.updated_at).format('DD.MM.YYYY')}` : `Создано: ${moment(data.created_at).format('DD.MM.YYYY')}`}}</p>
        </span>

        <div class="bottom-row__btn">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="submitForm"
            :disabled="!validate"
          />
        </div>
      </div>
    </form>

  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, watch } from 'vue'
import QNotification from '@/components/ui/QNotification.vue';
import useApi from '@/composables/useApi'
import moment from 'moment'
import store from '@/store'
import QSearchSelect from '@/components/ui/QSearchSelect.vue'


const props = defineProps({
  data: {
    type: Object,
  },
})

const emit = defineEmits(['successSendOrder', 'close'])

const number = ref('');
const searchedNumbers = ref([]);

const maskVal = computed(() => Number(number.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')

const inputNumbers = async(e) => {
  if(e){
    const filtNum = await useApi('get', `filters/find_state_number/${e}/tko`)
    searchedNumbers.value = filtNum.cars
  } else {
    searchedNumbers.value = []
  }
}

const chooseNumber = item => {
  number.value = item.label;
  searchedNumbers.value = [];
}


const brutto = ref('')
const place = ref('')
const jobTitle = ref('')
const fullName = ref('')
const rfid = ref('')

const organisationSelectItems = ref([]);
const organisationSelectItemActive = ref({id: '', label: 'Не выбрано'});

const date = computed(()=> {
  if(props.data) return moment(props.data.date_update).utc().format('DD.MM.YYYY')
  return ''
})


const orderTimeItems = ref([
  {
    id: '2',
    label: '2 часа'
  },
  {
    id: '3',
    label: '3 часа'
  },
  {
    id: '4',
    label: '4 часа'
  },
  {
    id: '6',
    label: '6 часов'
  },
  {
    id: '8',
    label: '8 часов'
  },
]);
const orderTimeItemActive = ref({
  id: '2',
  label: '2 часа'
});

const orderWasteItems = ref([])
const orderWasteItemActive = ref({
  id: '',
  label: 'Не выбрано'
})

const orderWasteTypeItems = ref([])
const orderWasteTypeItemActive = ref({
  id: '',
  label: 'Не выбрано'
})

const getOrganisations = async() => {
  const transportersData = await useApi('get', `company/get_transporters/tko`)

  organisationSelectItems.value = transportersData.transporters.map(function (item) {
    return {
      label: `${item.label} ${item.description ? ' ('+item.description+')' : '' }`,
      id: item.id,
    };
  });
};

const getPlatforms = async () => {
  const platformsListData = await useApi('get', 'analitics/get_platforms_list')
  orderPolygonItems.value = platformsListData.platforms_list

  // if(orderPolygonItems.value.length){
  //   orderPolygonItemActive.value = orderPolygonItems.value[0]
  // }
}


const orderPolygonItems = ref([])
const orderPolygonItemActive = ref({id: '', label: 'Не выбрано'});

onMounted(async ()=> {
  await getOrganisations()
  await getPlatforms()

  if(props.data){
    number.value = props.data.car.state_number ? props.data.car.state_number : ''
    brutto.value = props.data.brutto_row ? props.data.brutto_row : ''
    rfid.value = props.data.car.rfid ? props.data.car && props.data.car.rfid : props.data.car.rfid
    organisationSelectItemActive.value = props.data.transporter ? {id: props.data.transporter.id, label: props.data.transporter.name } : {id: '', label: 'Не выбрано'}
    orderPolygonItemActive.value = props.data.platform ? {id: props.data.platform.id, label: props.data.platform.name } : {id: '', label: 'Не выбрано'}
    orderTimeItemActive.value = orderTimeItems.value.find(item => item.id === props.data.interval)

    place.value = props.data.place
    jobTitle.value = props.data.job_title
    fullName.value = props.data.full_name
  }
})


const submitForm = async() => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }


  const payload = {
    brutto: brutto.value,
    state_number: number.value,
    platform_id: orderPolygonItemActive.value.id,
    rfid: rfid.value,
    time_interval: orderTimeItemActive.value.id,
    transporter_id: organisationSelectItemActive.value.id,
    place: place.value,
    job_title: jobTitle.value,
    full_name: fullName.value,
    waste_category_id: orderWasteItemActive.value.id,
    waste_type_id: orderWasteTypeItemActive.value.id
  }

  if(props.data){
    payload.id = props.data.id
  }

  store.dispatch('setGlobalPreloader', true)

  const sendForm = await useApi('post', `bids/${props.data ? 'update' : 'create'}_bid`, payload)

  if (sendForm.bid_id || sendForm.status === "success"){
    emit('successSendOrder')
  }
  store.dispatch('setGlobalPreloader', false)
}

const validate = computed(() => number.value.length >= 8 && organisationSelectItemActive.value.id !== '' && orderPolygonItemActive.value.id !== '')


watch(
  () => orderPolygonItemActive.value,
  (val) => {
    getWasteTypes(val.id)
  }
)

watch(
  () => orderWasteItemActive.value,
  (val) => {
    orderWasteTypeItems.value = orderWasteItemActive.value.waste_type_selector
    orderWasteTypeItemActive.value = orderWasteTypeItems.value[0]
  }
)


const getWasteTypes = async(id) => {
  const sendForm = await useApi('get', `waste/client/get_info_by_platform_id/${id}`)
  orderWasteItems.value = sendForm.waste
  orderWasteItemActive.value = orderWasteItems.value[0]
}
</script>

<style scoped lang="scss">
.content{
  width: 648px;
  padding: 36px;
  .form-content{
    display: flex;
    justify-content: space-between;
    gap: 0 24px;
  }

  &__left{
    width: 50%;
  }

  &__right{
    width: 50%;
  }
  .form-item{
    margin-bottom: 24px;
    max-width: 276px;

    &--btn{
      padding-top: 10px;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }
}

.bottom-row{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  &__btn{
    width: 275px;
  }
  span{
    font-size: 16px;
    opacity: .5;
  }
}
</style>
