<template>
  <div class="page">
    <teleport to="#header__left-teleport-container">
      <button class="add-new" @click="isShowModalAddDistrict = true">
        <img src="@/assets/images/add.svg" />
        Добавить
      </button>
    </teleport>
    <teleport to="#header__right-teleport-container">
      <div class="scheme-tonnage">
        <span>Тоннаж по тер. схеме:</span> {{scheme}} т
      </div>
    </teleport>

    <div class="list container">

      <q-district-item
        v-for="item in sortedArr"
        :key="item.id"
        :data="item"
        @updateDistrictsList="getDistricts"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </div>

    <q-bottom-panel :isFixed="true" />

    <transition name="default">
      <q-modal-district
        v-if="isShowModalAddDistrict"
        @close="isShowModalAddDistrict = false"
        @updateDistrictsList="getDistricts"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter, useRoute } from "vue-router";
import store from '@/store'
import useApi from '@/composables/useApi'
import useRouterGuard from '@/composables/useRouterGuard'
import QBottomPanel from '@/components/common/QBottomPanel.vue';
import QDistrictItem from './QDistrictItem.vue'
import QModalDistrict from '@/components/modals/QModalDistrict.vue'
import { notify } from '@kyvg/vue3-notification'

const router = useRouter();

onMounted(async ()=>{
  await useRouterGuard()
  if(store.state.user.role !== 1) router.push("acts")
  await getDistricts()
})

const isShowModalAddDistrict = ref(false)

const districtsList = ref([])

const sortedArr = computed(()=> {
  return districtsList.value.sort((a,b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
    return 0
  })
})

const scheme = ref(0)

const getDistricts = async() => {
  const distrsData = await useApi('post', 'distrs/find_distrs', {
    distr_name: ''
  })
  districtsList.value = distrsData.distrs
  scheme.value = distrsData.tonnage_scheme
}

</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.scheme-tonnage{
  background: white;
  padding: 8px 16px;
  border-radius: 14px;
  span{
    opacity: .5;
    margin-right: 10px;
  }
}

.district-item{
  width: calc(50% - 10px);
  background: white;
  padding: 14px 24px;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__name{
    font-size: 16px;
    margin-bottom: 5px;
    max-width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__scheme{
    background: #F4F4F4;
    border-radius: 12px;
    padding: 5px 16px;
    span{
      opacity: .5;
      margin-right: 10px;
    }
  }
  &__right{
    display: flex;
    align-items: center;
    img{
      margin-left: 22px;
      cursor: pointer;
    }
  }
}

.add-new{
  margin-left: 35px;
}
</style>
