<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5>
        <template v-if="stepOne">Проверьте почту</template>
        <template v-if="!stepOne">Редактирование пароля</template>
      </h5>

      <template v-if="stepOne">
        <div class="description">Мы выслали на ваш адрес электронной почты письмо. Для изменения пароля перейдите по ссылке в письме</div>

        <div class="form-item">
          <label>Код из письма</label>

          <q-input
            placeholder="Введите 4-х значный код "
            type="number"
            :white="true"
            v-model="code"
            masked="rfid"
            :error="codeError"
          />
        </div>
        <div class="form-item form-item--btn">
          <q-button
            label="Далее"
            view="secondary"
            @clicked="submitCode"
          />
        </div>

      </template>

      <template v-if="!stepOne">
        <div class="form-item">
          <label>Новый пароль</label>

          <q-input
            placeholder="Введите Новый пароль"
            type="password"
            :white="true"
            v-model="form.new_password"
            :error="v$.new_password.$errors.length ? 'Заполните поле' : ''"
          />

        </div>
        <div class="form-item">
          <label>Повторите новый пароль</label>

          <q-input
            placeholder="Введите пароль еще раз"
            type="password"
            :white="true"
            v-model="form.repeat_password"
            :error="v$.repeat_password.$errors.length ? 'Пароли не совпадают' : ''"
          />


        </div>
        <div class="form-item form-item--btn">
          <q-button
            label="Далее"
            view="secondary"
            @clicked="sendNewPassword"
          />
        </div>
      </template>
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, reactive } from 'vue'
import useApi from '@/composables/useApi'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import store from '@/store'

const emit = defineEmits(['close', 'showNotification'])

const stepOne = ref(true)


onMounted(async()=> {
  const sendCode = await useApi('post', `settings/password_update_request`, {})
})

const code = ref('')
const codeError = ref('')

const submitCode = async() => {
  codeError.value = ''

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  const send = await useApi('post', `settings/password_update_check`, {code: code.value})

  if(send.status === 'error'){
    if(send.msg){
      codeError.value = send.msg
    }
  }
  else if(send.status === 'success'){
    stepOne.value = false
  }
}

const form = reactive({
  new_password: '',
  repeat_password: ''
})

const newPass = computed(()=> form.new_password)

const validShema = ref({
  new_password: { required },
  repeat_password:  {v: sameAs(newPass)},
})

const v$ = useVuelidate(validShema, form)

const sendNewPassword = async() => {
  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }

  const send = await useApi('post', `settings/password_update_set`, form)
  if(send.status === 'success'){
    emit('showNotification', 'Пароль успешно изменен!')
    emit('close')
  }
}

</script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 24px;
}

.form-item{
  margin-bottom: 24px;
  &--btn{
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.description{
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;

  color: rgba(66, 66, 66, 0.5);
}

</style>
