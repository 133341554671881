<template>
  <div class="page">
    <q-counterparties-preloader v-if="preloader"/>

    <q-empty-content
      v-if="notFoundMessage"
      size="small"
      :message="notFoundMessage"
    />
    <transition name="default">
      <div class="list container" v-if="!preloader">
        <QСounterpartiesItem
          v-for="item in counterpartiesList"
          :key="item.id"
          :data="item"
          @changedData="changedData"
          :large="item.role !== 3"
          :allDistricts="districtsList"
          @updateCounterpartiesList="getAgents"
          @showNotification="notify({ title: $event, type: 'success'})"
          @disableEnable="disableEnableItem"
        />

        <div class="separator-qp"></div>

        <QСounterpartiesItem
          v-for="item in counterpartiesList"
          :key="item.id"
          :data="item"
          @changedData="changedData"
          @updateCounterpartiesList="getAgents"
          :large="item.role === 3"
          @showNotification="notify({ title: $event, type: 'success'})"
          @disableEnable="disableEnableItem"
        />
      </div>
    </transition>

    <teleport to="#header__left-teleport-container">
      <div
        class="header-btn"
        v-if="$store.state.user.role === 1 || $store.state.user.role === 6"
      >
        <button
          class="add-new"
          @click="isOpenHeaderMenu = true"
        >
          <img src="@/assets/images/add.svg" alt="">
          Добавить
        </button>

        <transition name="default">
          <ul
            class="header-btn__menu"
            v-if="isOpenHeaderMenu"
            ref="target"
          >
            <li @click="openModalAgent">Перевозчика</li>
            <li @click="openModalPolygonTKO">Площадку</li>
          </ul>
        </transition>
      </div>

      <div
        class="header-btn"
        v-if="$store.state.user.role === 2"
      >
        <button
          class="add-new"
          @click="isShowModalAddAgent = true"
        >
          <img src="@/assets/images/add.svg" alt="">
          Добавить
        </button>
      </div>
    </teleport>

    <teleport to="#header__right-teleport-container"></teleport>

    <transition name="default">
      <QModalCounterparties
        @updateCounterpartiesList="getAgents"
        v-if="isShowModalAddAgent"
        @close="isShowModalAddAgent = false"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>

    <transition name="default">
      <QModalPolygonTKO
        v-if="isShowModalPolygonTKO"
        @close="isShowModalPolygonTKO = false"
        @updateCounterpartiesList="getAgents"
        :allDistricts="districtsList"
        :data="null"
        @showNotification="notify({ title: $event, type: 'success'})"
      />
    </transition>

    <q-bottom-panel>
      <QCounterpartiesFilters
        @setStatus="setStatus"
        @setType="setType"
        @setTransporter="setTransporter"
      />
    </q-bottom-panel>

    <q-scroll-up-button :quantityPaginationPage="quantityPaginationPage"/>

    <v-pagination
      v-if="quantityPaginationPage > 1"
      v-model="currentPaginationPage"
      :pages="quantityPaginationPage"
      :range-size="1"
      @update:modelValue="updateHandler"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, provide } from 'vue';
import useApi from '@/composables/useApi'
import store from '@/store'
import QSwitcher from '@/components/ui/QSwitcher.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue';
import QСounterpartiesItem from './QСounterpartiesItem.vue';
import useRouterGuard from '@/composables/useRouterGuard'
import QModalCounterparties from '@/components/modals/QModalCounterparties.vue'
import QModalPolygonTKO from '@/components/modals/QModalPolygonTKO.vue'
import QCounterpartiesPreloader from './QCounterpartiesPreloader.vue'
import QCounterpartiesFilters from './QCounterpartiesFilters.vue'
import QEmptyContent from '@/components/common/QEmptyContent.vue'
import { onClickOutside } from '@vueuse/core'
import { notify } from '@kyvg/vue3-notification'
import VPagination from '@hennge/vue3-pagination'
import QScrollUpButton from '@/components/common/QScrollUpButton.vue'

onMounted(()=> {
  useRouterGuard()
  getDistricts()
  getAgents()
})


const preloader = ref(true)

const isShowModalAddAgent = ref(false)
const isShowModalPolygonTKO = ref(false)
const isShowModalAddDistrict = ref(false)

const counterpartiesList = ref([])
const districtsList = ref([])

const notFoundMessage = ref('')

const status = ref('true')
const type = ref('')
const transporter = ref('')

const isOpenHeaderMenu = ref(false)
const target = ref(null)
onClickOutside(target, (event) => isOpenHeaderMenu.value = false)

const openModalAgent = () => {
  isShowModalAddAgent.value = true
  isOpenHeaderMenu.value = false
}

const openModalPolygonTKO = () => {
  isShowModalPolygonTKO.value = true
  isOpenHeaderMenu.value = false
}

const getAgents = async() => {
  preloader.value = true

  const agentsData = await useApi('post', 'counterparties/get_counterparties', {
    company_name: transporter.value,
    status: status.value,
    type: type.value,
    current_page: currentPaginationPage.value,
  })

  notFoundMessage.value = ''
  if(agentsData.message) {
    notFoundMessage.value = agentsData.message.text
  }
  preloader.value = false
  counterpartiesList.value = agentsData.counterparties

  quantityPaginationPage.value = agentsData.pages
}

watch(
  () => store.state.user.role,
  () => getAgents()
)

provide('company_name', transporter.value)
provide('status', status.value)
provide('type', type.value)

const disableEnableItem = id => {
  counterpartiesList.value[counterpartiesList.value.findIndex(item => item.id === id)].status = !counterpartiesList.value[counterpartiesList.value.findIndex(item => item.id === id)].status
  setTimeout(()=> getAgents(), 1000)
}

const changedData = (data) => {
  counterpartiesList.value[counterpartiesList.value.findIndex(item => item.id === data.id)] = data
  isShowModalAddAgent.value = false
  notify({ title: 'Данные успешно изменены', type: 'success'})
}

const setStatus = e => {
  status.value = e
  getAgents()
}

const setType = e => {
  type.value = e
  getAgents()
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13) getAgents()
});

const setTransporter = e => {
  transporter.value = e
  getAgents()
}


const getDistricts = async() => {
  const distrsData = await useApi('post', 'distrs/find_distrs', {
    distr_name: ''
  })
  districtsList.value = distrsData.distrs
}

const quantityPaginationPage = ref(1)
const currentPaginationPage = ref(1)

watch(
  () => quantityPaginationPage.value,
  (val) => currentPaginationPage.value = 1
)

const updateHandler = (e) => {
  currentPaginationPage.value = e
  getAgents()
}
</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-btn{
  margin-left: 35px;
  position: relative;

  &__menu{
    position: absolute;
    top: 42px;
    left: 0;
    background: white;
    padding: 16px 8px;
    border-radius: 22px;
    width: 210px;
    box-shadow:0px 0px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 2px 0;
    li{
      font-size: 16px;
      padding: 8px 10px;
      transition: ease .2s;
      cursor: pointer;
      border-radius: 14px;
      &:hover{
        background: rgba(85, 85, 85, 0.1);
      }
    }
  }
}
.separator-qp{
  width: 100%;
}
.switcher{
  width: 200px;
}
</style>