<template>
  <div class="q-analytics-preloader container">
    <img src="@/assets/images/preloader-analytic.svg">
  </div>
</template>

<style scoped lang="scss">
.q-preloader-analytic{
  width: 1225px;
  position: fixed;
  left: 50%;
  transform: translateX(-52%);
  top: 120px;
  img{
    margin-bottom: 18px;
    animation-name: blink;
    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
}

@keyframes blink {
  0% { opacity: .5; }
  100% { opacity: 1; }
}
</style>