<template>
  <div class="page">
    <transition name="default">
      <q-analytics-period-date
        @changePeriod="periodType = $event"
        @changeMonth="monthValue = $event"
        @changeYear="yearValue = $event"

        :year="yearValue"
        :month="periodType === 'month' ? monthValue : ''"
        v-if="generalInfo && commonAnaliticData"
      />
    </transition>

    <transition name="default">
      <div class="container" v-if="generalInfo && commonAnaliticData">
        <q-analytics-general-info :data="generalInfo" />

        <q-analytics-polygons
          :data="commonAnaliticData.platforms_info"
          :polygonsPeriodData="commonAnaliticData.period_info"
          :polygonChartTonnage="polygonChartTonnage"
          :polygonChartSumm="polygonChartSumm"
          :month="monthValue"
          :year="yearValue"
        />

        <q-analytics-waste-info :data="commonAnaliticData.waste_distribution"/>

        <q-analytics-districts
          :data="commonAnaliticData.distrs_info"
          :districtsChartTonnage="districtsChartTonnage"
          :month="monthValue"
          :year="yearValue"
        />

        <q-analytics-transporters
          :data="commonAnaliticData.transporters"
          :transportersChartTonnage="transportersChartTonnage"
          @getCommonAnaliticData="getCommonAnaliticData"
          @setTransportNumber="transportNumber = $event"
          @setPolygonName="platformName = $event"
          :month="monthValue"
          :year="yearValue"
        />
      </div>
    </transition>

    <q-analytics-preloader v-if="!generalInfo && !commonAnaliticData" />

    <q-bottom-panel :isFixed="true" />
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, provide, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import store from "@/store"
import useApi from '@/composables/useApi'
import useRouterGuard from "@/composables/useRouterGuard";
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts";
import QAnalyticsPeriodDate from './QAnalyticsPeriodDate.vue'
import QAnalyticsPreloader from "./QAnalyticsPreloader.vue";
import QBottomPanel from "@/components/common/QBottomPanel.vue";
import QAnalyticsGeneralInfo from './AnalyticsGeneralInfo'
import QAnalyticsPolygonInfo from './AnalyticsPolygonInfo'
import QAnalyticsWasteInfo from './AnalyticsWasteInfo'
import QAnalyticsDistricts from './AnalyticsDistricts'
import QAnalyticsPolygons from './AnalyticsPolygons'
import QAnalyticsTransporters from './AnalyticsTransporters'

const emit = defineEmits(['setCarNumber'])

const router = useRouter();
const generalInfo = ref(null)

const periodType = ref('month')
provide('periodType', periodType)

const yearValue = ref('')
provide('yearValue', yearValue)

const monthValue = ref('')
provide('monthValue', monthValue)

const transportNumber = ref('')
const platformName = ref('')

const districtsChartTonnage = ref([])
const polygonChartTonnage = ref([])
const polygonChartSumm = ref([])
const transportersChartTonnage = ref([])

const flagNumberTransporter = ref(false)
provide('flagNumberTransporter', flagNumberTransporter)

onMounted(async () => {
  await useRouterGuard()
  if(store.state.user.role !== 1){
    router.push("acts")
    return
  }

  store.dispatch('setGlobalPreloader', true)

  let date = new Date()
  yearValue.value = String(date.getFullYear())
  monthValue.value = String(date.getMonth() + 1)

  await getGeneralInfo()
  await getCommonAnaliticData()

  store.dispatch('setGlobalPreloader', false)
})

watch([periodType, monthValue, yearValue], () => getCommonAnaliticData())


const getGeneralInfo = async () => {
  const generalInfoData = await useApi('get', 'analitics/get_main_analitics')
  generalInfo.value = generalInfoData.summ_main
}

const commonAnaliticData = ref(null)

const getCommonAnaliticData = async()=> {
  store.dispatch('setGlobalPreloader', true)

  const commonInfoData = await useApi('post', 'analitics/get_period_info', {
    car_number: transportNumber.value,
    month_value: monthValue.value,
    period_type: periodType.value,
    platform_name: platformName.value === "Выберите значение" ? "" : platformName.value,
    year_value: yearValue.value
  })

  commonAnaliticData.value = commonInfoData

  flagNumberTransporter.value = transportNumber.value !== ''

  polygonChartTonnage.value = [{
    name: "Тоннаж",
    data: commonInfoData.platforms_info.period.tonnage.map((item) =>
      item === "" ? null : Math.round(item)
    ),
  }]
  polygonChartSumm.value = [{
    name: "Сумма",
    data: commonInfoData.platforms_info.period.summ.map((item) =>
      item === "" ? null : Math.round(item)
    ),
  }]
  districtsChartTonnage.value = [{
    name: "Тоннаж",
    data: commonInfoData.distrs_info.period.tonnage.map((item) =>
      item === "" ? null : Math.round(item)
    ),
  }]
  transportersChartTonnage.value = [{
    name: "Тоннаж",
    data: commonInfoData.transporters.period.tonnage.map((item) =>
      item === "" ? null : Math.round(item)
    ),
  }]

  store.dispatch('setGlobalPreloader', false)
}
</script>

<style scoped lang="scss">
.page {
  padding-bottom: 150px;
  background: white;
}
</style>
