<template>
  <q-modal @close="$emit('close')">
    <form class="content" :class="{client: $store.state.user.role === 6 || !isStepSecond}" @submit.prevent="">
      <h5>{{data ? 'Редактирование' : 'Добавление'}} площадки</h5>

      <div class="form-content">
        <div class="district__create" v-if="$store.state.user.role !== 6 && isStepSecond">
          <q-button
            v-if="!isAddDistr"
            label="Добавить район из списка"
            view="green"
            @clicked="isAddDistr = true"
          />
          <q-button
            v-else
            label="Отмена"
            view="primary"
            @clicked="сancelDistrs"
          />
        </div>

        <div class="content__left">
          <div class="form-item" v-if="isStepSecond">
            <label>Объект</label>
            <q-select
              :items="selectItemsObj"
              @changeSelect="activeItemObj = $event"
              :selectedItem="activeItemObj"
            />
          </div>
          <div
            class="form-item"
            :class="{'form-item--no-margin': !isStepSecond}"
          >
            <label>ИНН</label>
            <q-input
              placeholder="Введите 10-12 цифр"
              type="text"
              :disabled="false"
              :white="true"
              v-model="inn"
              masked="inn"
              :error="inputErrorsInnKpp"
            />
            <transition name="default">
              <div class="dadata" v-if="daDataArr.length && isShowDaDataMenu">
                <div class="dadata-item"
                  v-for="item in daDataArr"
                  :key="item.inn"
                  @click="setDaData(item)"
                >
                  <span>{{item.label}}</span>
                  <span>ИНН {{item.inn}} / КПП {{item.kpp}}</span>
                </div>
              </div>
            </transition>
          </div>
          <div class="form-item" v-if="isStepSecond">
            <label>Название организации</label>
            <q-input
              placeholder="Управление благоустройства и очи..."
              type="text"
              :disabled="true"
              :white="true"
              v-model="name"
            />
          </div>
          <div class="form-item" v-if="isStepSecond">
            <label>КПП</label>
            <q-input
              placeholder="Введите 9 цифр"
              type="text"
              :disabled="true"
              :white="true"
              v-model="kpp"
              masked="kpp"
              :error="inputErrorsInnKpp"
            />
          </div>
          <div class="form-item" v-if="$store.state.user.role !== 6 && isStepSecond">
            <label>Тариф</label>
            <q-input
              placeholder="                                                                    ₽/т"
              type="number"
              :disabled="false"
              :white="true"
              v-model="tariff"
            />
          </div>

          <div class="form-item" v-if="$store.state.user.role === 6 && isStepSecond">
            <label>Описание(максимум 20 символов)</label>
            <q-input
              placeholder="Введите уточняющую информацию"
              type="text"
              :white="true"
              v-model="description"
            />
          </div>
        </div>

        <div class="content__right" v-if="isStepSecond">
          <div class="district">
            <div class="district__quantity">
              <label>Районы: </label>
              <span>{{seletedDistricts.length}}</span>
            </div>
            <div class="district__field">

              <div
                v-if="!seletedDistricts.length && !isAddDistr"
                class="district__empty"
              >
                Выберите районы, которые <br/> обслуживает площадка
              </div>

              <div class="district__list-selected">
                <div
                  class="district-item district-item--selected"
                  v-for="(item, idx) in seletedDistricts"
                  :key="item.id"
                >
                  <div class="district-item__name">{{ item.name }}</div>

                  <svg class="remove-btn" @click="deleteDistrict(idx)" width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="29" rx="11.5" stroke="#C6C6C6"/>
                    <g clip-path="url(#clip0_12500_104040)">
                    <path d="M25 15H20H15" stroke="#C6C6C6" stroke-width="2.5" stroke-linecap="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_12500_104040">
                    <rect width="14" height="14" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 27 8)"/>
                    </clipPath>
                  </defs>
                  </svg>
                </div>
              </div>

              <div
                class="district__list-all"
                v-if="isAddDistr"
              >
                <div
                  class="district-item"
                  v-for="item in allFilteredDistricts"
                  :key="item.id"
                >
                  <div class="district-item__name">{{ item.name }}</div>
                  <img
                    class="district-item__icon"
                    src="@/assets/images/add-icon.svg"
                    alt="добавить район"
                    @click="seletedDistricts.push(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bottom-row"
        v-if="isStepSecond"
      >
        <!-- <span>Дата добавления: 21.02.2021</span> -->

        <div class="bottom-row__btn">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="submitForm"
            :disabled="validate"
          />
        </div>
      </div>
    </form>

  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, watchEffect } from 'vue'
import QNotification from '@/components/ui/QNotification.vue';
import useApi from '@/composables/useApi'
import store from '@/store'


const props = defineProps({
  allDistricts: {
    type: Array,
    default: ()=>[],
  },
  data: {
    type: Object,
  }
})

const emit = defineEmits(['close', 'updateCounterpartiesList', 'showNotification'])

const clonedDistrs = ref([])
const inputErrorsInnKpp = ref('')

const name = ref('')
const inn = ref('')
const kpp = ref('')
const tariff = ref('')
const description = ref('')

const isStepSecond = ref(false)

onMounted(()=> {
  if(props.data){
    isStepSecond.value = true

    name.value = props.data.company_name
    inn.value = props.data.inn
    kpp.value = props.data.kpp
    tariff.value = props.data.tariff
    clonedDistrs.value = [...props.data.distrs]
    seletedDistricts.value = props.data.distrs
    description.value = props.data.description || ''

    if(props.data.platform_type){
      activeItemObj.value = selectItemsObj.value.find(item => item.id === props.data.platform_type)
    }
  }
})



const selectItemsObj = ref([
  {
    label: "Полигон",
    id: 1,
  },
  {
    label: "Сортировка",
    id: 2,
  },
  {
    label: "Полигон/Сортировка",
    id: 3,
  },
]);
const activeItemObj = ref({
  label: "Полигон",
  id: 1,
},);
const isAddDistr = ref(false)
const seletedDistricts = ref([])

const allFilteredDistricts = computed(()=> {
  return props.allDistricts.filter(item => {
    return !seletedDistricts.value.find(selIt => selIt.id === item.id)
  })
})

const deleteDistrict = idx => {
  seletedDistricts.value.splice(idx, 1)
}

const submitForm = async() => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  store.dispatch('setGlobalPreloader', true)
  inputErrorsInnKpp.value = ''

  const sendForm = await useApi('post', `counterparties/${props.data ? 'update': 'add'}_platform`, {
    id: props.data ? props.data.id : '',
    distrs_id: [...seletedDistricts.value.map(item => item.id)],
    inn: inn.value,
    kpp: kpp.value,
    name: name.value,
    role_id: activeItemObj.value.id,
    tariff: tariff.value,
    description: description.value
  })

  store.dispatch('setGlobalPreloader', false)

  if (sendForm.errors){
    inputErrorsInnKpp.value = 'Такая площадка уже существует'
    return
  }

  emit('updateCounterpartiesList')
  emit('close')
  emit('showNotification', props.data ? 'Площадка успешно отредактирована' : 'Площадка успешно добавлена')
}

const сancelDistrs = () => {
  isAddDistr.value = false
  seletedDistricts.value = clonedDistrs.value
}

const validate = computed(() => {
  if  ((inn.value.length === 10 || inn.value.length === 12) && kpp.value.length === 9 && name.value.length > 0){
    return false
  } else {
    return true
  }
})

const isINNselected = ref(false)
const isShowDaDataMenu = ref(false)

const setDaData = (x) => {
  name.value = x.label
  inn.value = x.inn
  kpp.value = x.kpp
  isShowDaDataMenu.value = false

  isStepSecond.value = true
}

const daDataArr = ref([])

const isFirstLoad = ref(true)

watchEffect(async() => {
  if(!isFirstLoad){
    name.value = ''
    kpp.value = ''
  }

  if(inn.value.length === 10 || inn.value.length === 12){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: inn.value,
        count: 300
      })
    }

    if(isFirstLoad.value && props.data){
      isFirstLoad.value = false
    } else {
      const data = await fetch(url, options)
      const dd = await data.json()

      daDataArr.value = dd.suggestions.map(item => {
        return {
          label: item.value,
          inn: item.data.inn,
          kpp: item.data.kpp
        }
      })
      isShowDaDataMenu.value = true
    }
  } else {
    daDataArr.value = []
  }
})

const getDaData = async() => {
  name.value = ''
  kpp.value = ''

  if(inn.value.length === 10 || inn.value.length === 12){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({query: inn.value})
    }
    const data = await fetch(url, options)
    const dd = await data.json()

    daDataArr.value = dd.suggestions.map(item => {
      return {
        label: item.value,
        inn: item.data.inn,
        kpp: item.data.kpp
      }
    })

    isShowDaDataMenu.value = true
  } else {
    daDataArr.value = []
  }
}
</script>

<style scoped lang="scss">
.content{
  width: 820px;
  padding: 36px;

  &.client, .step-first{
    width: 350px;
    .form-content > .content__right{
      display: none;
    }
  }

  .form-content{
    display: flex;
    justify-content: space-between;
  }

  &__left{
    width: 276px;
  }

  &__right{
    margin-left: 24px;
    flex: 1;
  }

  .district{
    height: 100%;
    position: relative;
    &__quantity{
      margin-bottom: 10px;
      display: flex;
      label{
        margin-bottom: 0;
      }
      span{
        margin-left: 4px;
      }
    }
    &__field{
      border-radius: 22px;
      background: $caparol;
      padding: 8px;
      height: 395px;
      overflow-y: auto;
    }
    &__empty{
      opacity: .3;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__create{
      position: absolute;
      right: 36px;
      top: 24px;
      width: 276px;
    }
  }

  .form-item{
    margin-bottom: 24px;
    position: relative;

    &--btn{
      padding-top: 10px;
    }
    &--no-margin{
      margin-bottom: 0;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }
}

.district-item{
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 18px;
  border-radius: 16px;
  margin-bottom: 8px;
  &__name{
    font-size: 16px;
    max-width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__icon{
    cursor: pointer;
  }
  &--selected{
    opacity: .5;
  }
}
.bottom-row{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  &__btn{
    width: 275px;
  }
  span{
    font-size: 16px;
    opacity: .5;
  }
}

.dadata{
  position: absolute;
  left: 0;
  top: 75px;
  right: 0;
  background: #FAFAFA;
  border-radius: 22px;
  z-index: 2;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  max-height: 322px;
  overflow: auto;
  gap: 2px 0px;
  .dadata-item{
    padding: 8px 10px;
    transition: ease .3s;
    cursor: pointer;
    border-radius: 14px;
    &:hover{
      background: rgba(#555555, .1);
    }
    span{
      display: block;
      opacity: .5;

      &:first-child{
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.remove-btn{
  margin-left: 22px;
  cursor: pointer;
  rect{
    transition: ease .3s;
  }
  g{
    path{
      transition: ease .3s;
    }
  }
  &:hover{
    rect{
      stroke: #A0A0A0;
    }
    g{
      path{
        stroke: #A0A0A0;
      }
    }

  }
}
</style>
