<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <!-- <h5>Добавление видов груза по категории “{{ type.label }}”</h5> -->

      <div class="form-item">
        <label>Вид груза</label>

        <div
          class="saved-types-list"
          v-if="savedTypes.length"
        >
          <span
            v-for="item in savedTypes"
            :key="item.id"
          >
            {{ item.label }}
          </span>
        </div>

        <div class="input-types">
          <q-input
            placeholder="Введите вид"
            type="text"
            :white="true"
            v-model="form.label"
            :error="v$.label.$errors.length ? 'Заполните поле' : ''"
          />
        </div>


      </div>

      <div class="form-item form-item--btn">
        <q-button
          label="Сохранить"
          view="secondary"
          @clicked="submitForm({more: false})"
        />
      </div>
      <div class="form-item form-item--btn">
        <q-button
          label="Сохранить и добавить еще"
          view="primary"
          @clicked="submitForm({more: true})"
        />
      </div>
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, reactive } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const emit = defineEmits(['close', 'updateWasteList', 'notification'])

const props = defineProps({
  type: {
    type: Object,
    required: true,
  }
})

const savedTypes = ref([])

onMounted(()=> {
  savedTypes.value = props.type.waste_type_selector
})

const form = reactive({
  label: ''
})

const validShema = ref({
  label: { required },
})

const v$ = useVuelidate(validShema, form)

const submitForm = async(type) => {

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  const send = await useApi('post', `waste/type_add`, {...form, category_id: props.type.id})

  if(send.status === 'success'){
    savedTypes.value = send.category.waste_type_selector
    emit('notification', `Вид груза "${form.label}" успешно добавлен`)

    if(type.more){
      form.label = ''
      v$.value.$reset()
    } else {
      emit('close')
    }
    emit('updateWasteList')
  }
}
</script>

<style scoped lang="scss">
.content{
  width: 350px;
  padding: 36px;
  position: relative;
}
h5{
  margin-bottom: 24px;
}

.form-item{
  margin-bottom: 0;
  &--btn{
    margin-top: 10px;
  }
}

// .add-more{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   padding: 5px;
//   border: 1px solid rgba(66, 66, 66, 0.3);
//   border-radius: 14px;
// }

.saved-types-list{
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  margin: 15px 0;
  span{
    background: #F4F4F4;
    border-radius: 14px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 22px;
  }
}

.input-types{
  margin-bottom: 25px;
}
</style>
