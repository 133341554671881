<template>
  <vue-apex-charts
    :options="options"
    :series="series"
    height="300px"
  />
</template>

<script setup>
import { defineComponent, inject, computed, ref } from "vue"
import VueApexCharts from "vue3-apexcharts"

const props = defineProps({
  series: {
    type: Array
  },
  polygonChartSumm: {
    type: Array
  },
  polygonChartTonnage: {
    type: Array
  },
  month: {
    type: String
  },
  year: {
    type: String
  },
  compareData: {
    type: Array,
    default: () => []
  },
  customTooltip: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
  }
})


const periodType = inject('periodType')

const isCompareMode = inject('isCompareMode')

const monthEnum = ref([
  ['января', 'январь'],
  ['февраля', 'февраль'],
  ['марта', 'март'],
  ['апреля', 'апрель'],
  ['мая', 'май'],
  ['июня', 'июнь'],
  ['июля', 'июль'],
  ['августа', 'август'],
  ['сентября', 'сентябрь'],
  ['октября', 'октябрь'],
  ['ноября', 'ноябрь'],
  ['декабря', 'декабрь']
])

const options = computed(()=>{
  return {
    chart: {
      id: "vuechart",
      type: "line",
      background: "#FAFAFA",
    },
    colors: ["#FF8049", "#1E85FF"],
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: "#F4F4F4",
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 5,
      colors: ["white"],
      strokeColor: ["#FF8049", "#1E85FF"],
      strokeWidth: 3,
    },
    xaxis: {
      categories: periodType.value === 'year' ? ['Янв', 'Фев', 'Мар','Апр', 'Май', 'Июн','Июл', 'Авг', 'Сен','Окт', 'Ноя', 'Дек'] : [],
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          if(!props.typeSwitcher){
            return value
          } else {
            if(value > 1000000){
              return ` ${(value / 1000000).toFixed(1)} млн.`
            } else if (value > 1000 & value < 1000000){
              return ` ${(value / 1000).toFixed(0)} тыс.`
            } else {
              return value
            }
          }
        },
      }
    },
    tooltip: {
      enabled: true,

      custom: function({series, seriesIndex, dataPointIndex, w}) {
        return isCompareMode.value
          ? `
            <div class="q-chart-tooltip q-chart-tooltip--compare">

              <div class="date">
                ${monthEnum.value[ +props.month - 1] ? w.globals.labels[dataPointIndex] : monthEnum.value[dataPointIndex][1]}
                ${monthEnum.value[ +props.month - 1] ? monthEnum.value[ +props.month - 1][0] : ''}
                ${props.year}
              </div>

              <div class="compare-table">
                <div class="compare-table__row compare-table__header">
                  ${props.type === 'polygon' ? `${
                    `
                      <span>Площадки</span>
                      <span>Тоннаж</span>
                      <span>Сравнение  <br/> с пред. годом</span>
                      <span>Начисления</span>
                    `
                  }` : ''}

                  ${props.type === 'district' ? `${
                    `
                      <span>Районы</span>
                      <span>Тоннаж</span>
                      <span>Сравнение  <br/> с пред. годом</span>
                      <span>Тер. схема</span>
                    `
                  }` : ''}

                  ${props.type === 'transport' ? `${
                    `
                      <span>Перевозчики</span>
                      <span>Тоннаж</span>
                      <span>Сравнение  <br/> с пред. годом</span>
                      <span>Кол-во <br/> взвеш-й</span>
                    `
                  }` : ''}
                </div>
                ${props.compareData.reduce((acc, item) => acc + `
                  <div class="compare-table__row">
                    ${props.type === 'polygon' ? `${
                    `
                      <span class="chart-round ${props.compareData[0].name === item.name ? 'orange' : 'blue'}"> ${item.name}</span>
                      <span>${item.dataTonnage[dataPointIndex]} т</span>
                      <span style="color: ${item.diff[dataPointIndex].includes('-') ? '#A4F0AA' : '#FF6E63'}">${item.diff[dataPointIndex]}</span>
                      <span>${item.dataSumm[dataPointIndex]} ₽</span>
                    `
                  }` : ''}

                  ${props.type === 'district' ? `${
                    `
                      <span>${item.name}</span>
                      <span>${item.data[dataPointIndex]} т</span>
                      <span style="color: ${item.diff[dataPointIndex].includes('-') ? '#A4F0AA' : '#FF6E63'}">${item.diff[dataPointIndex]}</span>
                      <span style="color: ${Number(item.scheme[dataPointIndex].slice(0, -1)) >= 100 ? '#FF6E63' : '#6FB1FF'}">${item.scheme[dataPointIndex]}</span>
                    `
                  }` : ''}


                  ${props.type === 'transport' ? `${
                    `
                      <span>${item.name}</span>
                      <span>${item.data[dataPointIndex]} т</span>
                      <span style="color: ${item.diff[dataPointIndex].includes('-') ? '#A4F0AA' : '#FF6E63'}">${item.diff[dataPointIndex]}</span>
                      <span>${item.weightNum[dataPointIndex]}</span>
                    `
                  }` : ''}
                  </div>`, '')
                }
              </div>
            </div>
          `:`
            <div class="q-chart-tooltip">
              <div class="date">
                ${props.month ? w.globals.labels[dataPointIndex] : ''}
                ${props.month ? monthEnum.value[ +props.month - 1][0] : monthEnum.value[dataPointIndex][1]}
                ${props.year}
              </div>
              <div class="block">
                <span>Тоннаж</span>
                <p>${props.polygonChartTonnage[0].data[dataPointIndex]} т</p>
              </div>
              ${props.type === 'polygon'
                ? `<div class="block">
                <span>Сумма</span>
                <p>${props.polygonChartSumm[0].data[dataPointIndex]} ₽</p>
              </div>` : ''}
            </div>
          `
      },
      y: {
        formatter: (value) => {
          return value
        },
      },
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
