<template>
  <div ref="target"
    class="search-point-input"
    :class="{
      'search-point-input--error': error !== ''
    }"
  >

    <div class="error-message">{{error}}</div>

    <img
      src="@/assets/images/arrow-grey.svg"
      class="arrow"
      :class="{
        rotate: searchedList,
      }"
      @click="clickOnArrow"
    >

    <input
      type="text"
      v-model="inputValue"
      :placeholder="placeholder"
      ref="input"
      @click="openList"
    />

    <div :class="{searchedList}" class="search-point-input__dropdown">
      <div class="list">
        <div
          class="item"
          v-for="item in filteredResult"
          :key="item.id"
          @click="chooseItem(item)"
        >
          {{item.label}}
        </div>
        <div v-if="!filteredResult.length" class="empty">С таким значением ничего не найдено</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, onMounted, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import useApi from '@/composables/useApi'

const emit = defineEmits(['chooseItem'])

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
  placeholder: {
    type: String,
    default: ''
  },
  active: {
    type: Object,
    default: {
      label: '',
      value: ''
    }
  },
  wasteType: {
    type: Object,
    default: {label: '', id: ''}
  },
  error: {
    type: String,
    default: '',
  },
})

const rememberDefault = ref({label: '', id: ''})

onMounted(async()=> {
  inputValue.value = props.active.label
  rememberDefault.value = props.active

  setTimeout(()=> {
    initial.value = true
  }, 0)
})

const target = ref(null)
const input = ref(null)

const initial = ref(false)

const inputValue = ref('')

const chooseItem = item => {
  emit('chooseItem', item)
  searchedList.value = false
  inputValue.value = item.label
}

const searchedList = ref(false)

onClickOutside(target, (event) => {
  searchedList.value = false

  const active = filteredResult.value.find(item => item.label.toLowerCase() === inputValue.value.toLowerCase())

  if(active){
    emit('chooseItem', active)
    inputValue.value = active.label
  } else {
    emit('chooseItem', rememberDefault.value)
    inputValue.value = rememberDefault.value.label
  }
})

const filteredResult = computed(()=> {
  if(inputValue.value !== ''){
    return props.data.filter(item => item.label.toLowerCase().includes(inputValue.value.toLowerCase()))
  } else {
    return props.data
  }
})

const clickOnArrow = () => {
  if(!searchedList.value){
    input.value.focus()
    inputValue.value = ''
    searchedList.value = true
  }
  // searchedList.value = !searchedList.value
}

const openList = () => {
  if(!searchedList.value){
    inputValue.value = ''
    searchedList.value = true
  }
}

watch(
  () => props.wasteType.id,
  (val) => {
    if(initial.value){
      emit('chooseItem', {id: '', label: ''})
      inputValue.value = ''
      clickOnArrow()
      rememberDefault.value = {id: '', label: ''}
    }
  }
)
</script>

<style scoped lang="scss">
.search-point-input{
  position: relative;
  .arrow{
    position: absolute;
    right: 12px;
    top: 13px;
    transition: ease .3s;
    cursor: pointer;
    &.rotate{
      transform: rotate(180deg);
    }
  }
  input{
    width: 100%;
    background: #f4f4f4;
    border-radius: 14px;
    border: none;
    padding: 9px 40px 9px 18px;
    &::placeholder{
      color: #424242;
      opacity: .5;
    }
  }
  &--error {
    input {
      background: rgba(#FFAFAF, 20%) !important;
      border: 1px solid #FFAFAF !important;
    }
  }
  &__dropdown{
    position: absolute;
    padding: 16px 8px;
    padding-right: 10px;
    left: 0;
    top: 63px;
    z-index: 2;
    width: 100%;
    max-height: 390px;
    background: #FAFAFA;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
    border-radius: 20px;
    opacity: 0;
    visibility: hidden;
    transition: ease .3s;
    top: 20px;

    &.searchedList{
      opacity: 1;
      visibility: visible;
      top: 45px;
    }
    .list{
      max-height: 290px;
      overflow: auto;
      padding-right: 10px;
      .item{
        white-space: nowrap;
        margin-bottom: 2px;
        padding: 8px 10px;
        border-radius: 10px;
        transition: ease .3s;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover{
          background: rgba(85, 85, 85, 0.1);
          cursor: pointer;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
      .empty{
        opacity: .5;
        text-align: center;
        padding: 8px 40px;
        width: 100%;
      }
    }
  }
}

.error-message{
  position: absolute;
  top: 40px;
  right: 0;
  color: #FF7A7A;
  text-align: right;
}
</style>
