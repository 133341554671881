<template>
  <div class="q-preloader container">
    <img
      src="@/assets/images/preloader-agent.svg"
      v-for="(_, idx) in 6"
      :key="idx"
    >
  </div>
</template>

<style scoped lang="scss">
.q-preloader {
  width: 1225px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img{
    margin-bottom: 18px;
    animation-name: blink;
    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
}

@keyframes blink {
  0% { opacity: .5; }
  100% { opacity: 1; }
}
</style>