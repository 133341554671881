<template>
  <div
    class="demo-switch-role"
    :class="{public: $route.name === 'ActsPublic'}"
    ref="target"
  >
    <div class="demo-switch-role__btn">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2978 2.13398C11.4498 1.68582 12.6695 2.41093 12.9433 3.58059L14.3675 3.08809C15.6626 2.64026 17 3.64025 17 5.05642V5.54248L18.4253 5.0989C19.7085 4.69954 21 5.69637 21 7.08619V16.7464C21 18.1362 19.7085 19.133 18.4253 18.7337L17 18.2901V18.7761C17 20.1923 15.6626 21.1923 14.3675 20.7445L12.9738 20.2625C12.7902 21.434 11.6691 22.2364 10.5149 21.937L4.51494 20.3809C3.6246 20.15 3.00001 19.3201 3.00001 18.3681V6.41082C3.00001 5.54595 3.51715 4.7718 4.29776 4.46812L10.2978 2.13398ZM15 16.9199C14.9997 16.9041 14.9996 16.8883 15 16.8725V6.96008C14.9996 6.94423 14.9997 6.92842 15 6.91266V5.05642L13.3162 5.63866C13.2114 5.67493 13.1048 5.69216 13 5.69218V15.1322V18.1404C13.1048 18.1404 13.2114 18.1576 13.3162 18.1939L15 18.7761V16.9199ZM17 7.70863V16.1239L19 16.7464V7.08619L17 7.70863ZM11 4.07667L5.00001 6.41082V18.3681L11 19.9242V15.1322V4.07667Z" fill="#424242"/>
      </svg>
    </div>

    <transition name="slide">
      <div class="demo-switch-role__overlay">
        <p>Это демоверсия. Ниже вы можете выбрать роль, котрую хотите посмотреть</p>
        <button @click="changeRole(1)" :class="{active: $store.state.user.role === 1}">Региональный оператор</button>
        <button @click="changeRole(2)" :class="{active: $store.state.user.role === 2}">Площадка</button>
        <button @click="changeRole(3)" :class="{active: $store.state.user.role === 3}">Перевозчик</button>
        <button @click="changeRole(6)" :class="{active: $store.state.user.role === 6}">Клиент</button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import store from '@/store'
import useApi from '@/composables/useApi'
import { useRouter, useRoute } from 'vue-router'

const route = useRoute()
const router = useRouter()
const target = ref(null)

const roles = ref([
  {
    token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI5MmZhYzAzZS1jZTg2LTRlNDItYmQwNy1jZWExYmI1YTE1MzAiLCJVc2VyUm9sZSI6InJlZ29wZXJhdG9yIiwiVXNlckNvbXBhbnkiOiJiMzEyZTU5ZC0wYjFjLTQ1NmQtYmNkMi1mZGQ4YjJmY2Y4NzkiLCJEYXRlIjoiMjAyMy0wOS0wOFQwNjo1ODo0Ny40MzAyNTg2MDQrMDM6MDAiLCJMb25nIjp0cnVlfQ.N13_L_OX96I21hVkbSh2ZxQMNfWRV73a-eAl20sNTyg',
    role: 1,
    company_name: 'Региональный опреатор',
    access: 'rw',
  },
  {
    token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI0MWVjY2UzMS1hM2M1LTQ2MzAtYjdkMy1lODA0ZDYzZTIzNmUiLCJVc2VyUm9sZSI6InBsYXRmb3JtIiwiVXNlckNvbXBhbnkiOiJjY2Q0NzQyYy0yN2UzLTRjNWEtYTVhYy02OTE5YjNkNDAxODgiLCJEYXRlIjoiMjAyMy0wOS0wOFQwNjo1OToxNS45NjU3OTE0NjQrMDM6MDAiLCJMb25nIjp0cnVlfQ.aN8P_Zow6kNUnXVMngMundziPJpNuyMDEYAoBNDLqs8',
    role: 2,
    company_name: 'Площадка 2',
    access: 'rw',
  },
  {
    token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJjOGE0YmFjYy1mM2Y2LTRhMTktYTk0My03NDY3ZmEyYjkwMzAiLCJVc2VyUm9sZSI6InRyYW5zcG9ydGVyIiwiVXNlckNvbXBhbnkiOiJlMTEwYjg1MC0zMWI5LTQwYzAtOTg4NS1lM2FjYTBlYzZlYmEiLCJEYXRlIjoiMjAyMy0wOS0wOFQwNjo1OTozOS40NzY0ODA2NDkrMDM6MDAiLCJMb25nIjp0cnVlfQ.rMXfTFMkTjQqOiQugPGqex0GDwTDfV0yILzQ8WTAIb8',
    role: 3,
    company_name: 'Перевозчик 2',
    access: 'rw',
  },
  {
    token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI2NzA4NDI4OS05ZjNjLTRhODMtOTJhZi0xZjA4ZjMyN2Q3NmUiLCJVc2VyUm9sZSI6ImNsaWVudCIsIlVzZXJDb21wYW55IjoiNDhmOGZhZGUtNDM1ZC00ZjA5LTg5MmEtYzJjZDg0OTFiNzlhIiwiRGF0ZSI6IjIwMjMtMDktMDhUMDc6MDA6MDIuMzU5NDkzODU1KzAzOjAwIiwiTG9uZyI6dHJ1ZX0.IlhxMLEYyCl7ruhuPDEn-lJzORH4HbcgFG3Bdh7J_9Y',
    role: 6,
    company_name: 'Тестовый клиент',
    access: 'rw',
  },
])

const changeRole = (role) => {
  store.dispatch('login', {
    ...roles.value.find(item => item.role === role),
    remain: true,
  })

  router.push(role === 1 ? { name: 'Analytics'} : { name: 'Acts'})
  // window.location.reload()
}


</script>

<style scoped lang="scss">
.demo-switch-role{
  position: fixed;
  top: 50%;
  left: calc(calc(100vw - 1370px) / 2);
  z-index: 11;
  &:hover{
    .demo-switch-role__overlay{
      opacity: 1;
      visibility: visible;
    }
  }
  &.public{
    left: calc(calc(100vw - 590px) / 2);
  }
  &__btn{
    width: 48px;
    height: 48px;
    background: $lemon-lime;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  }
  &__overlay{
    position: absolute;
    left: 60px;
    top: -140px;
    width: 235px;
    background: white;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
    padding: 16px 8px;
    border-radius: 20px;
    left: 55px;
    opacity: 0;
    visibility: hidden;
    transition: ease .3s;

    p{
      font-size: 16px;
      line-height: 22px;
      opacity: .3;
      padding: 8px 10px;
      margin-bottom: 10px;
    }
    button{
      display: block;
      padding: 8px 10px;
      background: white;
      border-radius: 14px;
      width: 100%;
      text-align: left;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
      transition: ease .3s;
      &:last-child{
        margin-bottom: 0;
      }
      &.active{
        background: rgba(85, 85, 85, 0.1);
      }
    }
  }
}
</style>
