<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <h5 v-if="type === 'add'">Добавление ТС</h5>
      <h5 v-else>Редактирование ТС</h5>

      <div class="form">
        <div class="form__content">
          <div class="form__left">

            <div class="form-item" v-if="organisations[0]">
              <label>Организация</label>
              <q-search-select
                :error="fieldsError.company"
                :data="organisations"
                placeholder="Выберите организацию"
                @chooseItem="organisationSelectItemActive = $event"
                :active="type === 'add' ? organisations[0] : {id: data.organisation_id, label: data.organisation}"
              />
            </div>

            <div class="form-item">
              <label>Марка ТС</label>
              <q-select
                :items="carsSelectItems"
                @changeSelect="carsSelectItemActive = $event"
                :selectedItem="carsSelectItemActive"
                :maxHeight="139"
              />
            </div>

            <div class="form-item">
              <label>Гос. № ТС</label>
              <q-input-number
                placeholder="М 000 М 000"
                type="text"
                :disabled="false"
                :error="fieldsError.license"
                :white="true"
                v-model="liscensePlate"
                license
                :masked="maskVal"
              />
            </div>
          </div>

          <div class="form__right">
            <div
              v-if="$store.state.user.role === 1"
              class="form-item"
            >
              <label>Район обслуживания</label>
              <q-select
                :items="districtSelectItems"
                @changeSelect="districtSelectItemActive = $event"
                :selectedItem="districtSelectItemActive"
                :maxHeight="211"
              />
            </div>

            <div class="form-item">
              <label>№ RFID метки</label>
              <q-input
                placeholder="№"
                type="text"
                :disabled="false"
                :white="true"
                v-model="rfid"
                masked="rfid"
                :error="fieldsError.rfid"
              />
            </div>

            <div class="form-item">
              <label>Объем</label>
              <q-input
                placeholder="Введите объем кузова(м3)"
                type="number"
                :white="true"
                v-model="capacity"
              />
            </div>
          </div>
        </div>

        <div class="form__row">
          <div class="form-item form-item--no-margin">
            <label>{{setDate}}</label>
          </div>
          <div class="form-buttons">
            <div class="">
              <q-button
                label="Сохранить"
                view="secondary"
                @clicked="submitForm"
                :disabled="!validate"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, inject, watch, reactive } from "vue"
import QSearchSelect from '@/components/ui/QSearchSelect.vue'
import store from "@/store"
import moment from 'moment'
import useApi from '@/composables/useApi'
import { notify } from '@kyvg/vue3-notification'


const props = defineProps({
  type: String,

  id: String,

  data: Object,
})

const emit = defineEmits(['setDataEdit', 'setDataCreate', 'close'])

onMounted(async() => {
  await setInitialData()
  await getOrganisations()
  await getBrands()
  await getDistrics()
});

const organisations = ref([])

const getOrganisations = async() => {
  const organisationsData = await useApi('get', `company/get_transporters`)
  organisations.value = organisationsData.transporters.map(function (item) {
    return {
      label: `${item.label} ${item.description ? ' ('+item.description+')' : '' }`,
      id: item.id,
    };
  });
  if(!props.data){
    organisationSelectItemActive.value = organisations.value[0]
  }
};

const districtSelectItems = ref([])
const districtSelectItemActive = ref({
  id: '',
  label: "Выберите район",
});

const setDate = computed(()=>{
  if(props.type === 'edit'){
    if (props.data.date_create === props.data.date_update) {
      return `Дата создания: ${moment(props.data.date_create).format('DD.MM.YYYY')}`
    } else {
      return `Дата обновления: ${moment(props.data.date_update).format('DD.MM.YYYY')}`
    }
  } else {
    return `Дата добавления: ${moment().format('DD.MM.YYYY')}`
  }
})

const organisationSelectItems = ref([]);
const organisationSelectItemActive = ref({
  label: '',
  id: '',
});


const carsSelectItems = ref([]);

const carsSelectItemActive = ref({
  label: "Выберите Марку ТС",
  id: '',
});

const getBrands = async() => {
  const brandsData = await useApi('get', 'car/get_brands')
  carsSelectItems.value = brandsData.brands.map(function (item) {
    return {
      label: `${item.label} ${item.description ? ' ('+item.description+')' : '' }`,
      id: item.id,
    };
  });
};

const liscensePlate = ref("")

const capacity = ref("")

const rfid = ref('')

const getDistrics = async() => {
  const distrsData = await useApi('post', 'distrs/find_distrs', {
    distr_name: ''
  })
  districtSelectItems.value = distrsData.distrs.map(item => {
    return  {
      ...item,
      label: item.name,
    }
  })
}


const fieldsError = reactive({
  company: '',
  license: '',
  rfid: ''
})

const submitForm = async(isMore) => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  store.dispatch('setGlobalPreloader', true)

  errorMessageRFID.value = ''
  let url = 'create'

  let payload = {
    brand_id: carsSelectItemActive.value.id === '' ? '' : carsSelectItemActive.value.id,
    company_id: organisationSelectItemActive.value.id === '' ? '' : organisationSelectItemActive.value.id,
    rfid: rfid.value,
    state_number: liscensePlate.value,
    distr_id: districtSelectItemActive.value.id || '',
    capacity: capacity.value.length ? String(capacity.value) : '0'
  }

  if(props.type === 'edit'){
    url = 'edit'
    payload.id = props.data.car_id
  }

  const sendForm = await useApi('post', `car/${url}_car`, payload)

  if(sendForm.error){
    if(sendForm.type === 'common'){
      notify({ title: sendForm.message, type: 'error'})
    }
    if(sendForm.type === 'fields'){
      for (let key in sendForm.fields) {
        fieldsError[key] = sendForm.fields[key]
      }
    }
    store.dispatch('setGlobalPreloader', false)
    return
  }


  if(props.type === 'add') {
    emit('setDataCreate', sendForm.car)
    if(!isMore){ emit('close') }

    organisationSelectItemActive.value = { label: "Выберите организацию", id: '' }
    carsSelectItemActive.value = { label: "Выберите Марку ТС", id: '', value: ''}
    liscensePlate.value = ''
    rfid.value = ''
    districtSelectItemActive.value = {
      id: '',
      label: "Выберите район",
    }
    store.dispatch('setGlobalPreloader', false)
  } else {
    emit('setDataEdit', sendForm.car)
    store.dispatch('setGlobalPreloader', false)
    emit('close')
  }
}

const validate = computed(() =>
  liscensePlate.value.length >= 8 &&
  liscensePlate.value.length <= 9 &&
  organisationSelectItemActive.value.id !== '' &&
  carsSelectItemActive.value.id !== ''
)

const setInitialData = () => {
  if (props.data) {

    carsSelectItemActive.value = {
      label: props.data.brand,
      id: props.data.brand_id
    }
    if(props.data.distrs[0]){
      districtSelectItemActive.value = props.data.distrs[0]
    }
    organisationSelectItemActive.value = {
      label: props.data.organisation,
      id: props.data.organisation_id
    }
    liscensePlate.value = props.data.state_number

    if(props.data.rfid && props.data.rfid !== '-'){
      rfid.value = props.data.rfid
    }
    capacity.value = props.data.capacity === '-' ? '0' : props.data.capacity
  }
}

const errorMessageRFID = ref('')
const errorMessageLicense = ref('')

const maskVal = computed(() => Number(liscensePlate.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>

<style scoped lang="scss">
.content {
  width: 650px;
  padding: 36px;
  .form{
    &__content{
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    &__left,
    &__right{
      width: 276px;
    }
    &__row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      .form-buttons{
        width: calc(50% - 13px);
        margin-top: 0;
      }
      .form-item > label{
        margin-bottom: 0;
      }
    }
  }

  .form-item {
    margin-bottom: 24px;
    &--btn {
      padding-top: 10px;
    }
    &--no-margin{
      margin: 0;
    }
    &+.ro{
      height: 81px;
      display: flex;
      align-items: flex-end;
    }
  }
  .form-btn {
    margin-top: 10px;
  }
  h5 {
    margin-bottom: 22px;
  }
  label {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: 0.5;
  }
  .form-buttons {
    margin-top: 52px;
  }
}
</style>
