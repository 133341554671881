<template>
  <div class="header-support" ref="target">

    <span
      class="header-support__text"
      v-if="text"
      @click="isOpenInfo = !isOpenInfo"
    >
      Поддержка
    </span>

    <svg
      v-else
      @click="isOpenInfo = !isOpenInfo"
      class="header-support__icon"
      :class="{active: isOpenInfo}"
      width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 11.7373C21 11.7163 20.9997 11.6953 20.999 11.6745L20.999 9C20.999 5.13401 17.865 2 13.999 2L9.99902 2C6.13303 2 2.99902 5.13401 2.99902 9L2.99902 11.7373L2.99902 12L2.99902 14.2627C2.99902 15.1956 3.64395 16.0046 4.55337 16.2124L5.55337 16.441C6.80553 16.7272 7.99902 15.7757 7.99902 14.4913L7.99902 11.5087C7.99902 10.2243 6.80553 9.2728 5.55337 9.55901L4.99902 9.68571L4.99902 9C4.99902 6.23858 7.2376 4 9.99902 4L13.999 4C16.7604 4 18.999 6.23858 18.999 9L18.999 9.68549L18.4456 9.55901C17.1935 9.2728 16 10.2243 16 11.5087L16 14.4913C16 15.7757 17.1935 16.7272 18.4456 16.441L18.8127 16.3571C18.2725 18.2767 16.6151 19.7284 14.5865 19.9658C14.3133 19.6789 13.9275 19.5 13.5 19.5L12.5 19.5C11.6716 19.5 11 20.1716 11 21C11 21.8284 11.6716 22.5 12.5 22.5L13.5 22.5C13.9582 22.5 14.3684 22.2945 14.6436 21.9707C18.2074 21.6454 20.999 18.6487 20.999 15L20.999 14.3255C20.9997 14.3047 21 14.2837 21 14.2627L21 11.7373Z" fill="#C6C6C6"/>
    </svg>

    <transition name="default">
      <div
        v-show="isOpenInfo"
        class="header-support__block"
      >
        <div class="header-support__close" @click="isOpenInfo = false">
          <q-round-button />
        </div>

        <div class="header-support__row">
          <img class="header-support__avatar" src="@/assets/images/avatar-support.svg" alt="">
          <div class="header-support__info">
            <h4>QODEX SUPPORT</h4>
            <div>Пн-Пт ・ 08:00 – 20:00</div>
            <div>Если у вас возникли вопросы по работе системы:</div>
          </div>
        </div>
        <div class="header-support__links">
          <a class="link" href="tg://resolve?domain=@qodextechsup" target="_blank">
            <span>telegram</span>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0002 32.3002C25.4502 32.3002 32.3002 25.4502 32.3002 17.0002C32.3002 8.55024 25.4502 1.7002 17.0002 1.7002C8.55024 1.7002 1.7002 8.55024 1.7002 17.0002C1.7002 25.4502 8.55024 32.3002 17.0002 32.3002Z" fill="#4AAEE8"/>
              <path d="M17.5022 24.0772C17.3379 23.9114 16.7593 22.9013 16.2163 21.8327C15.6733 20.764 15.1191 19.7324 14.9846 19.5404C14.8314 19.3214 13.9646 18.8008 12.6612 18.1448C10.3165 16.9647 10.0014 16.7115 10.0648 16.0582C10.1228 15.4613 10.3605 15.3378 13.6612 14.1895C15.1709 13.6643 17.606 12.8174 19.0725 12.3075C20.539 11.7976 21.9015 11.3804 22.1003 11.3804C22.5153 11.3804 22.9052 11.7472 22.9052 12.1377C22.9052 12.2847 22.4161 13.8176 21.8182 15.5443C21.2203 17.2709 20.5341 19.2564 20.2932 19.9566C19.4791 22.3234 18.8565 23.9086 18.6485 24.1442C18.3634 24.4673 17.8595 24.4379 17.5022 24.0772Z" fill="white"/>
            </svg>
          </a>
          <a class="link" href="https://wa.me/79375000665" target="_blank">
            <span>whatsapp</span>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0002 32.3002C25.4502 32.3002 32.3002 25.4502 32.3002 17.0002C32.3002 8.55024 25.4502 1.7002 17.0002 1.7002C8.55024 1.7002 1.7002 8.55024 1.7002 17.0002C1.7002 25.4502 8.55024 32.3002 17.0002 32.3002Z" fill="#45D354"/>
              <path d="M19.9504 17.6414C19.7874 17.5291 19.6245 17.473 19.4615 17.6975L18.8098 18.5957C18.6468 18.7079 18.5382 18.7641 18.321 18.6518C17.5063 18.2027 16.3657 17.6975 15.3881 16.0136C15.3338 15.7891 15.4424 15.6768 15.551 15.5645L16.0398 14.7787C16.1484 14.6664 16.0941 14.5541 16.0398 14.4419L15.3881 12.8141C15.2251 12.365 15.0622 12.4211 14.8992 12.4211H14.4647C14.3561 12.4211 14.1389 12.4773 13.9216 12.7018C12.7267 13.9367 13.2155 15.6768 14.0845 16.7994C14.2475 17.024 15.3338 19.0447 17.6692 20.1112C19.4072 20.8971 19.7874 20.7848 20.2762 20.6725C20.8737 20.6164 21.4711 20.1112 21.7427 19.606C21.797 19.4376 22.0686 18.7079 21.8513 18.5957L19.9504 17.6414ZM17.5606 23.8721C15.3338 23.8721 13.65 22.6372 13.65 22.6372L10.9887 23.3669L11.6405 20.6725C11.6405 20.6725 10.5542 18.9325 10.5542 16.7433C10.5542 12.7018 13.7587 9.33386 17.7235 9.33386C21.4168 9.33386 24.567 12.3089 24.567 16.4626C24.567 20.5041 21.4168 23.8159 17.5606 23.8721ZM8.9248 25.4999L13.4328 24.2089C14.7384 24.8996 16.1935 25.2331 17.6592 25.1773C19.125 25.1215 20.5524 24.6784 21.8053 23.8902C23.0582 23.102 24.0947 21.9951 24.8159 20.6752C25.5371 19.3552 25.9189 17.8662 25.9248 16.3504C25.9248 11.523 22.2315 7.6499 17.5606 7.6499C16.0614 7.65387 14.5894 8.06367 13.2911 8.8385C11.9928 9.61334 10.9136 10.7262 10.1609 12.0662C9.40827 13.4061 9.00841 14.9265 9.00117 16.4759C8.99394 18.0253 9.37958 19.5496 10.1197 20.8971" fill="white"/>
            </svg>
          </a>
          <a class="link" href="tel:+79375000665">
            <span>+7(937)500-06-65</span>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0002 32.3002C25.4502 32.3002 32.3002 25.4502 32.3002 17.0002C32.3002 8.55024 25.4502 1.7002 17.0002 1.7002C8.55024 1.7002 1.7002 8.55024 1.7002 17.0002C1.7002 25.4502 8.55024 32.3002 17.0002 32.3002Z" fill="#BEA0FF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4498 8.5H19.5498C21.4276 8.5 22.9498 10.0222 22.9498 11.9V22.1C22.9498 23.9778 21.4276 25.5 19.5498 25.5H14.4498C12.572 25.5 11.0498 23.9778 11.0498 22.1V11.9C11.0498 10.0222 12.572 8.5 14.4498 8.5ZM14.4498 11.05C14.4498 10.5806 14.8304 10.2 15.2998 10.2H18.6998C19.1692 10.2 19.5498 10.5806 19.5498 11.05C19.5498 11.5194 19.1692 11.9 18.6998 11.9H15.2998C14.8304 11.9 14.4498 11.5194 14.4498 11.05ZM16.9998 23.8C17.9387 23.8 18.6998 23.0389 18.6998 22.1C18.6998 21.1611 17.9387 20.4 16.9998 20.4C16.0609 20.4 15.2998 21.1611 15.2998 22.1C15.2998 23.0389 16.0609 23.8 16.9998 23.8Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import QRoundButton from '@/components/common/QRoundButton.vue'

const props = defineProps({
  text: {
    type: Boolean,
    default: false
  }
})

const isOpenInfo = ref(false)
const target = ref(null)

onClickOutside(target, (event) => isOpenInfo.value = false)
</script>


<style scoped lang="scss">
  .header-support{
    margin-left: 35px;
    height: 24px;
    cursor: pointer;
    position: relative;
    &__text{
      color: white;
      font-size: 16px;
    }

    &__icon{
      path{
        transition: ease .2s;
      }
      &.active{
        path{
          fill: #424242;
        }
      }
      &:hover{
        path{
          fill: #424242;
        }
      }
    }

    &__block{
      background: white;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      padding: 20px;
      position: absolute;
      right: 0;
      top: 45px;
      width: 350px;
    }
    &__row{
      display: flex;
      align-items: flex-start;
    }
    &__info{
      div{
        opacity: .5;
        margin-top: 5px;

        &:last-child{
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
    &__avatar{
      margin-right: 18px;
      border-radius: 50%;
      overflow: hidden;
      flex: none;
    }
    &__links{
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
    }
    &__close{
      position: absolute;
      top: 0;
      right: -50px;
    }
  }

.link{
  text-decoration: none;
  margin-right: 10px;
  border-radius: 30px;
  margin-bottom: 10px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  transition: 1s ease;
  svg{
    margin-right: -10px;
    margin-left: 10px;
  }
  &:nth-child(1){
    background: #E3F5FF;
    &:hover{
      background: #c3e4f7
    }
  }
  &:nth-child(2){
    background: #EEFFEF;
    &:hover{
      background: #c1f0c6;
    }
  }
  &:nth-child(3){
    background: #ECE3FF;
    margin-bottom: 0;
    &:hover{
      background: #e9e0ff;
    }
  }
}
</style>
