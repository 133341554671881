<template>
  <div
    class="q-input"
    :class="{
      'q-input--error': error !== '',
      'q-input--show-pass': showPassword,
      'q-input--white': white,
      'q-input--grey': grey,
      'q-input--filter': filter,
      'q-input--license': license,
      'q-input--cross': cross,
      'q-input--disabled': disabled,

    }"
    v-click-outside="closeSearchPanel"
  >
    <div
      v-if="showPassword"
      class="q-input__show-pass"
      @click="$emit('switch-show-pass')"
    >
      <svg v-if="type === 'password'" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0954 3.68424C13.905 2.20284 11.5399 0 7.99985 0C4.45983 0 2.09466 2.20284 0.904343 3.68424C0.27798 4.46377 0.277979 5.53623 0.904342 6.31576C2.09466 7.79716 4.45983 10 7.99985 10C11.5399 10 13.905 7.79716 15.0954 6.31576C15.7217 5.53623 15.7217 4.46377 15.0954 3.68424ZM11.9998 5C11.9998 7.20914 10.209 9 7.99985 9C5.79071 9 3.99985 7.20914 3.99985 5C3.99985 2.79086 5.79071 1 7.99985 1C10.209 1 11.9998 2.79086 11.9998 5ZM7.99985 7C9.10442 7 9.99985 6.10457 9.99985 5C9.99985 3.89543 9.10442 3 7.99985 3C6.89528 3 5.99985 3.89543 5.99985 5C5.99985 6.10457 6.89528 7 7.99985 7Z" fill="white"/></svg>
      <svg v-if="type === 'text'" style="margin-top: 6px;" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0954 5.68426C14.8134 5.33342 14.4656 4.94211 14.0519 4.54918L11.9997 6.94342C12 6.96225 12.0001 6.98112 12.0001 7.00003C12.0001 9.02074 10.5017 10.6915 8.55541 10.9618L7.67097 11.9936C7.77944 11.9979 7.88907 12 7.99985 12C11.5399 12 13.905 9.79718 15.0954 8.31579C15.7217 7.53626 15.7217 6.46379 15.0954 5.68426ZM4.00051 7.05629C4.00025 7.03757 4.00012 7.01881 4.00012 7.00003C4.00012 4.97942 5.49835 3.30875 7.4445 3.0383L8.32898 2.00642C8.22042 2.00219 8.11071 2.00003 7.99985 2.00003C4.45983 2.00003 2.09466 4.20287 0.904343 5.68426C0.27798 6.46379 0.27798 7.53626 0.904342 8.31579C1.18628 8.66667 1.53413 9.05803 1.9479 9.45101L4.00051 7.05629Z" fill="white"/><path d="M13.7682 1.64021C14.1218 1.21593 14.0644 0.585368 13.6402 0.231804C13.2159 -0.12176 12.5853 -0.0644362 12.2318 0.359841L10.5328 2.3986L9.71184 3.38374L8.34085 5.02893L6.12262 7.6908L4.75221 9.33529L3.59258 10.7268L2.23175 12.3598C1.87819 12.7841 1.93551 13.4147 2.35979 13.7682C2.78407 14.1218 3.41463 14.0645 3.7682 13.6402L5.46711 11.6015L6.28819 10.6162L7.65914 8.97107L9.87753 6.30901L11.2479 4.66457L12.4073 3.27331L13.7682 1.64021Z" fill="white"/></svg>
    </div>

    <transition name="bottom">
      <div
        class="q-input__search-panel"
        :class="{'q-input__search-panel--grey': grey }"
        v-if="search && search.length"
      >
        <div class="content scroll-bar-dark">
          <div
            class="item"
            v-for="item in search"
            :key="item.id"
            @click="$emit('searchItemChoose', item)"
          >
            <img src="@/assets/images/zoom.svg" alt="">
            <span>{{ license ? prettyLicensePlate(item.label).toUpperCase() : item.label.toUpperCase() }} {{ item.description || '' }} </span>
            <img src="@/assets/images/arrow-filter.svg" alt="">
          </div>
        </div>
      </div>
    </transition>

    <transition name="default">
      <div class="q-input__cross" v-if="cross && modelValue.length > 0" @click="$emit('searchItemChoose', {id: '', label: ''})">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6569 1.75744C12.0474 1.36692 12.0474 0.733751 11.6569 0.343227C11.2663 -0.047298 10.6332 -0.0472978 10.2426 0.343227L11.6569 1.75744ZM0.343146 10.2427C-0.0473781 10.6332 -0.0473788 11.2664 0.343146 11.6569C0.73367 12.0475 1.36684 12.0475 1.75736 11.6569L0.343146 10.2427ZM1.75729 0.343294C1.36677 -0.04723 0.733602 -0.0472299 0.343078 0.343294C-0.0474462 0.733819 -0.0474466 1.36698 0.343078 1.75751L1.75729 0.343294ZM10.2426 11.657C10.6331 12.0475 11.2663 12.0475 11.6568 11.657C12.0473 11.2665 12.0473 10.6333 11.6568 10.2428L10.2426 11.657ZM10.2426 0.343227L0.343146 10.2427L1.75736 11.6569L11.6569 1.75744L10.2426 0.343227ZM0.343078 1.75751L10.2426 11.657L11.6568 10.2428L1.75729 0.343294L0.343078 1.75751Z" fill="#C6C6C6"/>
        </svg>
      </div>
    </transition>

    <input
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      @input="onInput"
      v-maska="isMasked"
      step="0.01"
      @click="onInput"
      autocomplete="off"
    />

    <div class="error-message">{{error}}</div>
  </div>
</template>

<script>
import { defineComponent, PropType, computed } from 'vue'
import prettyLicensePlate from '@/helpers/prettyLicensePlate.js'
import { maska } from 'maska'

export default defineComponent({
  name: 'QInput',

  directives: { maska },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    showPassword: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false
    },
    search: {
      type: Array,
      default: []
    },
    license: {
      type: Boolean
    },
    cross: {
      type: Boolean,
      default: false,
    },
    masked: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
    'switch-show-pass',
    'searchItemChoose',
    'closeSearchPanel',
    'onInput',
    'focusedSearch'
  ],

  setup({license, rfid, modelValue, val, masked}, {emit}){

    const isMasked = computed(()=>{
      if(masked === 'rfid') return '####'
      if(masked === 'inn') return '############'
      if(masked === 'kpp') return '#########'
      if(masked === 'masko') return 'AAAA##'
      if(masked === 'port') return '######'
    })

    const closeSearchPanel = (p) => {
      p === 'clear'
        ? emit('update:modelValue', '')
        : emit('closeSearchPanel')
    }

    const onInput = (e) => {
      emit('onInput', e.target.value)
      emit('update:modelValue', license ? e.target.value.toUpperCase() : e.target.value)
    }

    return {
      prettyLicensePlate,
      closeSearchPanel,
      onInput,
      isMasked,
    }
  }
})
</script>


<style scoped lang="scss">
.q-input {
  position: relative;
  width: 100%;

  &__cross{
    position: absolute;
    right: 7px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    text-align: center;
  }

  &__search-panel{
    position: absolute;
    left: 0;
    bottom: 70px;
    width: 100%;
    padding: 8px;
    border-radius: 22px;
    background: $ford-medium-dark;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 5px;
      background: transparent;
    }
    .content{
      overflow-y: auto;
      height: 100%;
      max-height: 300px;
    }
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px 5px;
      border-radius: 14px;
      transition: ease .2s;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      &:hover{
        background: rgba(85, 85, 85, .6);
      }
      span{
        color: $white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 10px;
        flex: 1;
      }
    }
  }

  &__show-pass{
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: ease .3s;
    &:hover{
      opacity: .6;

    }
  }
  input {
    background: rgba($white, .1);
    border-radius: 14px;
    padding: 8px 18px;
    border: 1px solid transparent;
    width: 100%;
    color: $white;
    transition: ease-in-out .3s;

    &:focus {
      outline: none;
    }
    &:disabled {
      color: rgba($ford-medium-dark, .5);
    }
  }
  .error-message{
    position: absolute;
    top: 40px;
    right: 0;
    color: #FF7A7A;
    text-align: right;
  }
  &--show-pass{
    input {
      padding-right: 38px;
    }
  }
  &--white {
    input {
      background: $caparol;
      color: $ford-medium-dark;
      &::placeholder {
        color: $ford-medium-dark;
        opacity: .5;
      }
    }
  }
  &--disabled{
    opacity: .3;
  }
  &--grey {
    input {
      border: 1px solid $grey;
      color: $ford-medium-dark;
      height: 42px;
    }
    .q-input__search-panel--grey{
      bottom: 45px;
    }
  }
  &--filter{
    input{
      height: 48px;
      font-size: 16px;
    } 
  }
  &--cross{
    input{
      padding-right: 28px;
    } 
  }
  &--error { 
    input {
      background: rgba(#FFAFAF, 20%) !important;
      border: 1px solid #FFAFAF !important;
    }
  }
}

.q-input__search-panel--grey{
  background: white;
  border: 1px solid $grey;
  .item{
    span{
      color: $ford-medium-dark;
    }
    &:hover{
      background: $caparol;
    }
  }
}


// hide arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
