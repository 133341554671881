<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">
      <div class="back" v-if="isStepSecond && !isEdit" @click="backToStepFirst">
        <q-round-button :back="true" />
      </div>

      <h5>{{data ? 'Редактирование перевозчика' : 'Добавление перевозчика'}}</h5>

      <transition name="default">
        <div class="form-item" v-if="isStepSecond">
          <label>Название организации</label>
          <q-input
            placeholder="Например: Газпром"
            type="text"
            :disabled="true"
            :white="true"
            v-model="name"
          />
        </div>
      </transition>

      <div
        class="form-item"
        :class="{'form-item--no-margin': !isStepSecond}"
      >
        <label>ИНН</label>
        <q-input
          placeholder="Введите 10 или 12 цифр"
          type="text"
          :disabled="isStepSecond"
          :white="true"
          v-model="inn"
          masked="inn"
          :error="inputErrorsInnKpp"
        />
        <transition name="default">
          <div class="dadata" v-if="daDataArr.length && isShowDaDataMenu">
            <div class="dadata-item"
              v-for="item in daDataArr"
              :key="item.inn"
              @click="setDaData(item)"
            >
              <span>{{item.label}}</span>
              <span>ИНН {{item.inn}} <template v-if="item.kpp">/ КПП {{item.kpp}}</template></span>
            </div>
          </div>
        </transition>
      </div>

      <transition name="default">
        <div class="form-item" v-if="isStepSecond && kpp.length">
          <label>КПП</label>
          <q-input
            placeholder="Введите 9 цифр"
            type="text"
            :disabled="true"
            :white="true"
            v-model="kpp"
            masked="kpp"
            :error="inputErrorsInnKpp"
          />
        </div>
      </transition>

      <transition name="default">
        <div class="form-item" v-if="isStepSecond">
          <label>Описание(максимум 20 символов)</label>
          <q-input
            placeholder="Введите уточняющую информацию"
            type="text"
            :white="true"
            v-model="description"
          />
        </div>
      </transition>

      <div v-if="changedName" class="changed-name">{{ changedName }}</div>

      <div
        class="form-buttons"
        v-if="isStepSecond"
      >
        <div class="">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="submitForm"
            :disabled="validate"
          />
        </div>
        <div class="form-btn" v-if="!data">
          <q-button
            label="Сохранить и добавить еще"
            view="flat-b"
            :disabled="validate"
            @clicked="submitForm(true)"
          />
        </div>
      </div>
    </form>
  </q-modal>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed, onMounted, watchEffect } from 'vue'
import useApi from '@/composables/useApi'
import store from '@/store'
import QRoundButton from '@/components/common/QRoundButton.vue'

const props = defineProps({
  data: {
    type: Object,
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close', 'updateCounterpartiesList', 'showNotification'])

const name = ref('')
const inn = ref('')
const kpp = ref('')
const description = ref('')

const isShowDaDataMenu = ref(false)
const isStepSecond = ref(false)

const daDataArr = ref([])

const isFirstLoad = ref(true)

watchEffect(async() => {
  if(inn.value.length === 10 || inn.value.length === 12){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: inn.value,
        count: 300
      })
    }

    if(isFirstLoad.value && props.isEdit){
      isFirstLoad.value = false
    }
    else {
      const data = await fetch(url, options)
      const dd = await data.json()

      daDataArr.value = dd.suggestions.map(item => {
        return {
          label: item.value,
          inn: item.data.inn,
          kpp: item.data.kpp || '',
        }
      })
      isShowDaDataMenu.value = true
    }
  } else {
    daDataArr.value = []
  }
})

const inputErrorsInnKpp = ref('')

onMounted(async()=> {

  if(props.data) {
    name.value = props.data.company_name
    inn.value = props.data.inn
    kpp.value = props.data.kpp || ''
    description.value = props.data.description || ''

    // setTimeout(() => isShowDaDataMenu.value = false, 500);
  }

  if(props.isEdit){
    isStepSecond.value = true

    //new name
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: inn.value,
        count: 300
      })
    }

    const data = await fetch(url, options)
    const dd = await data.json()

    let name1 = name.value.replaceAll('«', '').replaceAll('»', '').replaceAll('"', '').toLowerCase()
    let name2 = dd.suggestions[0].value.replaceAll('«', '').replaceAll('»', '').replaceAll('"', '').toLowerCase()

    if(name1 !== name2){
      changedName.value = `Изменилось наименование организации (прошлое название: ${name.value})`
      name.value = dd.suggestions[0].value
    }
  }
})


const changedName = ref('')

const validate = computed(() => {
  if(kpp.value === ''){
    if  ((inn.value.length === 10 || inn.value.length === 12) && name.value.length > 0){
      return false
    } else {
      return true
    }
  } else {
    if  ((inn.value.length === 10 || inn.value.length === 12) && kpp.value.length === 9 && name.value.length > 0){
      return false
    } else {
      return true
    }
  }
})

const submitForm = async(isMore) => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  store.dispatch('setGlobalPreloader', true)
  inputErrorsInnKpp.value = ''

  const payload = {
    company_name: name.value,
    company_inn: inn.value,
    company_kpp: kpp.value,
    description: description.value
  }
  if(props.data){
    payload.id = props.data.id;
  }
  const actionCounterparty = await useApi('post', `counterparties/${props.data ? 'edit' : 'add'}_counterparties`, payload)

  store.dispatch('setGlobalPreloader', false)

  if (actionCounterparty.errors){
    inputErrorsInnKpp.value = 'Такой контрагент уже существует'
    return
  }
  if(isMore){
    name.value = ''
    inn.value = ''
    kpp.value = ''
    description.value = ''
  }
  else {
    emit('close')
  }
  emit('updateCounterpartiesList')
  emit('showNotification', props.data ? 'Успешное редактирование контрагента' : 'Успешное добавление контрагента')
}

const setDaData = (x) => {
  isStepSecond.value = true
  name.value = x.label
  inn.value = x.inn
  kpp.value = x.kpp
  isShowDaDataMenu.value = false
}

const backToStepFirst = () => {
  isStepSecond.value = false
  name.value = ''
  kpp.value = ''
  isShowDaDataMenu.value = true
}
</script>

<style scoped lang="scss">
.content{
  width: 360px;
  padding: 36px;
  position: relative;

  .back{
    position: absolute;
    left: -60px;
    top: 0;
  }

  .form-item{
    margin-bottom: 24px;
    position: relative;

    &--btn{
      padding-top: 10px;
    }
    &--no-margin{
      margin-bottom: 0;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }
}

.dadata{
  position: absolute;
  left: 0;
  top: 75px;
  right: 0;
  background: #FAFAFA;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  z-index: 2;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  max-height: 322px;
  overflow: auto;
  gap: 2px 0px;
  .dadata-item{
    padding: 8px 10px;
    transition: ease .3s;
    cursor: pointer;
    border-radius: 14px;
    &:hover{
      background: rgba(#555555, .1);
    }
    span{
      display: block;
      opacity: .5;

      &:first-child{
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.changed-name{
  color: $natural-color-system;
  font-size: 18px;
}
</style>
