<template>
  <div class="q-rfid-filters">
    <div class="q-rfid-filters__item q-rfid-filters__item--free">
      <q-select
        :items="freeSelectItems"
        @changeSelect="freeSelectItemActive = $event"
        :selectedItem="freeSelectItemActive"
        :maxHeight="170"
        :filter="true"
      />
    </div>
    <div class="q-rfid-filters__item q-rfid-filters__item--rfid">
      <q-input
        v-model="rfid"
        placeholder="№ RFID"
        type="text"
        filter
        :search="searchedRfid"
        @searchItemChoose="chooseRfid"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputRfid"
        cross
        masked="rfid"
      />
    </div>

    <div class="q-rfid-filters__item q-rfid-filters__item--company">
      <q-input
        v-model="transporter"
        placeholder="Компания"
        type="text"
        filter
        :search="searchedTransporters"
        @searchItemChoose="chooseTransporter"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputTransporters"
        cross
      />
    </div>

    <div class="q-rfid-filters__item q-rfid-filters__item--type">
      <q-select
        :items="typeSelectItems"
        @changeSelect="typeSelectItemActive = $event"
        :selectedItem="typeSelectItemActive"
        :maxHeight="170"
        :filter="true"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'

const emit = defineEmits([
  'setRfid',
  'setFree',
  'setCompany',
  'setType'
])

const freeSelectItems = ref([
  {
    label: 'Cвободные',
    id: 'true'
  },
  {
    label: 'Занятые',
    id: 'false'
  },
  {
    label: 'Все',
    id: ''
  }
])
const freeSelectItemActive = ref({
  label: 'Все',
  id: ''
})

const typeSelectItems = ref([
  {
    label: 'Все метки',
    id: ''
  },
  {
    label: 'Карты',
    id: 'map'
  },
  {
    label: 'Стикеры',
    id: 'sticker'
  }
])
const typeSelectItemActive = ref({
  label: 'Все метки',
  id: ''
})

watch(
  ()=> typeSelectItemActive.value,
  ()=> emit('setType', typeSelectItemActive.value.id)
)

watch(
  ()=> freeSelectItemActive.value,
  ()=> emit('setFree', freeSelectItemActive.value.id)
)



const rfid = ref('')
const searchedRfid = ref([])

const inputRfid = async(e) => {
  if(e){
    const rfidData = await useApi('get', `rfid/type/find/${e}`)
    searchedRfid.value = rfidData.rfid.map(item => {
      return {
        id: item.id,
        label: item.number
      }
    })
  } else {
    searchedRfid.value = []
  }
}
const chooseRfid = item => {
  rfid.value = item.label
  emit('setRfid', item.id)
  searchedRfid.value = []
}

const closeSearchPanel = () => {
  // searchedNumbers.value = []
  // searchedTransporters.value = []
  // searchedBrands.value = []
  searchedRfid.value = []
}

const transporter = ref('')
const searchedTransporters = ref([])

const inputTransporters = async(e) => {
  if(e){
    const transporterData = await useApi('get', `filters/find_transporter/${e}`)
    searchedTransporters.value = transporterData.transporters
  } else {
    searchedTransporters.value = []
  }
}
const chooseTransporter = item => {
  transporter.value = item.label
  emit('setCompany', item.id)
  searchedTransporters.value = []
}
</script>


<style scoped lang="scss">
  .q-rfid-filters{
    display: flex;
    justify-content: space-between;
    padding: 18px 9px;
    &__item{
      padding: 0 9px;
      &--free{
        width: 228px;
      }
      &--rfid{
        width: 152px;
      }
      &--company{
        width: 570px;
      }
      &--type{
        width: 232px;
      }
    }
  }
</style>