<template>
  <div class="page">
    <q-scroll-up-button :quantityPaginationPage="quantityPaginationPage"/>

    <teleport to="#header__left-teleport-container">
      <div class="header-btn" >
        <button
          class="add-new"
          @click="isShowModalOrder = true"
        >
          <img src="@/assets/images/add.svg" alt="">
          Добавить
        </button>
      </div>

      <div
        class="header-btn"
        v-if="$store.state.user.role === 2 && currentWaste.id === 'po'"
      >
        <button
          class="add-new"
          @click="isShowModalOrder = true"
        >
          <img src="@/assets/images/add.svg" alt="">
          Добавить
        </button>
      </div>
    </teleport>

    <q-empty-content
      v-if="notFoundMessage"
      :message="notFoundMessage"
      page="orders"
    />

    <q-orders-preloader v-if="preloader"/>

    <transition name="default">
      <div class="orders-list"  v-if="!preloader">
        <div class="container">
          <q-orders-item
            v-for="order in orders"
            :key="order.id"
            :data="order"
            @updateOrdersList="getOrders"
          />
        </div>
      </div>
    </transition>

    <transition name="left">
      <q-notification v-if="notificationText" :text="notificationText"/>
    </transition>

    <v-pagination
      v-if="quantityPaginationPage > 1"
      v-model="currentPaginationPage"
      :pages="quantityPaginationPage"
      :range-size="1"
      @update:modelValue="updateHandler"
    />

    <q-bottom-panel>
      <QOrdersFilters
        @setCarNumber="setCarNumber"
        @setTransporter="setTransporter"
        @setPolygon="setPolygon"
        @setSortType="setSortType"
        @setFilteredDate="setFilteredDate"
        @downloadExcel="downloadExcel"
      />
    </q-bottom-panel>

    <transition name="default">
      <q-modal-order
        v-if="isShowModalOrder"
        @close="isShowModalOrder  = false"
        @successSendOrder="successSendOrder"
      />
    </transition>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch } from 'vue'
import moment from 'moment'
import store from '@/store'
import { useRouter } from 'vue-router'
import useRouterGuard from '@/composables/useRouterGuard'
import useApi from '@/composables/useApi'
import QOrdersItem from './QOrdersItem.vue'
import QOrdersFilters from './QOrdersFilters.vue'
import QOrdersPreloader from './QOrdersPreloader.vue'
import QBottomPanel from '@/components/common/QBottomPanel.vue'
import QEmptyContent from '@/components/common/QEmptyContent.vue'
import VPagination from '@hennge/vue3-pagination'
import QScrollUpButton from '@/components/common/QScrollUpButton.vue'
import QNotification from '@/components/ui/QNotification.vue'
import QModalOrder from '@/components/modals/QModalOrder.vue'

const isShowModalOrder = ref(false)

const preloader = ref(false)
const orders = ref([])
const notFoundMessage = ref('')

const notificationText = ref('')

const router = useRouter()

onMounted(async ()=> {
  if(store.state.user.role !== 6){
    router.push('/acts')
  }

  date.value = [moment().utc(moment()._d), moment().utc(moment()._d)]
  await useRouterGuard()
  preloader.value = true
  getOrders()
})

const carNumber = ref('')
const transporter = ref('')
const polygon = ref('')
const sortType = ref('date_desc')
const date = ref(['', ''])

const isDirtyFields = ref(false)

const quantityPaginationPage = ref(1)
const currentPaginationPage = ref(1)

watch(
  () => quantityPaginationPage.value,
  (val) => currentPaginationPage.value = 1
)

const getOrders = async(dispute = false) => {
  store.dispatch('setGlobalPreloader', true)
  orders.value = []
  preloader.value = true
  notFoundMessage.value = ''

  const ordersData = await useApi('post', 'bids/get_bids', {
    car_number: carNumber.value,
    current_page: isDirtyFields.value ? 1 : currentPaginationPage.value,
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    platform_name: polygon.value,
    sorting_by: dispute ? 'dispute' : String(sortType.value),
    transporter_name: transporter.value,
  })

  if(ordersData.message){
    notFoundMessage.value = ordersData.message.text
  }
  quantityPaginationPage.value = ordersData.pages
  orders.value = ordersData.bid
  preloader.value = false
  isDirtyFields.value = false
  store.dispatch('setGlobalPreloader', false)
}

const successSendOrder = () => {
  isShowModalOrder.value = false
  notificationText.value = 'Заявка успешно создана'
  setTimeout(()=> notificationText.value = '', 4000)
  getOrders()
}

const updateHandler = (e) => {
  currentPaginationPage.value = e
  getOrders()
}

const setSortType = e => {
  isDirtyFields.value = true
  sortType.value = e
  getOrders()
}

const setPolygon = e => {
  polygon.value = e
  isDirtyFields.value = true
  getOrders()
}

const setTransporter = e => {
  transporter.value = e
  isDirtyFields.value = true
  getOrders()
}

const setCarNumber = e => {
  carNumber.value = e
  isDirtyFields.value = true
  getOrders()
}

const setFilteredDate = e => {
  isDirtyFields.value = true
  date.value = [moment(e[0]).utc(e[0]), moment(e[1]).utc(e[1])]
  getOrders()
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13) getOrders()
});


const downloadExcel = async() => {
  store.dispatch('setGlobalPreloader', true)
  const req = await useApi('post', 'bids/get_excel', {
    car_number: carNumber.value,
    date_start: date.value[0] ? date.value[0] : '',
    date_stop: date.value[1] ? date.value[1] : '',
    platform_name: polygon.value,
    transporter_name: transporter.value,
  })

  if(req.docs){
    window.open(req.docs, '_self');
  }
  store.dispatch('setGlobalPreloader', false)
}
</script>

<style scoped lang="scss">
.page{
  padding-bottom: 150px;
}
.add-new{
  margin-left: 35px;
}
</style>
