<template>
  <q-modal @close="$emit('close')">

    <div
      class="content"
      :class="{min: !isStepSecond}"
    >

      <h5>{{data ? 'Редактирование компании' : 'Добавление компании'}}</h5>

      <div
        v-if="!isStepSecond"
        class="form-item"
      >
        <label>ИНН</label>
        <q-input
          placeholder="Введите 10 или 12 цифр"
          type="text"
          :white="true"
          v-model="innFirst"
          masked="inn"
          :error="inputErrorsInnKpp"
        />
        <transition name="default">
          <div class="dadata" v-if="daDataArr.length && isShowDaDataMenu">
            <div class="dadata-item"
              v-for="item in daDataArr"
              :key="item.inn"
              @click="setDaData(item)"
            >
              <span>{{item.label}}</span>
              <span>ИНН {{item.inn}} <template v-if="item.kpp">/ КПП {{item.kpp}}</template></span>
            </div>
          </div>
        </transition>
      </div>

      <form v-else @submit.prevent="">

        <div class="back" v-if="isStepSecond && !data" @click="backToStepFirst">
          <q-round-button :back="true" />
        </div>

        <div class="form-item">
          <label>Название организации</label>
          <q-input
            type="text"
            :white="true"
            v-model="name"
            disabled
          />
        </div>

        <div class="form-item">
          <label>Сфера деятельности</label>
          <q-select
            :items="spheres"
            @changeSelect="sphereSelectItemActive = $event"
            :selectedItem="sphereSelectItemActive"
            :maxHeight="139"
          />
        </div>

        <div class="form-item">
          <label>ИНН</label>
          <q-input
            type="text"
            :white="true"
            v-model="inn"
            masked="inn"
            disabled
          />
        </div>

        <div class="form-item">
          <label>КПП</label>
          <q-input
            type="text"
            :white="true"
            v-model="kpp"
            masked="kpp"
            disabled
          />
        </div>

        <div class="form-item">
          <label>Роль</label>
          <q-select
            :items="roles"
            @changeSelect="roleSelectItemActive = $event"
            :selectedItem="roleSelectItemActive"
            :maxHeight="139"
          />
        </div>

        <div class="form-item">
          <label>E-mail</label>
          <q-input
            placeholder="Введите e-mail"
            type="text"
            :white="true"
            v-model="validFields.email"
            :error="v$.email.$errors.length ? 'Заполните поле' : ''"
          />
        </div>

        <transition name="top">
          <div v-if="roleSelectItemActive.type.length" class="form-item">
            <label>Тип роли</label>
            <q-select
              :items="roleTypeSelectItems"
              @changeSelect="roleTypeSelectItemActive = $event"
              :selectedItem="roleTypeSelectItemActive"
              :maxHeight="139"
            />
          </div>
        </transition>

        <div class="form-item form-item--password">
          <label>Пароль</label>
          <q-input
            placeholder="Придумайте пароль"
            type="text"
            :white="true"
            v-model="validFields.password"
            :error="v$.password.$errors.length ? 'Заполните поле' : ''"
          />
          <svg @click="generatePassword" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1185 0.49968C12.4961 0.49968 11.9909 1.00425 11.9909 1.62667C11.9909 2.2491 12.4961 2.75367 13.1185 2.75367C13.741 2.75367 14.2462 2.2491 14.2462 1.62667C14.2462 1.00425 13.741 0.49968 13.1185 0.49968ZM2.31519 11.7825L9.41217 4.68961C10.0423 4.05988 11.0638 4.05988 11.6939 4.68961L13.3211 6.31591C13.9512 6.94563 13.9512 7.96661 13.3211 8.59634L6.22416 15.6892C5.14473 16.7681 3.39462 16.7681 2.31519 15.6892C1.23576 14.6104 1.23575 12.8613 2.31519 11.7825ZM10.4731 5.74996L8.25038 7.97144L10.0374 9.75746L12.2602 7.53598C12.3043 7.49188 12.3043 7.42037 12.2602 7.37626L10.6329 5.74996C10.5888 5.70586 10.5173 5.70586 10.4731 5.74996ZM3.37615 12.8429L7.18941 9.0318L8.97646 10.8178L5.1632 14.6289C4.66972 15.1221 3.86963 15.1221 3.37615 14.6289C2.88268 14.1357 2.88267 13.3361 3.37615 12.8429ZM14.2474 9.38865C14.2474 8.76622 14.7526 8.26165 15.375 8.26165C15.9974 8.26165 16.5027 8.76622 16.5027 9.38865C16.5027 10.0111 15.9974 10.5156 15.375 10.5156C14.7526 10.5156 14.2474 10.0111 14.2474 9.38865ZM7.60797 1.49968C6.98555 1.49968 6.48033 2.00425 6.48033 2.62667C6.48033 3.2491 6.98555 3.75367 7.60797 3.75367C8.23039 3.75367 8.73561 3.2491 8.73561 2.62667C8.73561 2.00425 8.23039 1.49968 7.60797 1.49968Z" fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.4"/>
          </svg>
        </div>


        <transition name="top">
          <div v-if="roleSelectItemActive.type.length" class="form-item">
            <label>Контролирующий орган(РО)</label>
            <q-select
              :items="[ ...ro, {id: '', label: 'Без привязки'} ]"
              @changeSelect="roSelectItemActive = $event"
              :selectedItem="roSelectItemActive"
              :maxHeight="139"
            />
          </div>
        </transition>

        <div class="form-item">
          <label>Ссылка на диск</label>
          <q-input
            placeholder="Диск с информацией о Gravity и тд"
            type="text"
            :white="true"
            v-model="validFields.disk"
            :error="v$.disk.$errors.length ? 'Заполните поле' : ''"
          />
        </div>

        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

        <q-button
          label="Сохранить"
          view="secondary"
          @clicked="submitForm"
        />


      </form>

    </div>
  </q-modal>
</template>

<script setup>
import { defineProps, ref, computed, onMounted, watch, watchEffect, reactive } from 'vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import { useVuelidate } from '@vuelidate/core'
import { required, email, url  } from '@vuelidate/validators'
import QRoundButton from '@/components/common/QRoundButton.vue'
import { useRouter } from 'vue-router'


const emit = defineEmits(['close', 'notification', 'updateCounterpartiesList', 'openAttachingGravity'])

const props = defineProps({
  data: {
    type: Object,
  },
  spheres: {
    type: Array,
    default: () => []
  },
  roles: {
    type: Array,
    default: () => []
  },
  ro: {
    type: Array,
    default: () => []
  }
})

const router = useRouter()


const isStepSecond = ref(false)

const innFirst = ref('')
const inputErrorsInnKpp = ref('')
const daDataArr = ref([])
const isShowDaDataMenu = ref(false)
const isFirstLoad = ref(true)

const setDaData = (x) => {
  isStepSecond.value = true
  name.value = x.label
  inn.value = x.inn
  kpp.value = x.kpp
  isShowDaDataMenu.value = false
}

watchEffect(async() => {
  if(innFirst.value.length === 10 || innFirst.value.length === 12){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: innFirst.value,
        count: 300
      })
    }

    if(isFirstLoad.value && props.isEdit){
      isFirstLoad.value = false
    }
    else {
      const data = await fetch(url, options)
      const dd = await data.json()

      daDataArr.value = dd.suggestions.map(item => {
        return {
          label: item.value,
          inn: item.data.inn,
          kpp: item.data.kpp || '',
        }
      })
      isShowDaDataMenu.value = true
    }
  } else {
    daDataArr.value = []
  }
})

const name = ref('')
const inn = ref('')
const kpp = ref('')

const validFields = reactive({
  email: '',
  disk: '',
  password: ''
})

const errorMessage = ref('')

const validShema = ref({
  email: { required, email },
  disk: { required, url },
  password: props.data ? {} : { required }
})

const v$ = useVuelidate(validShema, validFields)

const roleSelectItemActive = ref({
  id: '',
  label: "Выберите Роль",
  type: []
});

const roleTypeSelectItems = ref([])
const roleTypeSelectItemActive = ref({
  id: '',
  label: "Выберите тип роли",
});

const roSelectItemActive = ref({
  id: '',
  label: "Без привязки",
});

const sphereSelectItemActive = ref({
  id: '',
  label: "Выберите сферу",
});



watch(
  () => roleSelectItemActive.value,
  () => {
    if(roleSelectItemActive.value.type.length){
      roleTypeSelectItems.value = roleSelectItemActive.value.type
      roleTypeSelectItemActive.value = roleSelectItemActive.value.type[0]
      // roSelectItemActive.value = {id: '', label: "Без привязки"}
    }
  }
)


onMounted(()=> {
  sphereSelectItemActive.value = props.spheres[0]
  roleSelectItemActive.value = props.roles[0]

  if(props.data){
    isStepSecond.value = true
    validFields.email = props.data.email
    validFields.disk = props.data.disk
    inn.value = props.data.inn
    kpp.value = props.data.kpp
    name.value = props.data.label
    roleSelectItemActive.value = props.data.role
    roleTypeSelectItemActive.value = props.data.role_type
    sphereSelectItemActive.value = props.data.sphere

    if(props.data.reg_oper.length){
      roSelectItemActive.value = props.data.reg_oper[0]
    }
  }
})

const submitForm = async() => {

  errorMessage.value = ''

  v$.value.$touch()
  if(v$.value.$errors.length){
    return false
  }

  const payload = {
    disk: validFields.disk,
    email: validFields.email,
    inn: inn.value,
    kpp: kpp.value,
    label: name.value,
    password: validFields.password,
    platform_type: roleTypeSelectItemActive.value,
    reg_oper: [ roSelectItemActive.value ],
    role: roleSelectItemActive.value,
    sphere: sphereSelectItemActive.value
  }

  if(props.data){
    payload.id = props.data.id
    payload.user_id = props.data.user_id
  }

  const data = await useApi('post', `admin/company/${props.data ? 'update' : 'create'}`, payload)
  if(data.status === 'successful'){

    if(roleSelectItemActive.value.id === 2){
      router.push({ query: { id: data.company_id }})
      emit('openAttachingGravity')
    }

    emit('updateCounterpartiesList')
    emit('notification', `Компания успешно ${props.data ? 'отредактирована' : 'Cоздана'}`)
    emit('close')
  }
  if(data.status === 'error'){
    errorMessage.value = data.error.message
  }
}

const backToStepFirst = () => {
  isStepSecond.value = false
  name.value = ''
  kpp.value = ''
  isShowDaDataMenu.value = true
}

const generatePassword = () => validFields.password = Math.random().toString(36).slice(-10)
</script>

<style scoped lang="scss">
.content{
  width: 648px;
  padding: 36px;
  &.min{
    width: 348px;
    .form-item{
      position: relative;
      margin-bottom: 0;
    }
  }
}
h5{
  margin-bottom: 22px;
}



form{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item{
    width: calc(50% - 12px);
    position: relative;
  }
}

.dadata{
  position: absolute;
  left: 0;
  top: 75px;
  right: 0;
  background: #FAFAFA;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  z-index: 2;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  max-height: 322px;
  overflow: auto;
  .dadata-item{
    padding: 8px 10px;
    transition: ease .3s;
    cursor: pointer;
    border-radius: 14px;
    &:hover{
      background: rgba(#555555, .1);
    }
    span{
      display: block;
      opacity: .5;

      &:first-child{
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.back{
  position: absolute;
  left: -60px;
  top: 0;
}

.form-item--password{
  position: relative;
  svg{
    position: absolute;
    right: 10px;
    bottom: 11px;
    cursor: pointer;
    path{
      transition: ease .3s;
    }
    &:hover{
      path{
        fill: #B2B2B2;
      }
    }
  }
}
.error-message{
  color: #FF6D63;
  margin: 20px auto;
}
</style>
