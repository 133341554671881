<template>
  <div class="period-date" ref="target">

    <button
      class="period-date__btn"
      :style="{opacity: isShowPanel ? '0.8' : '1'}"
      @click="isShowPanel = !isShowPanel"
    >
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.66781 3.37004C8.66781 2.7973 9.1321 2.33301 9.70484 2.33301C10.2776 2.33301 10.7419 2.79731 10.7419 3.37005V5.71702C10.7419 6.28976 10.2776 6.75406 9.70484 6.75406C9.1321 6.75406 8.66781 6.28976 8.66781 5.71702V3.37004Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9991 2.33301C17.4264 2.33301 16.9621 2.7973 16.9621 3.37004V4.66634H9.18734C5.96568 4.66634 3.354 7.27801 3.354 10.4997V18.6663C3.354 21.888 5.96568 24.4997 9.18734 24.4997H18.5207C21.7423 24.4997 24.354 21.888 24.354 18.6663V10.4997C24.354 7.45172 22.0164 4.94975 19.0362 4.6888V3.37005C19.0362 2.79731 18.5719 2.33301 17.9991 2.33301ZM19.0362 4.6888C18.8663 4.67393 18.6944 4.66634 18.5207 4.66634H16.9621V5.71702C16.9621 6.28976 17.4264 6.75406 17.9991 6.75406C18.5719 6.75406 19.0362 6.28976 19.0362 5.71702V4.6888ZM5.68734 10.4997C5.68734 8.56668 7.25434 6.99967 9.18734 6.99967H18.5207C20.4537 6.99967 22.0207 8.56668 22.0207 10.4997H5.68734ZM5.68734 12.833H22.0207V18.6663C22.0207 20.5993 20.4537 22.1663 18.5207 22.1663H9.18734C7.25434 22.1663 5.68734 20.5993 5.68734 18.6663V12.833Z" fill="white"/>
        <path d="M17.9991 6.75406C18.5719 6.75406 19.0362 6.28976 19.0362 5.71702V4.6888C18.8663 4.67393 18.6944 4.66634 18.5207 4.66634H16.9621V5.71702C16.9621 6.28976 17.4264 6.75406 17.9991 6.75406Z" fill="white"/>
      </svg>
    </button>

    <div class="period-date__info" :class="{static: isShowPanel}">
      <span v-if="!periodType">{{months[month - 1].fullName}}</span>
      <span>{{year}}</span>
    </div>

    <div
      class="period-date__panel"
      :class="{'show': isShowPanel}"
    >
      <div class="period-date__title">Выберите период</div>
      <div class="period-date__switch" :class="{'period-type': periodType}">
        <div @click="changePeriodType">За месяц</div>
        <div @click="changePeriodType">За год</div>
      </div>
      <div class="period-date__period">
        <div class="months">
          <div
            class="disable"
            v-if="periodType"
          />
          <div
            class="item"
            v-for="m in months"
            :key="m.value"
            :class="{active: m.value === month, dis: periodType}"
            @click="changeMonth(m.value)"
          >
            {{m.name}}
          </div>
        </div>

        <div class="separator"></div>

        <div class="years">
          <div
            class="item"
            v-for="y in years"
            :key="y"
            :class="{active: y === year}"
            @click="changeYear(y)"
          >
            {{y}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'
import useApi from '@/composables/useApi'

onMounted(()=> {
  getYearPeriod()
})

const props = defineProps({
  year:{
    required: true
  },
  month:{
    required: true
  },
})

const getYearPeriod = async () => {
  const yearPeriod = await useApi('get', 'analitics/get_year_period')

  years.value = yearPeriod.years
    .map(item => String(item))
    .reverse()
}

const emit = defineEmits(['changePeriod', 'changeMonth', 'changeYear'])

const target = ref(null)
const periodType = ref(false)
const isShowPanel = ref(false)
const years = ref([])
const months = ref([
  {
    name: 'Янв',
    fullName: 'Январь',
    value: '1',
  },
  {
    name: 'Фев',
    fullName: 'Февраль',
    value: '2',
  },{
    name: 'Мар',
    fullName: 'Март',
    value: '3',
  },{
    name: 'Апр',
    fullName: 'Апрель',
    value: '4',
  },{
    name: 'Май',
    fullName: 'Май',
    value: '5',
  },{
    name: 'Июн',
    fullName: 'Июнь',
    value: '6',
  },{
    name: 'Июл',
    fullName: 'Июль',
    value: '7',
  },{
    name: 'Авг',
    fullName: 'Август',
    value: '8',
  },{
    name: 'Сен',
    fullName: 'Сентябрь',
    value: '9',
  },{
    name: 'Окт',
    fullName: 'Октябрь',
    value: '10',
  },{
    name: 'Ноя',
    fullName: 'Ноябрь',
    value: '11',
  },{
    name: 'Дек',
    fullName: 'Декабрь',
    value: '12',
  }
])

onClickOutside(target, (event) => isShowPanel.value = false)


const changeMonth = month => {
  emit('changeMonth', month)
  setTimeout(()=> isShowPanel.value = false, 500)
}

const changeYear = year => {
  emit('changeYear', year)
  setTimeout(()=> isShowPanel.value = false, 500)
}

const changePeriodType = () => {
  periodType.value = !periodType.value
  emit('changePeriod', periodType.value ? 'year' : 'month')
  setTimeout(()=> isShowPanel.value = false, 500)
}
</script>

<style scoped lang="scss">
.period-date{
  position: relative;
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translateX(685px);
  z-index: 15;
  &__btn{
    background: $ford-medium-dark;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease .2s;
    &:hover{
      opacity: .8;
      ~.period-date__info{
        opacity: 1;
        visibility: visible;
        right: 59px;
      }
    }
  }
  &__info{
    position: absolute;
    transition: ease .3s;
    background: linear-gradient(0deg, #393939, #393939), #FFE4D9;
    border-radius: 14px;
    padding: 8px 16px;
    right: 0px;
    top: 10px;
    white-space: nowrap;
    right: 0px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    span{
      color: white;
      &:first-child{
        margin-right: 5px;
      }
    }
    &.static{
      opacity: 1;
      visibility: visible;
      right: 59px;
    }
  }
  &__panel{
    background: white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    padding: 12px;
    width: 375px;
    position: absolute;
    top: 66px;
    right: -300px;
    opacity: 0;
    transition: ease .4s;
    visibility: hidden;

    &.show{
      right: 0;
      visibility: visible;
      opacity: 1;
    }
  }

  &__title{
    text-align: center;
    padding: 16px 0;
    font-size: 16px;
  }

  &__switch{
    background: $caparol;
    display: flex;
    border-radius: 13px;
    position: relative;
    div{
      width: 50%;
      text-align: center;
      padding: 13px;
      font-size: 16px;
      isolation: isolate;
      cursor: pointer;
      &:first-child{
        color: white;
      }
    }
    &:before{
      content: '';
      border-radius: 14px;
      position: absolute;
      left: 6px;
      top: 6px;
      width: calc(50% - 6px);
      height: calc(100% - 12px);
      background: $ford-medium-dark;
      transition: ease .2s;
    }

    &.period-type{
      &:before{
        left: 175px;
      }
      div{
        &:first-child{
          color: $ford-medium-dark;
        }
        &:last-child{
          color: white;
        }
      }
    }
  }
  &__period{
    background: $beckers;
    padding: 12px;
    margin-top: 10px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .months{
      width: 231px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      .disable{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
    .separator{
      height: 174px;
      width: 2px;
      background: rgba($ford-medium-dark, .1);
      border-radius: 16px;
    }
  }
}

.item{
  text-align: center;
  background: $caparol;
  border-radius: 14px;
  margin-bottom: 10px;
  padding: 7px 0;
  cursor: pointer;
  width: 69px;
  transition: ease .2s;

  &.active{
    background: $ford-medium-dark;
    color: white;
  }
  &.dis{
    opacity: .6;
  }
}
</style>
