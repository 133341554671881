<template>
  <div class="wrapper" :style="{ backgroundImage: 'url(' + require('@/assets/images/video-poster.jpg') + ')' }">
    <video-background
      :src="require('@/assets/videos/login-background.mp4')"
      style="height: 100vh; width: 100vw;"
      v-if="$store.state.user.token == null"
      poster="@/assets/images/video-poster.jpg"
    />
    <div class="inner">
      <div class="container">
        <h1>Наблюдайте <br/>за происходящим вокруг</h1>
        <QLoginForm />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import VideoBackground from 'vue-responsive-video-background-player'
import QLoginForm from './QLoginForm.vue'
import useRouterGuard from '@/composables/useRouterGuard'

onMounted(()=> useRouterGuard('login'))
</script>

<style scoped lang="scss">
.wrapper{
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  height: 100vh;
}
.inner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, .7);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 95px 15px;

  h1 {
    max-width: 470px;
    margin-bottom: 30px;
    color: $white;
  }
}
</style>
