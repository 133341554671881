<template>
  <teleport to="#header__left-teleport-container" >
    <div class="teleport-container-left">
      <div v-if="!kpp"  class="acts-info">
        <div class="acts-info__item">Тоннаж: {{ (tonnage / 1000).toFixed(2) }} т</div>
        <div class="acts-info__item">Взвешиваний: {{ weights }}</div>
      </div>
      <div v-else class="acts-info"> {{incidents ? 'Инцидентов' : 'Пропусков'}}: {{ passes }}</div>

      <div
        class="acts-dispute"
        @click="typeSelectItemActive = {
          label: 'Оспоренные',
          id: 'dispute'
        }"
        v-if="numDispute && !kpp"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2821 2.31294C14.5818 2.62984 14.6415 3.10441 14.4297 3.48568C14.4153 3.51153 14.401 3.53723 14.3868 3.56277C14.0959 4.08504 13.8384 4.54725 13.8471 5.1256C13.8552 5.66548 14.244 6.64752 15.5338 7.73574C17.2794 9.20864 18.1423 11.0244 18.569 12.4472C18.7828 13.1602 18.8901 13.784 18.9442 14.2342C18.9714 14.4597 18.9853 14.643 18.9924 14.7738C18.996 14.8392 18.9979 14.8916 18.9989 14.9297C18.9994 14.9488 18.9997 14.9643 18.9998 14.976L19 14.9909L19 14.9962L19 14.9983C19 14.9983 19 15 18 15H19C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15C5 13.0708 5.27082 11.3872 5.65531 10.1373C5.84643 9.51606 6.07537 8.97001 6.33429 8.54645C6.46359 8.33495 6.61569 8.1286 6.79493 7.95593C6.96706 7.79011 7.22146 7.60021 7.55827 7.52443C7.83311 7.46259 8.12129 7.51954 8.35194 7.6813C8.5826 7.84305 8.73432 8.09459 8.76981 8.37406C8.80188 8.62658 8.90678 8.99636 9.07376 9.38677C9.06542 8.72353 9.11444 8.0194 9.25001 7.31185C9.62771 5.34067 10.6991 3.27308 13.1195 2.10014C13.5119 1.90993 13.9824 1.99605 14.2821 2.31294ZM17 15.0027C16.9986 17.7629 14.7605 20 12 20C9.23858 20 7 17.7615 7 15C7 13.2915 7.23503 11.8401 7.54224 10.8069C7.62101 10.9513 7.70476 11.0928 7.79301 11.2296C8.29556 12.0087 9.18578 13 10.4444 13C10.7595 13 11.0562 12.8515 11.245 12.5993C11.4338 12.3471 11.4927 12.0206 11.4039 11.7183C11.1444 10.8344 10.9153 9.24848 11.2143 7.68822C11.3418 7.02257 11.5613 6.37519 11.9093 5.7881C12.1118 6.90179 12.8212 8.06382 14.244 9.26433C15.6095 10.4164 16.3021 11.8506 16.6533 13.0216C16.8283 13.6055 16.9154 14.1145 16.9585 14.4729C16.98 14.6517 16.9904 14.7916 16.9954 14.883C16.9979 14.9287 16.9991 14.9621 16.9996 14.982L17 15.0016L17 15.0027Z" fill="#FFE4D9"/>
        </svg>
        <span class="acts-dispute__num">{{numDispute}}</span>

        <div class="hint">
          <div class="hint-col">
            <span>Нетто не учтено</span>
            <span>{{tonnageDispute}} т</span>
          </div>
          <div class="hint-col">
            <span>Оспореных актов</span>
            <span>{{numDispute}}</span>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <div class="q-acts-filters">
    <div class="q-acts-filters__item q-acts-filters__item--number">
      <q-input-number
        v-model="number"
        placeholder="М 000 ММ 000"
        type="text"
        filter
        :search="searchedNumbers"
        @searchItemChoose="chooseNumber"
        license
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputNumbers"
        cross
        :masked="maskVal"
        :disabled="incidents"
      />
    </div>

    <div
      class="q-acts-filters__item q-acts-filters__item--transporter"
      v-if="$store.state.user.role === 1 || $store.state.user.role === 2 || $store.state.user.role === 6"
    >
      <q-input
        v-model="transporter"
        placeholder="Перевозчик"
        type="text"
        filter
        :search="searchedTransporters"
        @searchItemChoose="chooseTransporter"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputTransporters"
        cross
        :disabled="incidents"
      />
    </div>

    <div
      class="q-acts-filters__item q-acts-filters__item--transporter"
      v-if="$store.state.user.role === 2"
    >
      <q-input
        v-model="client"
        placeholder="Клиент"
        type="text"
        filter
        :search="searchedClients"
        @searchItemChoose="chooseClient"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputClients"
        cross
        :disabled="incidents"
      />
    </div>

    <div
      v-if="$store.state.user.role === 1 || $store.state.user.role === 3  || $store.state.user.role === 6"
      class="q-acts-filters__item q-acts-filters__item--polygon"
    >
      <q-input
        v-model="polygon"
        placeholder="Площадка"
        type="text"
        filter
        :search="searchedPolygons"
        @searchItemChoose="choosePolygon"
        @closeSearchPanel="closeSearchPanel"
        @onInput="inputPolygons"
        cross
        :disabled="incidents"
      />
    </div>

    <div v-if="!kpp" class="q-acts-filters__item q-acts-filters__item--find-act">
      <div
        class="find-act__btn filter-round-btn"
        :class="{'filter-round-btn--active': isFindSoloAct}"
        @click="isFindSoloAct = true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="4" cy="12" r="2" fill="#C6C6C6"/>
          <circle cx="12" cy="12" r="2" fill="#C6C6C6"/>
          <circle cx="20" cy="12" r="2" fill="#C6C6C6"/>
        </svg>
      </div>

      <transition name="top">
        <KeepAlive>
          <div
            id="findAct"
            v-show="isFindSoloAct"
            class="find-act__block"
            ref="target"
          >
            <input
              type="number"
              v-model="soloSearch"
              placeholder="№  акта"
              ref="input"
            >
            <q-button
              label="Поиск"
              @clicked="findSoloAct"
            />
          </div>
        </KeepAlive>
      </transition>
    </div>

    <div class="q-acts-filters__item q-acts-filters__item--datepicker">
      <q-date-picker @setFilteredDate="setFilteredDate"/>
    </div>

    <div class="q-acts-filters__item">
      <q-select
        :items="typeSelectItems"
        @changeSelect="typeSelectItemActive = $event"
        :selectedItem="typeSelectItemActive"
        :filter="true"
        :sort="true"
        multi
        :incidents="incidents"
      />
    </div>

    <div
      class="q-acts-filters__item filter-round-btn q-acts-filters__item-download-excel"
      @click="$emit('downloadExcel')"
      v-if="!kpp"
    >
      <img src="@/assets/images/download-file.svg" alt="">
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch, computed, onMounted } from 'vue'
import QDatePicker from '@/components/common/QDatePicker.vue'
import store from '@/store'
import useApi from '@/composables/useApi'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  tonnage: {
    type: Number
  },
  weights: {
    type: Number
  },
  numDispute: {
    type: Number
  },
  tonnageDispute: {
    type: Number
  },
  wasteCategory: {
    type: String,
    default: ''
  },
  kpp: {
    type: Boolean,
    default: false
  },
  passes: {
    type: Number
  },
  incidents: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits([
  'setCarNumber',
  'setTransporter',
  'setPolygon',
  'setSortType',
  'setFilteredDate',
  'downloadExcel',
  'setTemporaryActNumber',
  'setClient',
])

const number = ref('')
const searchedNumbers = ref([])

const inputNumbers = async(e) => {
  if(e){
    const numberData = await useApi('get', `filters/find_state_number/${e}${props.wasteCategory ? '/'+props.wasteCategory : ''}`)
    searchedNumbers.value = numberData.cars
  } else {
    searchedNumbers.value = []
  }
}

const chooseNumber = item => {
  number.value = item.label
  emit('setCarNumber', item.label)
}

const transporter = ref('')
const searchedTransporters = ref([])

const inputTransporters = async(e) => {
  if(e){
    const transporterData = await useApi('get', `filters/find_transporter/${e}`)
    searchedTransporters.value = transporterData.transporters
  } else {
    searchedTransporters.value = []
  }
}
const chooseTransporter = item => {
  transporter.value = item.label
  emit('setTransporter', item.id)
  searchedTransporters.value = []
}

const client  = ref('')
const searchedClients = ref([])

const inputClients = async(e) => {
  if(e){
    const clientData = await useApi('get', `acts/prompt/clients?search=${e}`)
    searchedClients.value = clientData.data.clients
  } else {
    searchedClients.value = []
  }
}
const chooseClient = item => {
  client.value = item.label
  emit('setClient', item.id)
  searchedClients.value = []
}


const polygon = ref('')
const searchedPolygons = ref([])

const inputPolygons = async(e) => {
  if(e){
    const polygonsData = await useApi('get', `filters/find_platform/${e}`)
    searchedPolygons.value = polygonsData.platforms
  } else {
    searchedPolygons.value = []
  }
}

const choosePolygon = item => {
  polygon.value = item.label
  emit('setPolygon', item.id)
  searchedPolygons.value = []
}

const filteredDate = ref([])

const setFilteredDate = (date) => {
  filteredDate.value = date
  emit('setFilteredDate', date)
}

const typeSelectItems = ref([])

const typeSelectItemActive = ref({
  label: 'Сначала новые',
  id: 'date_desc'
})

watch(
  () => typeSelectItemActive.value,
  (val) => emit('setSortType', val)
)

const closeSearchPanel = () => {
  searchedNumbers.value = []
  searchedTransporters.value = []
  searchedPolygons.value = []
}

const maskVal = computed(() => Number(number.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')


onMounted(async()=> {
  const filters = await useApi('get', `filters/all`)

  typeSelectItems.value = filters.items.map(item => {
    return {
      label: item.label,
      id: item.key,
      children: item.children ? item.childrens : null
    }
  })
})

// const soloSearch = ref(null)
const soloSearch = ref(null)
const isFindSoloAct = ref(false)
const target = ref(null)
const input = ref(null)

watch(
  () => isFindSoloAct.value,
  (val) => {
    if(isFindSoloAct.value){
      store.dispatch('changeIsBlockWindow', true)
      document.querySelector('body').style.overflowY = "hidden"
      document.querySelector('body').style.paddingRight = "5px"
      setTimeout(()=> input.value.focus(), 0)
    } else {
      document.querySelector('body').style.overflowY = "scroll"
      document.querySelector('body').style.paddingRight = "0"
      store.dispatch('changeIsBlockWindow', false)
    }

  }
)

const findSoloAct = () => {
  emit('setTemporaryActNumber', soloSearch.value)
  // soloSearch.value = null
  isFindSoloAct.value = false
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13 && isFindSoloAct.value) {
    findSoloAct()
  }
});

onClickOutside(target, (event) =>{
  isFindSoloAct.value = false
  // soloSearch.value = null
})
</script>


<style scoped lang="scss">
.q-acts-filters{
  display: flex;
  justify-content: space-between;
  padding: 18px 9px;
  &__item{
    padding: 0 9px;
    &--number{
      width: 215px;
    }
    &--polygon,
    &--transporter{
      flex: 1;
    }
    &-download-excel{
      margin: 0 9px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #555555;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.teleport-container-left{
  display: flex;
}

.acts-dispute{
  background: $natural-color-system;
  padding: 6px 10px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  span{
    margin-left: 5px;
    color: white;
  }
  &__num{
    color: #FFE4D9 !important;
  }
  .hint{
    position: absolute;
    transition: ease .3s;
    top: 90%;
    opacity: 0;
    visibility: hidden;
    left: 50%;
    transform: translateX(-50%);
    background: #393939;
    backdrop-filter: blur(5px);
    border-radius: 14px;
    display: flex;
    &:before{
      position: absolute;
      content: url('~@/assets/images/hint-trinagle.svg');
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
    .hint-col{
      margin: 13px;
      display: flex;
      flex-direction: column;
      span{
        white-space: nowrap;
        &:first-child{
          opacity: .7;
          margin-bottom: 3px;
        }
      }
    }
  }

  &:hover{
    .hint{
      top: 120%;
      opacity: 1;
      visibility: visible;
    }
  }

}

.acts-info {
  background-color: $lemon-lime;
  padding: 8px 16px;
  border-radius: 14px;
  display: flex;
  margin-left: 32px;

  &__item {
    line-height: 20px;
    &:last-child{
      margin-left: 40px;
    }
  }
}

.q-acts-filters__item--find-act{
  position: relative;
}
.find-act{
  &__btn{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #555555;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__block{
    position: absolute;
    right: 10px;
    bottom: 74px;
    width: 359px;
    padding: 18px;
    border-radius: 24px;
    background: #424242;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input{
      background: rgba(255, 255, 255, 0.1);
      border-radius: 14px;
      padding: 8px 18px;
      border: 1px solid transparent;
      width: 100%;
      color: white;
      transition: ease-in-out 0.3s;
      height: 48px;
      margin-bottom: 20px;
      outline: none;
    }

  }
}
</style>
