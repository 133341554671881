<template>
  <div class="polygons">
    <h5>Статистические данные по начислениям и тоннажу</h5>

    <!-- <div class="switcher">
      <transition name="default">
        <q-anaylytics-switcher
          :position="switcher"
          @changePosition="switcher = !switcher"
        />
      </transition>
    </div> -->

    <!-- <pre>{{ compareData }}</pre> -->

    <div class="block-wrapper">
      <q-charts
        :series="isCompareMode ? dataSeries : polygonChartTonnage"
        :compareData="compareData"
        :polygonChartSumm="polygonChartSumm"
        :polygonChartTonnage="polygonChartTonnage"
        :month="month"
        :year="year"
        customTooltip
        type="polygon"
      />

      <q-analytics-polygon-info :data="polygonsPeriodData"/>

      <div class="table">
        <div class="thead">
          <div class="tr">
            <div class="td" style="width: 650px">

              <span>Все площадки</span>
              <button
                class="compare-btn"
                :class="{active: isCompareMode}"
                @click="changeCompareMode"
              >
                <span>{{isCompareMode ? 'Сбросить' : 'Сравнить'}}</span>
                <img src="@/assets/images/сompare.svg">
              </button>

            </div>
            <div class="td" style="width: 153px">Общий тоннаж (т)</div>
            <div class="td" style="width: 153px">Тариф</div>
            <div class="td no-separator" style="width: 153px">Итоговая сумма</div>
          </div>
        </div>
        <div class="tbody">
          <div
            class="item"
            :class="{'show-inner': showInnerId === item.id}"
            v-for="item in data.platforms"
            :key="item.id"
          >
            <div class="tr">
              <div class="td content" style="width: 650px">

                <transition name="slide">
                  <q-checkbox
                    v-if="isCompareMode"
                    @clicked="chooseCompare(item)"
                    :checked="compareData.find(compareItem => compareItem.id === item.id) ? true : false"
                    :view="compareData[0] && compareData[0].id === item.id ? '1' : '2'"
                  />
                </transition>

                <div class="td title-row">
                  <div class="title">{{ item.name }}</div>
                  <div class="subtitle ">{{ item.role_text}}</div>
                </div>
                <img
                  class="table-arrow"
                  src="@/assets/images/arrow-analytics.svg"
                  @click="showInner(item.id)"
                />
                <img v-if="compareData[0] && compareData[0].id === item.id" class="compare-fixed" src="@/assets/images/compare-fixed.svg">
              </div>
              <div class="td" style="width: 153px"> {{ item.tonnage.toFixed(2) }} т</div>
              <div class="td" style="width: 153px">{{ prettyNumberWithSpaces(Math.round(item.tariff)) }} ₽/т</div>
              <div class="td no-separator" style="width: 153px"> {{ prettyNumberWithSpaces(Math.round(item.summ)) }} ₽</div>
            </div>

            <div class="inner">
              <div
                class="tr"
                v-for="(org, idx) in item.transporters"
                :key="idx"
              >
                <div class="td content" style="width: 650px; padding-left: 35px;">
                  <div class="title">{{ org.name }}</div>
                  <div>{{org.category}}</div>
                </div>
                <div class="td no-separator" style="width: 153px">{{ org.tonnage.toFixed(2) }} т</div>
              </div>
            </div>
          </div>
        </div>
        <div class="tfoot">
          <div class="tr">
            <div class="td" style="width: 650px">
              <div>
                <div class="title ">Общий итог</div>
                <div class="subtitle">{{ data.platforms.length }} площадок</div>
              </div>
            </div>
            <div class="td" style="width: 153px">{{ data.tonnage.toFixed(2) }} т</div>
            <div class="td" style="width: 153px"> - </div>
            <div class="td no-separator" style="width: 153px" > {{ prettyNumberWithSpaces(Math.round(data.summ)) }} ₽</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, provide, watch } from "vue"
import prettyNumberWithSpaces from "@/helpers/prettyNumberWithSpaces.ts"
import QCharts from "@/components/ui/QCharts.vue";
import QAnaylyticsSwitcher from './AnaylyticsSwitcher'
import QAnalyticsPolygonInfo from './AnalyticsPolygonInfo'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  polygonsPeriodData: {
    type: Object,
    required: true
  },
  polygonChartTonnage: {
    type: Array
  },
  polygonChartSumm: {
    type: Array
  },
  month: {
    type: String
  },
  year: {
    type: String
  },
})


const switcher = ref(true)
const showInnerId = ref('')
const showInner = id => id === showInnerId.value ? showInnerId.value = '' : showInnerId.value = id

const isCompareMode = ref(false)
provide('isCompareMode', isCompareMode)

const compareData = ref([])

const changeCompareMode = () => {
  if(!isCompareMode.value){
    isCompareMode.value = true
  } else {
    isCompareMode.value = false
    compareData.value = []
  }
}

const chooseCompare = item => {
  const s = compareData.value.findIndex(compareItem => compareItem.id === item.id)
  if(s === 0 || s === 1){
    compareData.value.splice(s, 1)
    return
  }
  if(compareData.value.length === 2){
    return
  }

  compareData.value.push({
    isFixed: compareData.value.length ? true : false,
    id: item.id,
    name: item.name,
    dataTonnage: item.period.tonnage.map(item => item === '' ? null : item),
    dataSumm: item.period.summ.map(item => item === '' ? null : item),
    diff: item.period.diff_previous.map(item => item === '' ? null : item)
  })
}

const dataSeries = computed(()=> {
  return compareData.value.map(item => {
    return {
      isFixed: item.isFixed,
      id: item.id,
      name: item.name,
      data: switcher.value ? item.dataTonnage : item.dataSumm
    }
  })
})

const dataForCharts = computed(()=> {
  if(isCompareMode.value){
    return compareData
  }
  switcher.value ? props.polygonChartSumm : props.polygonChartTonnage
})

watch(
  () => props.month,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
watch(
  () => props.year,
  () => {
    isCompareMode.value = false
    compareData.value = []
  }
)
</script>

<style scoped lang="scss">
  h5{ margin-bottom: 25px;}
  .title-row{
    transition: ease .3s;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
    min-height: auto !important;

    &:before{
      display: none;
    }
  }

  .switcher{
    margin-bottom: 30px;
    height: 56px;
  }
</style>
